

export const consultTellerIP = async () => {
    const IPIFY_URL = process.env.REACT_APP_IPIFY_URL == undefined ? "" : process.env.REACT_APP_IPIFY_URL;

    try {
        const response = await fetch(IPIFY_URL);

        if (!response.ok) {
            // Maneja el error HTTP
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Verifica que el tipo de contenido sea JSON
        const contentType = response.headers.get('Content-Type');
        if (!contentType || !contentType.includes('application/json')) {
            throw new Error('Expected JSON response, but got something else.');
        }

        const data = await response.json();
        console.log(data);
        return data.ip;
    } catch (error) {
        console.error('Error fetching IP:', error);
        throw error;
    }
};