import React, { useState } from 'react';
import { gql } from '@apollo/client/core';
import { useMutation, useQuery } from '@apollo/client';
import {
  reviewConsignments,
  reviewConsignments_consignments_nodes_Consignment,
  reviewConsignmentsVariables,
} from '../../__api__/reviewConsignments';
import { DatePicker, Table, Tag } from 'antd';
import { Titles } from '../../enums/titles.enum';
import { Helmet } from 'react-helmet-async';
import { Modal } from '../../components/modal';
import { Labels } from '../../enums/labels.enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { ConsignmentStatus, Role } from '../../__api__/globalTypes';
import { ConsignmentStatus as ConsignmentStatusSpanish } from '../../enums/spanish.enum';
import { useMe } from '../../hooks/use-me';
import {
  updateConsignmentMutation,
  updateConsignmentMutationVariables,
} from '../../__api__/updateConsignmentMutation';
import {
  reverseConsignmentMutation,
  reverseConsignmentMutationVariables,
} from '../../__api__/reverseConsignmentMutation';
import { FormErrorMessages } from '../../enums/form-error-messages.enum';
import { FormError } from '../../components/form-error';
import { useForm } from 'react-hook-form';
import { Exceptions } from '../../enums/exceptions.enum';
import {
  SkeletonTable,
  SkeletonTableColumnsType,
} from '../../components/skeleton-table';
import { INPUT_TEXT_REGEX } from '../../constants';
import { bankAccountsQuery } from '../../__api__/bankAccountsQuery';
import DraggableZoomableImage from '../../components/draggable-image/DraggableZoomableImage';

const { RangePicker } = DatePicker;

const BANK_ACCOUNTS_QUERY = gql`
  query bankAccountsReviewConsignmentsQuery {
    bankAccounts {
      accountHolder
      accountNumber
      accountType
      bankCode
      bankName
      id
      status
    }
  }
`;




const REVIEW_CONSIGNMENTS_QUERY = gql`
  query reviewConsignments($input: ConsignmentSearcherInput!) {
    consignments(input: $input) {
      meta {
        nodeCount
        nodesPerPage
        pageCount
        pageCurrent
      }
      nodes {
        __typename
        ... on Consignment {
          id
          voucherNumber
          bankAccount {
            accountNumber
            accountHolder
            bankName
          }
          amount
          transactionDate
          status
          comment
          attachedURL
          reviewerUserId
          customer {
            name
            lastName
            identificationNumber
            cellPhone
          }
        }
      }
    }
  }
`;

const UPDATE_CONSIGNMENT_MUTATION = gql`
  mutation updateConsignmentMutation($input: UpdateConsignmentInput!) {
    updateConsignment(input: $input)
  }
`;

const REVERSE_CONSIGNMENT_MUTATION = gql`
  mutation reverseConsignmentMutation($input: ReverseConsignmentInput!) {
    reverseConsignment(input: $input)
  }
`;

const DEFAULT_PAGE_SIZE = 10;

interface ReviewProps {
  consignment: reviewConsignments_consignments_nodes_Consignment | null;
  loading: boolean;
  error: string;
  me: any;
  updateConsignment: (
    id: string,
    status: ConsignmentStatus,
    isReverse: boolean,
    comment?: string,
  ) => void;
}

interface UpdateConsignmentForm {
  comment?: string;
}

const Review: React.FC<ReviewProps> = ({
  consignment,
  error,
  loading,
  updateConsignment,
  me
}) => {
  const { register, getValues, errors } = useForm<UpdateConsignmentForm>({
    mode: 'onChange',
  });

  const isMobile = window.innerWidth <= 768; // Detecta si es móvil
  const containerWidth = isMobile ? '100%' : '700px'; // Ancho responsivo
  const containerHeight = isMobile ? 'auto' : '350px'; // Ajusta la altura responsivamente

  return (
    <div className="flex flex-col justify-center px-2 py-4 sm:px-6 lg:px-8">

      {error && <FormError errorMessage={Exceptions[error as any]} />}
      {!error && (
        <div>
          <div className="space-y-6">
            <div className='w-full flex justify-items-center items-center'>
              <DraggableZoomableImage
                src={consignment?.attachedURL ?? ''}
                containerWidth={containerWidth} // Ancho del contenedor
                containerHeight={containerHeight} // Alto del contenedor
                minZoom={-3} // Zoom mínimo
                maxZoom={4} // Zoom máximo
                enableTouch={isMobile}
              />
            </div>

            <div>
              <div className="flex justify-between px-2 py-1 text-sm font-medium bg-orange-500 rounded-lg mb-1">
                <dt className="text-white font-bold text-xl">No. comprobante</dt>
                <dd className="text-white font-bold text-2xl ">{consignment?.voucherNumber}</dd>

                {/* <dd className="text-gray-900">{consignment?.amount}</dd> */}
              </div>
              <div className="flex justify-between px-2 py-1 text-sm font-medium bg-green-500 rounded-lg">
                <dt className="text-white font-bold text-2xl">Monto</dt>
                <dd className="text-white font-bold text-2xl">${consignment?.amount}</dd>
              </div>
              <h3 className="font-medium text-gray-900">Información</h3>
              <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                <div className="flex justify-between py-3 text-sm font-medium">
                  <dt className="text-gray-500">Nombres</dt>
                  <dd className="text-gray-900">{`${consignment?.customer.name} ${consignment?.customer.lastName}`}</dd>
                </div>
                <div className="flex justify-between py-3 text-sm font-medium">
                  <dt className="text-gray-500">Identificación</dt>
                  <dd className="text-gray-900">
                    {consignment?.customer.identificationNumber}
                  </dd>
                </div>
                <div className="flex justify-between py-3 text-sm font-medium">
                  <dt className="text-gray-500">Celular</dt>
                  <dd className="text-gray-900">
                    {consignment?.customer.cellPhone}
                  </dd>
                </div>
                <div className="flex justify-between py-3 text-sm font-medium">
                  <dt className="text-gray-500">Cuenta bancaria</dt>
                  <dd className="text-gray-900">{consignment?.bankAccount != null ? `${consignment?.bankAccount.bankName} - ${consignment?.bankAccount.accountNumber} - ${consignment?.bankAccount.accountHolder}` : 'No Aplica'}</dd>
                </div>


              </dl>
            </div>
          </div>

          {(consignment?.status === ConsignmentStatus.REVERSED) && (
            <div>
              <label htmlFor="user_comment" className="label">
                {Labels.COMMENT}
              </label>
              <div className="mt-1">
                {consignment?.comment ?? ''}
              </div>
            </div>
          )}
          {[ConsignmentStatus.PENDING, ConsignmentStatus.REVISION].includes(
            consignment?.status || ConsignmentStatus.PENDING,
          ) && (
              <div className="sm:mx-auto sm:w-full sm:max-w-xl">
                <h3 className="mb-2 font-semibold">Gestionar acreditación de saldo</h3>
                <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
                  <form className="space-y-6">

                    <div>
                      <label htmlFor="user_comment" className="label">
                        {Labels.USER_COMMENT}
                      </label>
                      <div className="mt-1">
                        {consignment?.comment ?? ''}
                      </div>
                    </div>

                    <div>
                      <label htmlFor="comment" className="label">
                        {Labels.COMMENT}
                      </label>
                      <div className="mt-1">
                        <textarea
                          ref={register({
                            validate: {
                              isValidCharacters: (comment) =>
                                INPUT_TEXT_REGEX.test(comment) ||
                                FormErrorMessages.CHARACTERS,
                            },
                          })}
                          name="comment"
                          className="input"
                          defaultValue="procesado"
                          placeholder={'Escribe un comentario opcional'}
                        />
                        {errors.comment?.message && (
                          <FormError errorMessage={errors.comment?.message} />
                        )}
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <button
                        onClick={() =>
                          updateConsignment(
                            consignment?.id || '',
                            ConsignmentStatus.ACCEPTED,
                            false,
                            `${consignment?.comment} Revisión:${getValues('comment')}`,
                          )
                        }
                        type="button"
                        className="flex justify-center w-full px-1 py-1 mr-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:opacity-90"
                      >
                        <div className="flex items-center justify-between">
                          <span>{loading ? 'Cargando..' : 'Aprobar'}</span>
                        </div>
                      </button>
                      <button
                        onClick={() =>
                          updateConsignment(
                            consignment?.id || '',
                            ConsignmentStatus.REJECTED,
                            false,
                            getValues('comment'),
                          )
                        }
                        type="button"
                        className="flex justify-center w-full px-1 py-1 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 hover:opacity-90"
                      >
                        <div className="flex items-center justify-between">
                          <span>{loading ? 'Cargando..' : 'Rechazar'}</span>
                        </div>
                      </button>
                    </div>
                    {error && (
                      <FormError errorMessage={Exceptions[error as any]} />
                    )}
                  </form>
                </div>
              </div>
            )}
          {(ConsignmentStatus.ACCEPTED == consignment?.status && me.role == Role.ADMIN) && (
            <div className="sm:mx-auto sm:w-full sm:max-w-xl">
              <h3 className="mb-2 font-semibold">Revertir acreditación de saldo</h3>
              <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
                <form className="space-y-6">

                  <div>
                    <label htmlFor="comment" className="label">
                      {Labels.COMMENT}
                    </label>
                    <div className="mt-1">
                      <textarea
                        ref={register({
                          validate: {
                            isValidCharacters: (comment) =>
                              INPUT_TEXT_REGEX.test(comment) ||
                              FormErrorMessages.CHARACTERS,
                          },
                        })}
                        name="comment"
                        className="input"
                        placeholder={'Escribe un comentario opcional'}
                        defaultValue={consignment.comment ?? ''}
                      />
                      {errors.comment?.message && (
                        <FormError errorMessage={errors.comment?.message} />
                      )}
                    </div>
                  </div>
                  <div className="flex items-center justify-between">
                    <button
                      onClick={() =>
                        updateConsignment(
                          consignment?.id || '',
                          ConsignmentStatus.REVERSED,
                          true,
                          getValues('comment'),
                        )
                      }
                      type="button"
                      className="flex justify-center w-full px-1 py-1 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 hover:opacity-90"
                    >
                      <div className="flex items-center justify-between">
                        <span>{loading ? 'Cargando..' : 'Revertir'}</span>
                      </div>
                    </button>
                  </div>
                  {error && (
                    <FormError errorMessage={Exceptions[error as any]} />
                  )}
                </form>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const ReviewConsignments = () => {
  const { data: user } = useMe();
  const { data: bankAccountsList } = useQuery<bankAccountsQuery>(
    BANK_ACCOUNTS_QUERY,
    {
      fetchPolicy: 'network-only',
    },
  );
  const [pageCurrent, setPageCurrent] = useState(1);
  const [nodesPerPage, setNodesPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [dateTo, setDateTo] = useState<string | null>(null);
  const [identificationNumber, setIdentificationNumber] = useState<string | null>('');
  const [voucherNumber, setVoucherNumber] = useState<string | null>('');
  const [isModalReviewVisible, setIsModalReviewVisible] = useState(false);
  const [bankAccountId, setBankAccountId] = useState<string | null>(null);
  const [status, setStatus] = useState<ConsignmentStatus[] | null>(null);
  const [
    consignment,
    setConsignment,
  ] = useState<reviewConsignments_consignments_nodes_Consignment | null>(null);

  const getCorrectError = (error: string, error2: string) => {
    if (user?.me.role != Role.ADMIN && consignment?.status == ConsignmentStatus.ACCEPTED) {
      return error2;
    }
    return error;
  }

  const onChange = (values: any) => {
    if (!values) {
      setDateFrom(null);
      setDateTo(null);
      return;
    }

    // Establecer la fecha desde con hora a 00:00
    const dateFrom = values[0].startOf('day').format(); // 00:00

    // Establecer la fecha hasta con hora a 23:59
    const dateTo = values[1].endOf('day').format(); // 23:59

    setDateFrom(dateFrom);
    setDateTo(dateTo);
  };

  const columns = [
    {
      title: 'ID Cliente',
      dataIndex: 'identificationNumber',
      key: 'identificationNumber',
    },
    {
      title: 'Comprobante',
      dataIndex: 'voucher',
      key: 'voucher',
    },
    {
      title: 'Monto acreditado',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Cuenta bancaria',
      dataIndex: 'bankAccount',
      key: 'bankAccount',
    },
    {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const { data, loading, refetch } = useQuery<
    reviewConsignments,
    reviewConsignmentsVariables
  >(REVIEW_CONSIGNMENTS_QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        pageCurrent,
        nodesPerPage,
        where: {
          dateFrom,
          dateTo,
          identificationNumber,
          voucherNumber,
          bankAccountId,
          status,
        },
      },
    },
  });

  const consignments = data?.consignments.nodes as
    | reviewConsignments_consignments_nodes_Consignment[]
    | null;

  const onCompleted = async (data: updateConsignmentMutation) => {
    const { updateConsignment: consignmentId } = data;
    if (consignmentId) {
      await refetch();
      if (isModalReviewVisible) {
        setIsModalReviewVisible(false);
      }
    }
  };

  const onCompleted2 = async (data: reverseConsignmentMutation) => {
    const { reverseConsignment: consignmentId } = data;
    if (consignmentId) {
      await refetch();
      if (isModalReviewVisible) {
        setIsModalReviewVisible(false);
      }
    }
  };

  const [
    updateConsignmentMutation,
    { loading: loadingMutation, error },
  ] = useMutation<
    updateConsignmentMutation,
    updateConsignmentMutationVariables
  >(UPDATE_CONSIGNMENT_MUTATION, { onCompleted });

  const [
    reverseConsignmentMutation,
    { loading: loadingMutation2, error: error2 },
  ] = useMutation<
    reverseConsignmentMutation,
    reverseConsignmentMutationVariables
  >(REVERSE_CONSIGNMENT_MUTATION, { onCompleted: onCompleted2 });

  const updateConsignmentStatus = async (
    id: string,
    status: ConsignmentStatus,
    isReverse: boolean,
    comment?: string,
  ) => {
    if (!loadingMutation || !loadingMutation2) {
      if (isReverse) {
        if (user?.me.role != Role.ADMIN) {
          return;
        }
        if (!window.confirm("¿Está seguro de revertir la acreditación de saldo?")) {
          return;
        }
        try {
          await reverseConsignmentMutation({
            variables: {
              input: {
                id,
                comment,
              },
            },
          });
        } catch (error) { }
      }
      else {
        try {
          await updateConsignmentMutation({
            variables: {
              input: {
                id,
                status,
                comment,
              },
            },
          });
        } catch (error) { }
      }
    }
  };

  const datasource = consignments?.map((item) => ({
    key: item.id,
    identificationNumber: item.customer.identificationNumber,
    voucher: item.voucherNumber,
    amount: item.amount,
    bankAccount: item.bankAccount != null ? `${item.bankAccount.bankName} - ${item.bankAccount.accountNumber} - ${item.bankAccount.accountHolder}` : 'No Aplica',
    date: item.transactionDate,
    status: (
      <Tag
        color={
          item.status === 'ACCEPTED'
            ? 'geekblue'
            : item.status === 'PENDING'
              ? 'orange'
              : item.status === 'REJECTED'
                ? 'error'
                : item.status === 'REVERSED'
                  ? 'yellow'
                  : 'cyan'
        }
      >
        {ConsignmentStatusSpanish[item.status]}
      </Tag>
    ),
    comment: item.comment,
    action: (
      <div className="flex items-center space-x-2">


        {(![ConsignmentStatus.REVISION, ConsignmentStatus.PENDING].includes(item.status)) && (
          // Botón para cualquier otro estado
          <button
            onClick={() => {
              setConsignment(item);
              setIsModalReviewVisible(true);
            }}
            type="button"
            className="flex justify-center w-full px-1 py-1 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:opacity-90"
          >
            <div className="flex items-center justify-between">
              <span>Ver</span>
            </div>
          </button>
        )}

        {([ConsignmentStatus.REVISION, ConsignmentStatus.PENDING].includes(item.status)) && (
          <button
            onClick={async () => {
              await updateConsignmentStatus(item.id, ConsignmentStatus.REVISION, false);
              setConsignment(item);
              setIsModalReviewVisible(true);
            }}
            type="button"
            className={`flex justify-center w-full px-1 py-1 text-sm font-medium text-white border border-transparent rounded-md shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 ${!item.reviewerUserId || item.reviewerUserId === user?.me.id
              ? 'bg-green-600 hover:opacity-90'
              : 'bg-green-300 pointer-events-none'
              }`}
          >
            <span>Revisar</span>
          </button>
        )}
        {(ConsignmentStatus.REVISION === item.status && user?.me.role === Role.ADMIN) && (
          <button
            onClick={async () => {
              await updateConsignmentStatus(item.id, ConsignmentStatus.PENDING, false);
              refetch();
            }}
            type="button"
            className={`flex justify-center w-full py-1 text-sm font-medium text-white border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 bg-indigo-600 hover:opacity-90'}`}
          >
            <span>Liberar</span>
          </button>
        )}
      </div>
    )
  }));


  return (
    <div>
      <Modal
        title="Revisión de acreditación de saldo"
        visible={isModalReviewVisible}
        onOk={async () => {
          setIsModalReviewVisible(false);
          await refetch();
        }}
        onCancel={async () => {
          setIsModalReviewVisible(false);
          await refetch();
        }}
        child={
          <Review
            consignment={consignment}
            updateConsignment={updateConsignmentStatus}
            error={getCorrectError(error?.message || "", error2?.message || "")}
            loading={loadingMutation}
            me={user?.me || {}}
          />
        }
      />
      <Helmet>
        <title>{Titles.CONSIGNMENTS}</title>
      </Helmet>
      <div className="px-4 py-6">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-900 leading-6">
              {Labels.MENU_CONSIGNMENT}
            </h3>
          </div>
          <div className="mt-5 mb-5 md:mt-0 md:col-span-2">
            <form className="flex flex-col items-center w-full py-8 bg-white shadow sm:rounded-3xl sm:px-4">
              <div className="mb-4 mr-auto">
                {Labels.FILTERS}
                <FontAwesomeIcon icon={faFilter} className="ml-3 text-xl" />
              </div>
              <div className="max-w-xl grid grid-cols-1 gap-5">
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">{Labels.FILTER_DATES}</span>
                  </label>
                  <RangePicker
                    placeholder={['Fecha desde', 'Fecha hasta']}
                    className="w-full rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={onChange}
                    disabledDate={(current) => moment().isBefore(current)}
                  />
                </div>

                <div className="flex items-center justify-center">
                  <label
                    htmlFor="identificationNumber"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">{Labels.FILTER_IDENTIFICATION_NUMBER}</span>
                  </label>
                  <input type="text" className='w-full input' value={identificationNumber ?? ''} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setIdentificationNumber(e.target.value)} />
                </div>

                <div className="flex items-center justify-center">
                  <label
                    htmlFor="voucherNumber"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">{Labels.FILTER_VOUCHER_NUMBER}</span>
                  </label>
                  <input type="text" className='w-full input' value={voucherNumber ?? ''} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setVoucherNumber(e.target.value)} />
                </div>

                <div className="flex items-center justify-center">
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">{Labels.FILTER_BANK}</span>
                  </label>
                  <select
                    name="bankAccountId"
                    onChange={(event) => {
                      const value = event.target.value;
                      setBankAccountId(
                        value,
                      );
                    }}
                    className="w-full select"
                  >
                    <option value="">Todos</option>
                    {bankAccountsList?.bankAccounts.map((bank, index) => (
                      <option key={`item-cbo-bank-${index}`} value={bank.id ?? ''}>
                        {bank.bankName} {bank.accountNumber} -{bank.accountHolder}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="flex items-center justify-center">
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">{Labels.FILTER_STATUS}</span>
                  </label>
                  <select
                    name="status"
                    onChange={(event) => {
                      const value = event.target.value;
                      setStatus(
                        value ? ([value] as ConsignmentStatus[]) : null,
                      );
                    }}
                    className="w-full select"
                  >
                    <option value="">Todos</option>
                    {Object.keys(ConsignmentStatus).map((state) => (
                      <option key={state} value={state}>
                        {ConsignmentStatusSpanish[state as any]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </form>
          </div>
          <SkeletonTable
            active={true}
            loading={loading}
            columns={columns as SkeletonTableColumnsType[]}
          >
            <Table
              dataSource={datasource}
              columns={columns}
              pagination={{
                defaultPageSize: DEFAULT_PAGE_SIZE,
                pageSize: nodesPerPage,
                current: pageCurrent,
                total: data?.consignments.meta.nodeCount,
                responsive: true,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30', '100'],
                onShowSizeChange: (current, size) => setNodesPerPage(size),
                onChange: (page) => setPageCurrent(page),
              }}
            />
          </SkeletonTable>
        </div>
      </div>
    </div>
  );
};
