import React, { useEffect, useState } from 'react';
import { gql } from '@apollo/client/core';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import {
  validateRecoverPasswordLinkMutation,
  validateRecoverPasswordLinkMutationVariables,
} from '../__api__/validateRecoverPasswordLinkMutation';
import { Helmet } from 'react-helmet-async';
import { Titles } from '../enums/titles.enum';
import { Link, useHistory } from 'react-router-dom';
import logo from '../images/logo2.svg';
import { Labels } from '../enums/labels.enum';
import { FormErrorMessages } from '../enums/form-error-messages.enum';
import {
  PASSWORD_VALID_CHARACTERS_REGEX,
  STRONG_PASSWORD_REGEX,
} from '../constants';
import { FormError } from '../components/form-error';
import { Button } from '../components/button';
import { Exceptions } from '../enums/exceptions.enum';
import { Modal as AModal } from 'antd';
import { NotFound } from './404';
import {
  resetPasswordMutation,
  resetPasswordMutationVariables,
} from '../__api__/resetPasswordMutation';

const VALIDATE_RECOVER_PASSWORD_LINK = gql`
  mutation validateRecoverPasswordLinkMutation(
    $input: ValidateRecoverPasswordLinkInput!
  ) {
    validateRecoverPasswordLink(input: $input)
  }
`;

const RESET_PASSWORD = gql`
  mutation resetPasswordMutation($input: RecoverPasswordInput!) {
    recoverPassword(input: $input)
  }
`;

interface ResetPasswordForm {
  newPassword: string;
  confirmPassword: string;
}

const getLocation = (): URLSearchParams => {
  const search = window.location.search;
  return new URLSearchParams(search);
};

export const ResetPassword = () => {
  const history = useHistory();

  const success = (content: string) => {
    AModal.success({
      content,
      onOk: () => {
        history.push('/login');
      },
    });
  };

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [email, setEmail] = useState<string>('');
  const [token, setToken] = useState<string>('');

  const {
    register,
    getValues,
    errors,
    handleSubmit,
    formState,
  } = useForm<ResetPasswordForm>({
    mode: 'onChange',
  });

  const [
    validateRecoverPasswordLinkMutation,
    { loading: loadingValidate },
  ] = useMutation<
    validateRecoverPasswordLinkMutation,
    validateRecoverPasswordLinkMutationVariables
  >(VALIDATE_RECOVER_PASSWORD_LINK);

  useEffect(() => {
    const params = getLocation();
    const email = params.get('email');
    const token = params.get('token');
    if (!email || !token) {
      setErrorMessage('InvalidParams');
      return;
    }
    validateRecoverPasswordLinkMutation({
      variables: {
        input: {
          email: email || '',
          token: token || '',
        },
      },
    }).then((res) => {
      if (res?.data?.validateRecoverPasswordLink === false) {
        setErrorMessage('InvalidParams');
      } else {
        setEmail(email);
        setToken(token);
      }
    });
  }, [validateRecoverPasswordLinkMutation]);

  const onCompleted = (data: resetPasswordMutation) => {
    const { recoverPassword } = data;
    if (recoverPassword) {
      success('Se cambió el password satisfactoriamente');
    }
  };
  const [resetPasswordMutation, { loading, error }] = useMutation<
    resetPasswordMutation,
    resetPasswordMutationVariables
  >(RESET_PASSWORD, {
    onCompleted,
  });
  const onSubmit = async () => {
    if (!loading) {
      try {
        const { newPassword, confirmPassword } = getValues();
        await resetPasswordMutation({
          variables: {
            input: {
              newPassword,
              confirmPassword,
              email,
              token,
            },
          },
        });
      } catch (error) {}
    }
  };

  return (
    <div>
      {loadingValidate && (
        <div className="flex items-center justify-center h-screen">
          <span className="text-xl font-medium tracking-widest">
            {Labels.LOADING}
          </span>
        </div>
      )}
      {errorMessage && <NotFound />}
      {!errorMessage && (
        <div className="flex flex-col justify-center px-2 py-12 sm:px-6 lg:px-8">
          <Helmet>
            <title>{Titles.RESET_PASSWORD}</title>
          </Helmet>
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <Link to="/">
              <img className="w-auto h-40 mx-auto" src={logo} alt="Workflow" />
            </Link>
            <h2 className="mt-1 text-2xl font-extrabold text-center text-gray-900">
              {Labels.RESET_PASSWORD}
            </h2>
          </div>
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
              <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <label htmlFor="newPassword" className="label">
                    Nueva contraseña
                  </label>
                  <div className="mt-1">
                    <input
                      ref={register({
                        required: FormErrorMessages.REQUIRED_PASSWORD,
                        minLength: 8,
                        maxLength: 50,
                        validate: {
                          isValidCharacters: (newPassword) =>
                            PASSWORD_VALID_CHARACTERS_REGEX.test(newPassword) ||
                            FormErrorMessages.PASSWORD_ERROR_MESSAGE,
                          strongPassword: (newPassword) =>
                            STRONG_PASSWORD_REGEX.test(newPassword) ||
                            FormErrorMessages.PASSWORD_ERROR_MESSAGE,
                        },
                      })}
                      name="newPassword"
                      minLength={8}
                      maxLength={50}
                      type="password"
                      className="input"
                    />
                    {errors.newPassword?.message && (
                      <FormError errorMessage={errors.newPassword?.message} />
                    )}
                    {(errors.newPassword?.type === 'minLength' ||
                      errors.newPassword?.type === 'maxLength') && (
                      <FormError
                        errorMessage={FormErrorMessages.PASSWORD_ERROR_MESSAGE}
                      />
                    )}
                  </div>
                </div>
                <div>
                  <label htmlFor="confirmPassword" className="label">
                    Confirmar contraseña
                  </label>
                  <div className="mt-1">
                    <input
                      ref={register({
                        required: FormErrorMessages.REQUIRED_PASSWORD,
                        minLength: 8,
                        maxLength: 50,
                        validate: {
                          isValidCharacters: (confirmPassword) =>
                            PASSWORD_VALID_CHARACTERS_REGEX.test(
                              confirmPassword,
                            ) || FormErrorMessages.PASSWORD_ERROR_MESSAGE,
                          strongPassword: (confirmPassword) =>
                            STRONG_PASSWORD_REGEX.test(confirmPassword) ||
                            FormErrorMessages.PASSWORD_ERROR_MESSAGE,
                          differentPasswords: (confirmPassword) =>
                            getValues('newPassword') === confirmPassword ||
                            FormErrorMessages.DIFFERENT_PASSWORD,
                        },
                      })}
                      name="confirmPassword"
                      minLength={8}
                      maxLength={50}
                      type="password"
                      className="input"
                    />
                    {errors.confirmPassword?.message && (
                      <FormError
                        errorMessage={errors.confirmPassword?.message}
                      />
                    )}
                    {(errors.confirmPassword?.type === 'minLength' ||
                      errors.confirmPassword?.type === 'maxLength') && (
                      <FormError
                        errorMessage={FormErrorMessages.PASSWORD_ERROR_MESSAGE}
                      />
                    )}
                  </div>
                </div>
                <div>
                  <Button
                    canClick={formState.isValid}
                    loading={loading}
                    actionText={Labels.SEND}
                  />
                  {error && (
                    <FormError
                      errorMessage={Exceptions[error.message as any]}
                    />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
