import React from 'react';
import { Tabs } from 'antd';
import { RegisterLink } from './register-link';
import { AdminUsers } from './admin-users';
import { useMe } from '../../hooks/use-me';
import { Role } from '../../__api__/globalTypes';
import { AdminBankAccounts } from './admin-bank-accounts';
import { Customers } from './customers';
import { Titles } from '../../enums/titles.enum';
import { Helmet } from 'react-helmet-async';
import { RangeDiscounts } from './range-discounts';
import { CustomerDiscount } from './customer-discount';
import { Commissions } from './commissions';

const { TabPane } = Tabs;

export const Settings = () => {
  const { data: user } = useMe();

  return (
    <div className="py-6">
      <Helmet>
        <title>{Titles.SETTINGS}</title>
      </Helmet>
      <div className="px-2 py-2 mx-auto max-w-7xl sm:px-6 lg:px-8">
        {user?.me.role === Role.ADMIN && (
          <Tabs defaultActiveKey="1">
            <TabPane tab="Link de registro" key="1">
              <RegisterLink />
            </TabPane>
            <TabPane style={{ overflow: 'scroll' }} tab="Clientes" key="2">
              <Customers />
            </TabPane>
            <TabPane
              style={{ overflow: 'scroll' }}
              tab="Usuarios internos"
              key="3"
            >
              <AdminUsers />
            </TabPane>
            <TabPane
              style={{ overflow: 'scroll' }}
              tab="Cuentas bancarias"
              key="4"
            >
              <AdminBankAccounts />
            </TabPane>
            <TabPane
              style={{ overflow: 'scroll' }}
              tab="Descuentos por rango"
              key="5"
            >
              <RangeDiscounts />
            </TabPane>
            <TabPane
              style={{ overflow: 'scroll' }}
              tab="Descuentos por cliente"
              key="6"
            >
              <CustomerDiscount />
            </TabPane>
            <TabPane style={{ overflow: 'scroll' }} tab="Comisiones" key="7">
              <Commissions />
            </TabPane>
          </Tabs>
        )}
        {user?.me.role === Role.OPERATOR && (
          <Tabs defaultActiveKey="1">
            <TabPane tab="Link de registro" key="1">
              <RegisterLink />
            </TabPane>
            <TabPane style={{ overflow: 'scroll' }} tab="Clientes" key="2">
              <Customers />
            </TabPane>
          </Tabs>
        )}
        {user?.me.role === Role.DISTRIBUTOR && (
          <Tabs defaultActiveKey="1">
            <TabPane tab="Link de registro" key="1">
              <RegisterLink />
            </TabPane>
            <TabPane style={{ overflow: 'scroll' }} tab="Clientes" key="2">
              <Customers />
            </TabPane>
          </Tabs>
        )}
      </div>
    </div>
  );
};
