import { gql } from '@apollo/client/core';

export const UPDATE_USER_MUTATION = gql`
  mutation updateUserMutation($input: UpdateUserInput!) {
    updateUser(input: $input)
  }
`;

export const UPDATE_CUSTOMER_API_MUTATION = gql`
  mutation updateCostumerAPIMutation($input: UpdateCostumerAPIInput!) {
    updateCostumerAPI(input: $input)
  }
`;

export const UPDATE_DEVOLUTION_MUTATION = gql`
  mutation updateDevolucionMutation($input: UpdateDevolucionInput!) {
    updateDevolucion(input: $input)
  }
`;

export const UPDATE_ORDER_MUTATION = gql`
  mutation updateOrderMutation($input: UpdatePurchaseOrderInput!) {
    updatePurchaseOrder(input: $input)
  }
`;

export const CONSULTAR_MTTR = gql`
  mutation consultarMTTRMutation($input: MTTRConsultarInput!) {
    consultarMTTR(input: $input)
    {
        data
        code
        mensaje
    }
  }
`;

export const CREATE_MTTR_PURCHASE_ORDER = gql`
  mutation pagarMTTRMutation($input: MTTRPagarInput!) {
    pagarMTTR(input: $input)
  }
`;

export const CREATE_MTTR_PURCHASE_GIFTCARD = gql`
  mutation comprarVoucherMTTRMutation($input: MTTRComprarVoucherInput!) {
    comprarVoucherMTTR(input: $input)
  }
`;