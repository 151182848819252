import React from 'react'
import { Labels } from '../../enums/labels.enum'

const LoaderButton = () => {
    return (
        <div className="flex items-center justify-center bg-gray-300 animate-pulse rounded px-2 py-2">
            <span className='font-semibold'>{Labels.PAVVALIDCASHOUT_LOADINGPAYMENT}</span>
            <div className="w-6 h-6 border-4 border-blue-500 border-t-transparent rounded-full animate-pulse"></div>
        </div>
    )
}

export default LoaderButton