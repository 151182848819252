import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client';
import { useMe } from '../../hooks/use-me';
import { Labels } from '../../enums/labels.enum';
import { FormErrorMessages } from '../../enums/form-error-messages.enum';
import {
  checkLength,
  removeSpecialCharacters,
} from '../../utils/validate-utils';
import { FormError } from '../../components/form-error';
import { Exceptions } from '../../enums/exceptions.enum';
import { Button } from '../../components/button';
import 'moment/locale/es';
import { provinces_provinces_cities } from '../../__api__/provinces';
import {
  registerSubsidiaryMutation,
  registerSubsidiaryMutationVariables,
} from '../../__api__/registerSubsidiaryMutation';
import { IdentificationType } from '../../enums/spanish.enum';
import { useProvinces } from '../../hooks/use-provinces';
import { IdType } from '../../__api__/globalTypes';
import {
  EMAIL_REGEX,
  PASSWORD_VALID_CHARACTERS_REGEX,
  STRONG_PASSWORD_REGEX,
} from '../../constants';

const REGISTER_SUBSIDIARY_MUTATION = gql`
  mutation registerSubsidiaryMutation($input: RegisterSubsidiaryInput!) {
    registerSubsidiary(input: $input) {
      customerId
      userId
    }
  }
`;

interface IRegisterSubsidiaryForm {
  lastName: string;
  name: string;
  identificationType: IdType;
  identificationNumber: string;
  cellPhone: string;
  cityId: string;
  provinceId: string;
  email: string;
  password: string;
}

interface IRegisterSubsidiaryProps {
  onOk: () => void;
}

export const CreateUser: React.FC<IRegisterSubsidiaryProps> = ({ onOk }) => {
  const { data } = useMe();
  const { data: provinces } = useProvinces();
  const {
    register,
    watch,
    getValues,
    errors,
    handleSubmit,
    formState: { isValid },
  } = useForm<IRegisterSubsidiaryForm>({
    mode: 'onChange',
    defaultValues: {
      identificationType: IdType.CITIZENSHIP_ID,
    },
  });
  const onCompleted = (data: registerSubsidiaryMutation) => {
    const {
      registerSubsidiary: { customerId },
    } = data;
    if (customerId) {
      onOk();
    }
  };
  const [registerSubsidiaryMutation, { loading, error }] = useMutation<
    registerSubsidiaryMutation,
    registerSubsidiaryMutationVariables
  >(REGISTER_SUBSIDIARY_MUTATION, {
    onCompleted,
  });
  const onSubmit = async () => {
    if (!loading) {
      try {
        const { ...input } = getValues();
        await registerSubsidiaryMutation({
          variables: {
            input: {
              ...input,
              userId: data?.me.id || '',
            },
          },
        });
      } catch (error) {}
    }
  };

  const provinceId: string = watch('provinceId');
  const provinceOptions = useMemo(() => provinces?.provinces || [], [
    provinces,
  ]);
  const idType = watch('identificationType');
  const citiesByProvinceId = (
    provinceId: string,
  ): provinces_provinces_cities[] => {
    return (
      provinceOptions.find((province: any) => province.id === provinceId)
        ?.cities || []
    );
  };
  const cities = citiesByProvinceId(provinceId) || [];
  const cityOptions = useMemo(
    () => (provinceId && cities.length ? cities : citiesByProvinceId('1')),
    [cities, provinceId],
  );

  return (
    <div className="flex flex-col justify-center px-2 py-8 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label htmlFor="name" className="label">
                {Labels.NAME}
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_NAME,
                    minLength: 3,
                    maxLength: 50,
                    validate: {
                      isValidCharacters: (name) =>
                        /^[áéíóúÁÉÍÓÚñÑa-zA-Z ]*$/.test(name) ||
                        FormErrorMessages.CHARACTERS,
                    },
                  })}
                  name="name"
                  minLength={3}
                  maxLength={50}
                  type="text"
                  className="input"
                />
                {errors.name?.message && (
                  <FormError errorMessage={errors.name?.message} />
                )}
                {(errors.name?.type === 'minLength' ||
                  errors.name?.type === 'maxLength') && (
                  <FormError errorMessage={FormErrorMessages.LENGTH} />
                )}
              </div>
            </div>
            <div>
              <label htmlFor="lastName" className="label">
                {Labels.LAST_NAME}
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_LAST_NAME,
                    minLength: 3,
                    maxLength: 50,
                    validate: {
                      isValidCharacters: (lastName) =>
                        /^[áéíóúÁÉÍÓÚñÑa-zA-Z ]*$/.test(lastName) ||
                        FormErrorMessages.CHARACTERS,
                    },
                  })}
                  name="lastName"
                  minLength={3}
                  maxLength={50}
                  type="text"
                  className="input"
                />
                {errors.lastName?.message && (
                  <FormError errorMessage={errors.lastName?.message} />
                )}
                {(errors.lastName?.type === 'minLength' ||
                  errors.lastName?.type === 'maxLength') && (
                  <FormError errorMessage={FormErrorMessages.LENGTH} />
                )}
              </div>
            </div>
            <div>
              <label htmlFor="identificationType" className="label">
                {Labels.ID_TYPE}
              </label>
              <div className="mt-1">
                <select
                  name="identificationType"
                  ref={register({ required: true })}
                  className="select"
                >
                  {Object.keys(IdType).map((idType) => (
                    <option key={idType} value={idType}>
                      {IdentificationType[idType as any]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="identificationNumber" className="label">
                {Labels.ID_NUMBER}
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_ID_NUMBER,
                    minLength: idType === IdType.RUC ? 13 : 10,
                    maxLength: idType === IdType.RUC ? 13 : 10,
                    validate: {
                      isValidCharacters: (identificationNumber) =>
                        /^[0-9]*$/.test(identificationNumber) ||
                        FormErrorMessages.CHARACTERS,
                    },
                  })}
                  name="identificationNumber"
                  type="tel"
                  pattern="[0-9]*"
                  minLength={idType === IdType.RUC ? 13 : 10}
                  maxLength={idType === IdType.RUC ? 13 : 10}
                  className="input"
                  onKeyDown={(e) => checkLength(e)}
                  onKeyUp={removeSpecialCharacters}
                />
                {errors.identificationNumber?.message && (
                  <FormError
                    errorMessage={errors.identificationNumber?.message}
                  />
                )}
                {(errors.identificationNumber?.type === 'minLength' ||
                  errors.identificationNumber?.type === 'maxLength') &&
                  [IdType.CITIZENSHIP_ID, IdType.PASSPORT].includes(idType) && (
                    <FormError errorMessage={FormErrorMessages.ID_LENGTH} />
                  )}
                {(errors.identificationNumber?.type === 'minLength' ||
                  errors.identificationNumber?.type === 'maxLength') &&
                  idType === IdType.RUC && (
                    <FormError errorMessage={FormErrorMessages.ID_LENGTH_RUC} />
                  )}
              </div>
            </div>
            <div>
              <label htmlFor="cellPhone" className="label">
                {Labels.CELL_PHONE}
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_CELL_PHONE,
                    minLength: 10,
                    maxLength: 10,
                    validate: {
                      isValidCharacters: (cellPhone) =>
                        /^[0-9]*$/.test(cellPhone) ||
                        FormErrorMessages.CHARACTERS,
                    },
                  })}
                  name="cellPhone"
                  type="tel"
                  pattern="[0-9]*"
                  minLength={10}
                  maxLength={10}
                  className="input"
                  onKeyDown={(e) => checkLength(e)}
                  onKeyUp={removeSpecialCharacters}
                />
                {errors.cellPhone?.message && (
                  <FormError errorMessage={errors.cellPhone?.message} />
                )}
                {(errors.cellPhone?.type === 'minLength' ||
                  errors.cellPhone?.type === 'maxLength') && (
                  <FormError
                    errorMessage={FormErrorMessages.CELL_PHONE_LENGTH}
                  />
                )}
              </div>
            </div>
            <div>
              <label htmlFor="provinceId" className="label">
                {Labels.PROVINCE}
              </label>
              <div className="mt-1">
                <select
                  name="provinceId"
                  ref={register({ required: true })}
                  className="select"
                >
                  <option value="">{Labels.SELECTED_ONE}</option>
                  {provinceOptions.map((province: any) => (
                    <option key={province.id} value={province.id}>
                      {province.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="cityId" className="label">
                {Labels.CITY}
              </label>
              <div className="mt-1">
                <select
                  name="cityId"
                  ref={register({ required: true })}
                  className="select"
                >
                  <option value="">{Labels.SELECTED_ONE}</option>
                  {cityOptions.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="email" className="label">
                {Labels.EMAIL}
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_EMAIL,
                    pattern: {
                      value: EMAIL_REGEX,
                      message: FormErrorMessages.VALID_EMAIL,
                    },
                  })}
                  name="email"
                  type="email"
                  className="input"
                />
                {errors.email?.message && (
                  <FormError errorMessage={errors.email?.message} />
                )}
              </div>
            </div>
            <div>
              <label htmlFor="password" className="label">
                {Labels.PASSWORD}
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_PASSWORD,
                    minLength: 8,
                    maxLength: 50,
                    validate: {
                      isValidCharacters: (password) =>
                        PASSWORD_VALID_CHARACTERS_REGEX.test(password) ||
                        FormErrorMessages.PASSWORD_ERROR_MESSAGE,
                      strongPassword: (password) =>
                        STRONG_PASSWORD_REGEX.test(password) ||
                        FormErrorMessages.PASSWORD_ERROR_MESSAGE,
                    },
                  })}
                  name="password"
                  minLength={8}
                  maxLength={50}
                  type="password"
                  className="input"
                />
                {errors.password?.message && (
                  <FormError errorMessage={errors.password?.message} />
                )}
                {(errors.password?.type === 'minLength' ||
                  errors.password?.type === 'maxLength') && (
                  <FormError
                    errorMessage={FormErrorMessages.PASSWORD_ERROR_MESSAGE}
                  />
                )}
              </div>
            </div>
            <Button
              canClick={isValid}
              loading={loading}
              actionText={Labels.CREATE}
            />
            {error && (
              <FormError errorMessage={Exceptions[error.message as any]} />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
