import React from 'react';
import { Link } from 'react-router-dom';
import { Titles } from '../enums/titles.enum';
import { Helmet } from 'react-helmet-async';
import logo from '../images/logo2.svg';

export const NotFound = () => (
  <div className="flex flex-col items-center justify-center h-screen">
    <Helmet>
      <title>{Titles.NOT_FOUND}</title>
    </Helmet>
    <Link className="hover:underline text-cyan-600" to="/">
      <img src={logo} alt="Imagen de Error 404" className="mb-8 w-40 h-40" /> 
    </Link>
    <h2 className="mb-3 text-2xl font-semibold">Sesión Caducada.</h2>
    <h4 className="mb-5 text-lg font-medium text-center">
         Haz clic en{' '}
      <Link className="hover:underline text-cyan-600" to="/">
         Regresar al inicio &rarr; <br />
      </Link>{' '}
        e inicia sesión nuevamente.
    </h4>
  </div>
);
