export const capitalizePhrase = (phrase: string) => {
  return phrase
    .toLowerCase()
    .trim()
    .split(' ')
    .map((word: any) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

//function tu turn a number into 2 decimals number format
export const getFormattedValue = (number: number) => {
  return number.toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export function generateRandomString(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  const timestamp = Math.floor(Date.now() / 1000);
  return (timestamp + result).slice(0, length);
}

