import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Login } from '../pages/login';
import { RegisterStore } from '../pages/register-store';
import { NotFound } from '../pages/404';
import { LandingPage } from '../pages/landing-page';
import { TermsOfService } from '../pages/terms-of-service';
import { Privacy } from '../pages/privacy';
import { RefundPolicy } from '../pages/refund-policy';
import { ShippingPolicy } from '../pages/shipping-policy';
import { RecoverPassword } from '../pages/recover-password';
import { ResetPassword } from '../pages/reset-password';
import { RegisterNewUser } from '../pages/register-new-user';

export const LoggedOutRouter = () => {
  return (
    <Router>
      <Switch>
        <Route path="/shipping-policy">
          <ShippingPolicy />
        </Route>
        <Route path="/refund-policy">
          <RefundPolicy />
        </Route>
        <Route path="/privacy">
          <Privacy />
        </Route>
        <Route path="/terms-service">
          <TermsOfService />
        </Route>
        <Route path="/register-store">
          <RegisterStore />
        </Route>
        <Route path="/register-new-user">
          <RegisterNewUser />
        </Route>
        <Route path="/login" exact>
          <Login />
        </Route>
        <Route path="/recover-password" exact>
          <RecoverPassword />
        </Route>
        <Route path="/reset-password" exact>
          <ResetPassword />
        </Route>
        <Route path="/" exact>
          <LandingPage />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
};
