import React from 'react';
import { Document, Page, Text, StyleSheet } from '@react-pdf/renderer';
// import {  DOC_HEADER, DOC_FOOTER } from '../constants';

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 15,
    paddingBottom: 10,
    paddingHorizontal: 25,
  },
  header: {
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'Helvetica-Bold',
    marginBottom: '10'
  },
  title: {
    fontSize: 14,
    textAlign: 'center',
    fontFamily: 'Helvetica-Bold',
    marginBottom: '15',
    marginTop: '10'
  },
  subtitle: {
    fontSize: 10,
    fontFamily: 'Helvetica-Bold'
  },
  text: {
    fontSize: 10,
    textAlign: 'justify',
    fontFamily: 'Helvetica',
    marginBottom: '5'
  },
  textcenter: {
    fontSize: 10,
    textAlign: 'center',
    fontFamily: 'Helvetica',
    marginBottom: '5'
  },
});

const createDynamicExtra = (d:any)=>
{
  const temp : any = [];
  const lines = d.split("|");
  for(const item of lines)
  {
    if(item.length > 0)
    {
      temp.push(<Text style={styles.text}>{item}</Text>);
    }
    else
    {
      temp.push(<Text style={styles.text}> </Text>);
    }
  }
  return temp;
}

// Create Document Component
export const notaVentaPagatodito = (lines:string) => {

  return (
    <Document>
      <Page size="A6" style={styles.body}>
        {createDynamicExtra(lines)}
      </Page>
    </Document>
  );
}
