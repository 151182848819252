import React, { useState } from 'react';
import { Titles } from '../../enums/titles.enum';
import { Helmet } from 'react-helmet-async';
import { Labels } from '../../enums/labels.enum';
import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
import { useMe } from '../../hooks/use-me';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faPlus } from '@fortawesome/free-solid-svg-icons';
import { DatePicker, Modal as AModal, Table } from 'antd';
import { Modal } from '../../components/modal';
import moment from 'moment';
import {
  users,
  users_users_nodes_User,
  usersVariables,
} from '../../__api__/users';
import { IdentificationType } from '../../enums/spanish.enum';
import { CreateUser } from './create-user';
import {
  SkeletonTable,
  SkeletonTableColumnsType,
} from '../../components/skeleton-table';

const { RangePicker } = DatePicker;

const USERS_QUERY = gql`
  query users($input: UserSearcherInput!) {
    users(input: $input) {
      meta {
        nodeCount
        nodesPerPage
        pageCount
        pageCurrent
      }
      nodes {
        __typename
        ... on User {
          id
          email
          createdAt
          customer {
            name
            lastName
            identificationType
            identificationNumber
            cellPhone
          }
        }
      }
    }
  }
`;

const DEFAULT_PAGE_SIZE = 10;

export const Users = () => {
  const [pageCurrent, setPageCurrent] = useState(1);
  const [nodesPerPage, setNodesPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [dateTo, setDateTo] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onChange = (values: any) => {
    if (!values) {
      setDateFrom(null);
      setDateTo(null);
      return;
    }

    // Establecer la fecha desde con hora a 00:00
    const dateFrom = values[0].startOf('day').format(); // 00:00

    // Establecer la fecha hasta con hora a 23:59
    const dateTo = values[1].endOf('day').format(); // 23:59

    setDateFrom(dateFrom);
    setDateTo(dateTo);
  };
  const { data: user } = useMe();
  const { data, loading, refetch } = useQuery<users, usersVariables>(
    USERS_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        input: {
          pageCurrent,
          nodesPerPage,
          where: {
            customerParentId: user?.me.customer?.id,
            dateFrom,
            dateTo,
          },
        },
      },
    },
  );

  const users = data?.users.nodes as users_users_nodes_User[] | null;

  const success = () => {
    AModal.success({
      content: 'Usuario creado satisfactoriamente',
    });
  };

  const columns = [
    {
      title: 'Correo',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Nombres',
      dataIndex: 'fullName',
      key: 'fullName',
    },
    {
      title: 'Tipo de identificación',
      dataIndex: 'identificationType',
      key: 'identificationType',
    },
    {
      title: 'Identificación',
      dataIndex: 'identificationNumber',
      key: 'identificationNumber',
    },
    {
      title: 'Celular',
      dataIndex: 'cellPhone',
      key: 'cellPhone',
    },
    {
      title: 'Fecha de creación',
      dataIndex: 'date',
      key: 'date',
    },
  ];

  const datasource = users?.map((item) => ({
    key: item.id,
    email: item.email,
    fullName: `${item.customer?.name} ${item.customer?.lastName}`,
    identificationType:
      IdentificationType[item.customer?.identificationType as any],
    identificationNumber: item.customer?.identificationNumber,
    cellPhone: item.customer?.cellPhone,
    date: item.createdAt,
  }));

  return (
    <div>
      <Modal
        title={Labels.CREATE_USER}
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        afterClose={async () => {
          setPageCurrent(1);
          setDateTo(null);
          setDateTo(null);
          await refetch();
        }}
        child={
          <CreateUser
            onOk={() => {
              setIsModalVisible(false);
              success();
            }}
          />
        }
      />
      <Helmet>
        <title>{Titles.USERS}</title>
      </Helmet>
      <div className="py-6">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-900 leading-6">
              {Labels.MENU_USERS}
            </h3>
            <button
              type="button"
              onClick={() => {
                setIsModalVisible(true);
              }}
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent shadow-sm leading-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <div className="flex items-center justify-between">
                <span className="pr-2">{Labels.CREATE}</span>
                <FontAwesomeIcon icon={faPlus} />
              </div>
            </button>
          </div>
          <div className="mt-5 mb-5 md:mt-0 md:col-span-2">
            <form className="flex flex-col items-center w-full py-8 bg-white shadow sm:rounded-3xl sm:px-4">
              <div className="mb-4 mr-auto">
                {Labels.FILTERS}
                <FontAwesomeIcon icon={faFilter} className="ml-3 text-xl" />
              </div>
              <div className="max-w-xl grid grid-cols-1 gap-5">
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">{Labels.FILTER_DATES}</span>
                  </label>
                  <RangePicker
                    placeholder={['Fecha desde', 'Fecha hasta']}
                    className="w-full rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={onChange}
                    disabledDate={(current) => moment().isBefore(current)}
                  />
                </div>
              </div>
            </form>
          </div>
          <SkeletonTable
            active={true}
            loading={loading}
            columns={columns as SkeletonTableColumnsType[]}
          >
            <Table
              dataSource={datasource}
              columns={columns}
              pagination={{
                defaultPageSize: DEFAULT_PAGE_SIZE,
                pageSize: nodesPerPage,
                current: pageCurrent,
                total: data?.users.meta.nodeCount,
                responsive: true,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30', '100'],
                onShowSizeChange: (current, size) => setNodesPerPage(size),
                onChange: (page) => setPageCurrent(page),
              }}
            />
          </SkeletonTable>
        </div>
      </div>
    </div>
  );
};
