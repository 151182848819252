import { Link, useLocation } from 'react-router-dom';
import { Labels } from '../enums/labels.enum';
import face from '../images/face.svg';
import React, { useEffect, useState } from 'react';
import { Role } from '../__api__/globalTypes';
import { menuByRole } from '../utils/menu-utils';
import { MenuOption } from './menu-option';

interface IMobileMenuProps {
  fullName: string;
  email: string | undefined;
  role: Role | undefined;
  onLogout: () => Promise<void>;
  menuActive?: string | null;
}

export const MobileMenu: React.FC<IMobileMenuProps> = ({
  email,
  fullName,
  role,
  onLogout,
  menuActive,
}) => {
  const [openMenu, setOpenMenu] = useState(false);
  const toggleMenu = () => setOpenMenu((openMenu) => !openMenu);
  const getMenuClassName = () => `lg:hidden ${openMenu ? '' : 'hidden'}`;

  const menuItems = menuByRole(role);
  const activeClass =
    'block py-2 pl-3 pr-4 text-base font-medium text-indigo-700 border-l-4 border-indigo-500 bg-indigo-50';
  const inactiveClass =
    'block py-2 pl-3 pr-4 text-base font-medium text-gray-500 border-l-4 border-transparent hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700';
  const location = useLocation();
  const path = menuItems.find((x) => x.path === location.pathname)?.name || '';
  const defaultActive =
    path || menuItems.find((x) => x.isFirstOption)?.name || '';
  const [active, setActive] = useState(defaultActive);
  const action = (menuItem: string) => {
    setOpenMenu(false);
    setActive(menuItem);
  };

  useEffect(() => {
    setActive(menuActive || defaultActive);
  }, [menuActive]);

  return (
    <div>
      <div className="flex items-center -mr-2 lg:hidden">
        <button
          onClick={toggleMenu}
          type="button"
          className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          aria-controls="mobile-menu"
          data-todo-at-click="open = !open"
          aria-expanded="false"
          data-todo-x-bind-aria-expanded="open.toString()"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            data-todo-x-description="Icon when menu is closed.

Heroicon name: outline/menu"
            data-todo-x-state-on="Menu open"
            data-todo-x-state-off="Menu closed"
            className="block w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
          <svg
            data-todo-x-description="Icon when menu is open.

Heroicon name: outline/x"
            data-todo-x-state-on="Menu open"
            data-todo-x-state-off="Menu closed"
            className="hidden w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div
        data-todo-x-description="Mobile menu, show/hide based on menu state."
        className={getMenuClassName()}
        id="mobile-menu"
        data-todo-x-show="open"
      >
        <div className="pt-2 pb-3 space-y-1">
          {menuItems?.map((item) => (
            <MenuOption
              key={item.name}
              item={item.name}
              path={item.path}
              style={item.name === active ? activeClass : inactiveClass}
              action={() => action(item.name)}
            />
          ))}
        </div>
        <div className="pt-4 pb-3 border-t border-gray-200">
          <div className="flex items-center px-4">
            <div className="flex-shrink-0">
              <img className="w-10 h-10 rounded-full" src={face} alt="" />
            </div>
            <div className="ml-3">
              <div className="text-base font-medium text-gray-800">
                {fullName}
              </div>
              <div className="text-sm font-medium text-gray-500">{email}</div>
            </div>
          </div>
          <div className="mt-3 space-y-1">
            <Link
              onClick={() => setOpenMenu(false)}
              to="/my-profile"
              className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
            >
              {Labels.MY_PROFILE}
            </Link>
            <Link
              onClick={() => onLogout()}
              to="/"
              className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
            >
              {Labels.SIGN_OUT}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
