const KEYS = {
  leftKey: 37,
  rightKey: 39,
  backspace: 8,
  tab: 9,
  enter: 13,
  deleteKey: 46,
  digitZero: 48,
  digitNine: 57,
  digitZeroNumpad: 96,
  digitNineNumpad: 105,
  decimalPoint: 110,
  dot: 190,
  ctrl: 17,
  cmd: 91,
  vKey: 86,
};

export const removeSpecialCharacters = (e: any) => {
  e.target.value = e.target.value.replace(/[^0-9 ]/g, '');
  e.target.value = e.target.value.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '');
};

export const checkLength = (e: any) => {

  if (
    e.keyCode === KEYS.backspace ||
    e.keyCode === KEYS.deleteKey ||
    e.keyCode === KEYS.rightKey ||
    e.keyCode === KEYS.tab ||
    e.keyCode === KEYS.enter ||
    e.keyCode === KEYS.decimalPoint ||
    e.keyCode === KEYS.dot ||
    e.keyCode === KEYS.ctrl ||
    e.keyCode === KEYS.cmd ||
    e.keyCode === KEYS.vKey ||
    e.keyCode === KEYS.leftKey
  ) {
    return true;
  }
  if (isNumber(e) && e.target.value.length === e.target.maxLength) {
    e.stopPropagation();
    e.preventDefault();
    return false;
  }
  return true;
};

//function to check if a keycode pressed is a number
export const isNumber = (e: any) => {
  if (
    e.keyCode < KEYS.digitZero ||
    e.keyCode > KEYS.digitNine ||
    e.keyCode < KEYS.digitZeroNumpad ||
    e.keyCode > KEYS.digitNineNumpad ||
    e.shiftKey ||
    e.ctrlKey ||
    e.altKey
  ) {
    return false;
  }
  return true;
};
