import React from 'react';
import { Document, Page, Text, StyleSheet } from '@react-pdf/renderer';
import {  DOC_HEADER, DOC_FOOTER } from '../constants';
import { parseToShow,filterObject, keysToFilterDetail,keysToFilterResult, parseFormData, getValueFromArray, keysToFilterResultMTTR } from '../utils/display-utils';
import { FacType } from '../__api__/globalTypes';
import { PurchaseOrderStatus } from '../enums/spanish.enum';

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 15,
    paddingBottom: 10,
    paddingHorizontal: 25,
  },
  header: {
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'Helvetica-Bold',
    marginBottom: '10'
  },
  title: {
    fontSize: 14,
    textAlign: 'center',
    fontFamily: 'Helvetica-Bold',
    marginBottom: '15',
    marginTop: '10'
  },
  subtitle: {
    fontSize: 10,
    fontFamily: 'Helvetica-Bold'
  },
  text: {
    fontSize: 10,
    textAlign: 'justify',
    fontFamily: 'Helvetica',
    marginBottom: '5'
  },
  textcenter: {
    fontSize: 10,
    textAlign: 'center',
    fontFamily: 'Helvetica',
    marginBottom: '5'
  },
});

const createDynamicExtra = (d:any)=>
{
  if(d==undefined || d==null)
  {
    return [];
  }
  if(d.fields==undefined || d.fields==null)
  {
    return Object.entries(d)
    .filter(([key]) => !keysToFilterResultMTTR.includes(key))
    .map(([key, value]) => {
      if (key === 'voucher' && typeof value === 'object' && value !== null) {
        return Object.entries(value).filter(([key]) => !keysToFilterResultMTTR.includes(key)).map(([childKey, childValue]) => (
          <Text style={styles.text}>
            <Text style={styles.subtitle}>{childKey}: </Text>
            {parseToShow(childValue)}
          </Text>
        ));
      } else {
        return (
          <Text style={styles.text}>
            <Text style={styles.subtitle}>{key}: </Text>
            {parseToShow(value)}
          </Text>
        );
      }
    })
  }
  const temp : any = [];
  for(const item of filterObject(keysToFilterDetail,d.fields))
  {
    if(item.name!="precio_venta_publico")
    {
      temp.push(<Text style={styles.text}><Text style={styles.subtitle}>{item.name}: </Text>{parseToShow(item.value)}</Text>);
    }
  }
  const formData = parseFormData(getValueFromArray(d.fields,"formData"),getValueFromArray(d.fields,"formKey"));
  for(const item of formData)
  {
    temp.push(<Text style={styles.text}><Text style={styles.subtitle}>{item.name}: </Text>{parseToShow(item.value)}</Text>);
  }
  return temp;
}

const getDynamicTotal = (d:any, total: number)=>
{
  if(d.fields==undefined || d.fields==null)
  {
    return Number(total).toFixed(2);
  }
  if(d!=undefined && d!=null)
  {
    for(const item of d.fields)
    {
      if(item.name=="precio_venta_publico")
      {
        return Number(item.value).toFixed(2);
      }
    }
  }
  if(total==undefined)
  {
    return "0.00"
  }
  else
  {
    return Number(total).toFixed(2);
  }
}

// Create Document Component
export const notaVenta = (id:string, date:string, category:string, nombreProd:string, descProd:string, estado:string,total:number,datosDynamicos:any,resultadosDynamicos:any,nombreAgencia:string,nombreCli?:string | null,apellidoCli?:string | null, addressCli?: string | null, tipoci?:FacType | null, ci?:string | null) => {

  if(nombreCli==undefined || apellidoCli==undefined || addressCli==undefined || tipoci==undefined || ci == undefined || nombreCli==null || apellidoCli==null || addressCli==null || tipoci==null || ci == null)
  {
    return notaVentaClienteFinal(id,date,category,nombreProd,descProd,estado,total,datosDynamicos,resultadosDynamicos,nombreAgencia);
  }
  else
  {
    if(tipoci==FacType.CONSUMIDORFINAL)
    {
      return notaVentaClienteFinal(id,date,category,nombreProd,descProd,estado,total,datosDynamicos,resultadosDynamicos,nombreAgencia);
    }
    else
    {
      return (
        <Document>
          <Page size="A6" style={styles.body}>
            <Text style={styles.textcenter}>====================================</Text>
            <Text style={styles.header}>{DOC_HEADER}</Text>
            <Text style={styles.header}>Recibo de Venta</Text>
            <Text style={styles.text}><Text style={styles.subtitle}>ID Transaccion:</Text>{id==undefined?"No ID":id}</Text>
            <Text style={styles.text}><Text style={styles.subtitle}>Fecha:</Text>{date==undefined?"No fecha":date}</Text>
            <Text style={styles.text}><Text style={styles.subtitle}>Cliente:</Text>{nombreCli+" "+apellidoCli}</Text>
            <Text style={styles.text}><Text style={styles.subtitle}>Dirección:</Text>{addressCli}</Text>
            <Text style={styles.text}><Text style={styles.subtitle}>Tipo Documento:</Text>{tipoci}</Text>
            <Text style={styles.text}><Text style={styles.subtitle}>Documento:</Text>{ci}</Text>
            <Text style={styles.text}><Text style={styles.subtitle}>Agencia:</Text>{nombreAgencia}</Text>
            <Text style={styles.title}>{category==undefined?"No Category":category}</Text>
            <Text style={styles.text}><Text style={styles.subtitle}>Nombre Producto:</Text>{nombreProd==undefined?"No Nombre":nombreProd}</Text>
            {createDynamicExtra(datosDynamicos)}
            {createDynamicResults(resultadosDynamicos)}
            <Text style={styles.text}><Text style={styles.subtitle}>Estado:</Text>{estado==undefined?"PENDIENTE":PurchaseOrderStatus[estado]}</Text>
            <Text style={styles.textcenter}>====================================</Text>
            <Text style={styles.text}><Text style={styles.subtitle}>TOTAL:   </Text>${getDynamicTotal(datosDynamicos,total)}</Text>
            <Text style={styles.textcenter}>====================================</Text>
            <Text style={styles.text}>{DOC_FOOTER}</Text>
          </Page>
        </Document>
      );
    }
  }
}

const createDynamicResults = (d:any) =>{
  if(d==undefined || d==null)
  {
    return [];
  }
  
  if(d.fields==undefined || d.fields==null)
  {
    return Object.entries(d)
    .filter(([key]) => !keysToFilterResultMTTR.includes(key))
    .map(([key, value]) => {
      if (key === 'voucher' && typeof value === 'object' && value !== null) {
        return Object.entries(value).filter(([key]) => !keysToFilterResultMTTR.includes(key)).map(([childKey, childValue]) => (
          <Text style={styles.text}>
            <Text style={styles.subtitle}>{childKey}: </Text>
            {parseToShow(childValue)}
          </Text>
        ));
      } else {
        return (
          <Text style={styles.text}>
            <Text style={styles.subtitle}>{key}: </Text>
            {parseToShow(value)}
          </Text>
        );
      }
    })
  }
  if(isPagatodito(d))
  {
    return [];
  }
  const temp : any = [];
  for(const item of filterObject(keysToFilterResult,d.fields))
  {
    temp.push(<Text style={styles.text}><Text style={styles.subtitle}>{item.name}: </Text>{parseToShow(item.value)}</Text>);
  }
  return temp;
}

const isPagatodito = (d:any) =>
{
  if(d.fields==undefined || d.fields==null)
  {
    return false;
  }
  for(const item of d.fields)
  {
    if(item.name=="respuesta_proveedor")
    {
      return true;
    }
  }
  return false;
}

const notaVentaClienteFinal = (id:string, date:string, category:string, nombreProd:string,descProd:string, estado:string,total:number,datosDynamicos:any,resultadosDynamicos:any,nombreAgencia:string) => {
  return (
    <Document>
      <Page size="A6" style={styles.body}>
        <Text style={styles.textcenter}>====================================</Text>
        <Text style={styles.header}>{DOC_HEADER}</Text>
        <Text style={styles.header}>Recibo de Venta</Text>
        <Text style={styles.text}><Text style={styles.subtitle}>ID Transaccion:</Text>{id==undefined?"No ID":id}</Text>
        <Text style={styles.text}><Text style={styles.subtitle}>Fecha:</Text>{date==undefined?"No fecha":date}</Text>
        <Text style={styles.text}><Text style={styles.subtitle}>Cliente:</Text>Consumidor Final</Text>
        <Text style={styles.text}><Text style={styles.subtitle}>Dirección:</Text>N/A</Text>
        <Text style={styles.text}><Text style={styles.subtitle}>Tipo Documento:</Text>Consumidor Final</Text>
        <Text style={styles.text}><Text style={styles.subtitle}>Documento:</Text>9999999999</Text>
            <Text style={styles.text}><Text style={styles.subtitle}>Agencia:</Text>{nombreAgencia}</Text>
        <Text style={styles.title}>{category==undefined?"No Category":category}</Text>
        <Text style={styles.text}><Text style={styles.subtitle}>Nombre Producto:</Text>{nombreProd==undefined?"No Nombre":nombreProd}</Text>
        {createDynamicExtra(datosDynamicos)}
        {createDynamicResults(resultadosDynamicos)}
        <Text style={styles.text}><Text style={styles.subtitle}>Estado:</Text>{estado==undefined?"PENDIENTE":PurchaseOrderStatus[estado]}</Text>
        <Text style={styles.textcenter}>====================================</Text>
        <Text style={styles.text}><Text style={styles.subtitle}>TOTAL:   </Text>${getDynamicTotal(datosDynamicos,total)}</Text>
        <Text style={styles.textcenter}>====================================</Text>
        <Text style={styles.text}>{DOC_FOOTER}</Text>
      </Page>
    </Document>
  );
}
