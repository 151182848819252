import { Link, useLocation } from 'react-router-dom';
import { Labels } from '../enums/labels.enum';
import face from '../images/face.svg';
import React, { useEffect, useState } from 'react';
import { Role } from '../__api__/globalTypes';
import { menuByRole } from '../utils/menu-utils';
import { MenuOption } from './menu-option';

interface IMenuProps {
  role: Role | undefined;
  onLogout: () => Promise<void>;
  menuActive?: string | null;
}



export const Menu: React.FC<IMenuProps> = ({ role, onLogout, menuActive }) => {
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const toggleSubMenu = () => setOpenSubMenu((openSubMenu) => !openSubMenu);
  const getSubMenuClassName = () =>
    `absolute right-0 w-48 py-1 mt-2 bg-white shadow-lg origin-top-right rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none ${
      openSubMenu ? '' : 'hidden'
    }`;
  const menuItems = menuByRole(role);
  const activeClass =
    'inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900 border-b-2 border-indigo-500';
  const inactiveClass =
    'inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-500 border-b-2 border-transparent hover:border-gray-300 hover:text-gray-700';
  const location = useLocation();
  const path = menuItems.find((x) => x.path === transformRoute(location.pathname))?.name || '';
  const defaultActive = path || menuItems.find((x) => x.isFirstOption)?.name || '';
  const [active, setActive] = useState(defaultActive);
  const action = (menuItem: string) => {
    setOpenSubMenu(false);
    setActive(menuItem);
  };

  function transformRoute(route: string): string {
    if (route.startsWith('/products/')) {
      return "/products";
    }
    return route;
  }

  useEffect(() => {
    setActive(menuActive || defaultActive);
  }, [menuActive]);

  return (
    <div className="px-4 mx-auto max-w-7xl lg:px-6 lg:px-8">
      <div className="flex justify-between lg:h-16">
        <div className="flex">
          <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
            {menuItems?.map((item) => (
              <MenuOption
                key={item.name}
                item={item.name}
                path={item.path}
                style={item.name === active ? activeClass : inactiveClass}
                action={() => action(item.name)}
              />
            ))}
          </div>
        </div>
        <div className="hidden lg:ml-6 lg:flex lg:items-center">
          <div
            data-todo-x-data="{ open: false }"
            data-todo-at-keydown-escape-stop="open = false"
            data-todo-at-click-away="open = false"
            className="relative ml-3"
          >
            <div>
              <button
                type="button"
                className="flex text-sm bg-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                id="user-menu"
                aria-expanded="false"
                data-todo-at-click="open = !open"
                aria-haspopup="true"
                data-todo-x-bind-aria-expanded="open"
              >
                <span className="sr-only">Open user menu</span>
                <img
                  onClick={toggleSubMenu}
                  className="w-8 h-8 rounded-full"
                  src={face}
                  alt=""
                />
              </button>
            </div>

            <div
              data-todo-x-description="Dropdown menu, show/hide based on menu state."
              data-todo-x-show="open"
              data-todo-x-transition-enter="transition ease-out duration-200"
              data-todo-x-transition-enter-start="transform opacity-0 scale-95"
              data-todo-x-transition-enter-end="transform opacity-100 scale-100"
              data-todo-x-transition-leave="transition ease-in duration-75"
              data-todo-x-transition-leave-start="transform opacity-100 scale-100"
              data-todo-x-transition-leave-end="transform opacity-0 scale-95"
              className={getSubMenuClassName()}
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu"
            >
              <Link
                onClick={() => setOpenSubMenu(false)}
                to="/my-profile"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                {Labels.MY_PROFILE}
              </Link>
              <Link
                onClick={() => onLogout()}
                to="/"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              >
                {Labels.SIGN_OUT}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
