export enum Titles {
  LOGIN_PAGE = 'Login',
  RECOVER_PASSWORD = 'Recuperar contraseña',
  RESET_PASSWORD = 'Resetear contraseña',
  REGISTER_STORE_PAGE = 'Registrar Tienda',
  NOT_FOUND = 'Página no encontrada',
  MY_PROFILE = 'Mi perfil',
  MAIN = 'Inicio',
  CONSIGNMENTS = 'Consignaciones',
  USERS = 'Usuarios',
  PURCHASES = 'Compras',
  ORDERS = 'Pedidos',
  DEVOLUTIONS = 'Reclamos',
  CATEGORIES = 'Categorías de producto',
  PRODUCTS = 'Productos',
  PRODUCT_ACCOUNTS = 'Cuentas preestablecidas',
  LANDING = 'Gamerecar',
  SETTINGS = 'Configuraciones',
  REPORTS = 'Reportes',
  DOWNLOAD = 'Descargar Nota de Venta',
  TERMS = 'Terminos y Condiciones'
}
