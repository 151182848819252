import React from 'react';

interface IFormErrorProps {
  errorMessage: string;
}

export const FormError: React.FC<IFormErrorProps> = ({ errorMessage }) => (
  <div className="mt-2 mb-2">
    <span className="text-sm font-semibold text-red-400">{errorMessage}</span>
  </div>
);
