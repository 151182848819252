import { useEffect, useRef, useState } from "react";

interface SearchableDropdownProps<T> {
    label: string;
    options: T[];
    valueKey: keyof T; // Tipo genérico que toma la clave única del objeto
    labelFunction: (item: T) => string; // Función para mostrar el texto en la lista
    searchFunction: (item: T, filter: string) => boolean; // Función para buscar
    selectedValue: T[keyof T] | null; // Valor seleccionado basado en la clave del objeto
    onChange: (selectedOption: T | null) => void; // Callback para manejar el cambio, con opción de null
}

const SearchableDropdown = <T extends Record<string, any>>({
    label,
    options,
    valueKey,
    labelFunction,
    searchFunction,
    selectedValue,
    onChange,
}: SearchableDropdownProps<T>) => {
    const [filter, setFilter] = useState<string>('');
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<T | null>(
        options.find(option => option[valueKey] === selectedValue) || null
    );
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (selectedValue) {
            const initialOption = options.find(option => option[valueKey] === selectedValue);
            if (initialOption) {
                setSelectedOption(initialOption);
                setFilter(labelFunction(initialOption));
            }
        } else {
            setSelectedOption(null);
            setFilter('');
        }
    }, [selectedValue, options, valueKey, labelFunction]);

    const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(e.target.value);
        setShowOptions(true); // Mostrar opciones al escribir
    };

    const handleOptionSelect = (option: T) => {
        setSelectedOption(option);
        setFilter(labelFunction(option)); // Mostrar el nombre seleccionado en el input
        setShowOptions(false); // Ocultar opciones después de seleccionar
        onChange(option); // Llamar al callback con la opción seleccionada
    };

    const clearSelection = (e: React.MouseEvent) => {
        e.preventDefault(); // Evitar que abra el desplegable
        setSelectedOption(null);
        setFilter('');
        onChange(null); // Limpiar el valor seleccionado
    };

    // Filtrar opciones utilizando la función de búsqueda proporcionada
    const filteredOptions = options.filter(option => searchFunction(option, filter));

    // Manejar el clic fuera del componente para cerrar las opciones desplegadas
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setShowOptions(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (

        <div className="relative" ref={dropdownRef}>
            <input
                className="input truncate"
                type="text"
                placeholder={`Buscar ${label.toLowerCase()}`}
                value={filter}
                onChange={handleFilterChange}
                onFocus={() => setShowOptions(true)} // Mostrar opciones al hacer foco en el input
            />
            <div className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 cursor-pointer">
                {selectedOption ? (
                    <svg
                        className="fill-current h-4 w-4"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        onClick={clearSelection} // Limpiar la selección al hacer clic en la "X"
                    >
                        <path d="M14.348 14.849a1 1 0 01-1.415 0L10 11.415l-2.933 3.434a1 1 0 01-1.415-1.415L8.585 10 5.652 7.067a1 1 0 011.415-1.415L10 8.585l2.933-3.434a1 1 0 011.415 1.415L11.415 10l2.933 3.434a1 1 0 010 1.415z" />
                    </svg>
                ) : (
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M12.9 14.32a8 8 0 111.414-1.415l4.788 4.788-1.415 1.414-4.788-4.788zM8 14A6 6 0 108 2a6 6 0 000 12z" />
                    </svg>
                )}
            </div>
            {showOptions && filteredOptions.length > 0 && (
                <ul className="absolute z-10 w-full bg-white border border-gray-200 rounded mt-1 max-h-48 overflow-y-auto">
                    {filteredOptions.map((option, index) => (
                        <li
                            key={`${String(option[valueKey])}-${index}`}
                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => handleOptionSelect(option)}
                        >
                            {labelFunction(option)}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SearchableDropdown;
