import React, { useMemo, useState } from 'react';
import { ApolloError, gql } from '@apollo/client/core';
import { useMutation, useQuery } from '@apollo/client';
import {
  rangeDiscountsQuery,
  rangeDiscountsQuery_rangeDiscounts_nodes_DiscountRange,
} from '../../__api__/rangeDiscountsQuery';
import { DiscountStatusSpanish } from '../../enums/spanish.enum';
import { DatePicker, Modal as AModal, Table, Tag, Tooltip } from 'antd';
import {
  EyeIcon,
  EyeOffIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import { Labels } from '../../enums/labels.enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faPlus } from '@fortawesome/free-solid-svg-icons';
import {
  SkeletonTable,
  SkeletonTableColumnsType,
} from '../../components/skeleton-table';
import { useForm } from 'react-hook-form';
import { FormErrorMessages } from '../../enums/form-error-messages.enum';
import { FormError } from '../../components/form-error';
import { Button } from '../../components/button';
import { Exceptions } from '../../enums/exceptions.enum';
import { useMe } from '../../hooks/use-me';
import { useCategories } from '../../hooks/use-categories';
import {
  createRangeDiscountMutation,
  createRangeDiscountMutationVariables,
} from '../../__api__/createRangeDiscountMutation';
import { Modal } from '../../components/modal';
import { DiscountStatus } from '../../__api__/globalTypes';
import moment from 'moment';
import {
  updateRangeDiscountMutation,
  updateRangeDiscountMutationVariables,
} from '../../__api__/updateRangeDiscountMutation';
import {
  deleteRangeDiscountMutation,
  deleteRangeDiscountMutationVariables,
} from '../../__api__/deleteRangeDiscountMutation';

const { RangePicker } = DatePicker;

const RANGE_DISCOUNTS = gql`
  query rangeDiscountsQuery($input: DiscountSearcherInput!) {
    rangeDiscounts(input: $input) {
      meta {
        nodeCount
        pageCount
        pageCurrent
        nodesPerPage
      }
      nodes {
        __typename
        ... on DiscountRange {
          category {
            id
            name
          }
          id
          createdAt
          lowerLimit
          upperLimit
          discountPercentage
          status
        }
      }
    }
  }
`;

const CREATE_RANGE_DISCOUNT_MUTATION = gql`
  mutation createRangeDiscountMutation($input: CreateDiscountRangeInput!) {
    createDiscountRange(input: $input)
  }
`;

const UPDATE_RANGE_DISCOUNT_MUTATION = gql`
  mutation updateRangeDiscountMutation($input: UpdateDiscountRangeInput!) {
    updateDiscountRange(input: $input)
  }
`;

const DELETE_RANGE_DISCOUNT_MUTATION = gql`
  mutation deleteRangeDiscountMutation($input: DeleteDiscountRangeInput!) {
    deleteDiscountRange(input: $input)
  }
`;

const success = (content: string) => {
  AModal.success({
    content,
  });
};

interface CreateRangeDiscountForm {
  categoryId: string;
  lowerLimit: number;
  upperLimit: number;
  discountRate: number;
}

interface CreateRangeDiscountProps {
  onOk: () => void;
}

const CreateRangeDiscount: React.FC<CreateRangeDiscountProps> = ({ onOk }) => {
  const { data: user } = useMe();
  const { data: categories } = useCategories();

  const categoryOptions = useMemo(() => categories?.categories || [], [
    categories,
  ]);

  const {
    register,
    getValues,
    errors,
    handleSubmit,
    formState: { isValid },
  } = useForm<CreateRangeDiscountForm>({
    mode: 'onChange',
  });

  const onCompleted = (data: createRangeDiscountMutation) => {
    const { createDiscountRange: rangeDiscountId } = data;
    if (rangeDiscountId) {
      onOk();
    }
  };
  const [createRangeDiscountMutation, { loading, error }] = useMutation<
    createRangeDiscountMutation,
    createRangeDiscountMutationVariables
  >(CREATE_RANGE_DISCOUNT_MUTATION, {
    onCompleted,
  });
  const onSubmit = async () => {
    if (!loading) {
      try {
        const { ...input } = getValues();
        await createRangeDiscountMutation({
          variables: {
            input: {
              categoryId: input.categoryId,
              lowerLimit: Number(input.lowerLimit),
              upperLimit: Number(input.upperLimit),
              discountRate: Number(input.discountRate),
              userId: user?.me.id || '',
            },
          },
        });
      } catch (error) { }
    }
  };

  return (
    <div className="flex flex-col justify-center px-2 py-8 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label htmlFor="categoryId" className="label">
                Categoría
              </label>
              <div className="mt-1">
                <select
                  name="categoryId"
                  ref={register({ required: true })}
                  className="select"
                >
                  <option value="">{Labels.SELECTED_ONE}</option>
                  {categoryOptions.map((category: any) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="lowerLimit" className="label">
                Límite inferior
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    min: 0,
                  })}
                  name="lowerLimit"
                  min={0}
                  type="number"
                  step=".01"
                  className="input"
                />
                {errors.lowerLimit?.message && (
                  <FormError errorMessage={errors.lowerLimit?.message} />
                )}
                {errors.lowerLimit?.type === 'min' && (
                  <FormError errorMessage={FormErrorMessages.MIN_VALUE} />
                )}
              </div>
            </div>
            <div>
              <label htmlFor="upperLimit" className="label">
                Límite superior
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    min: 0,
                    validate: {
                      lessUpperLimit: (upperLimit) =>
                        Number(getValues('lowerLimit') || 0) < upperLimit ||
                        FormErrorMessages.LESS_UPPER_LIMIT,
                    },
                  })}
                  name="upperLimit"
                  min={0}
                  type="number"
                  step=".01"
                  className="input"
                />
                {errors.upperLimit?.message && (
                  <FormError errorMessage={errors.upperLimit?.message} />
                )}
                {errors.lowerLimit?.type === 'min' && (
                  <FormError errorMessage={FormErrorMessages.MIN_VALUE} />
                )}
              </div>
            </div>
            <div>
              <label htmlFor="discountRate" className="label">
                Porcentaje de descuento
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    min: 0.1,
                  })}
                  name="discountRate"
                  min={0.1}
                  type="number"
                  step=".01"
                  className="input"
                />
                {errors.discountRate?.message && (
                  <FormError errorMessage={errors.discountRate?.message} />
                )}
                {errors.discountRate?.type === 'min' && (
                  <FormError
                    errorMessage={FormErrorMessages.DISCOUNT_RATE_MIN_VALUE}
                  />
                )}
              </div>
            </div>
            <Button
              canClick={isValid}
              loading={loading}
              actionText={Labels.CREATE}
            />
            {error && (
              <FormError errorMessage={Exceptions[error.message as any]} />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

interface UpdateRangeDiscountForm {
  categoryId?: string;
  lowerLimit?: number;
  upperLimit?: number;
  discountRate?: number;
}

interface UpdateRangeDiscountProps {
  rangeDiscount: rangeDiscountsQuery_rangeDiscounts_nodes_DiscountRange | null;
  onOk: () => void;
}

const UpdateRangeDiscount: React.FC<UpdateRangeDiscountProps> = ({
  rangeDiscount,
  onOk,
}) => {
  const { data: user } = useMe();
  const { data: categories } = useCategories();

  const categoryOptions = useMemo(() => categories?.categories || [], [
    categories,
  ]);

  const {
    register,
    getValues,
    errors,
    handleSubmit,
  } = useForm<UpdateRangeDiscountForm>({
    mode: 'onChange',
    defaultValues: {
      categoryId: rangeDiscount?.category.id,
      lowerLimit: rangeDiscount?.lowerLimit,
      upperLimit: rangeDiscount?.upperLimit,
      discountRate: rangeDiscount?.discountPercentage,
    },
  });

  const onCompleted = (data: updateRangeDiscountMutation) => {
    const { updateDiscountRange: rangeDiscountId } = data;
    if (rangeDiscountId) {
      onOk();
    }
  };
  const [updateRangeDiscountMutation, { loading, error }] = useMutation<
    updateRangeDiscountMutation,
    updateRangeDiscountMutationVariables
  >(UPDATE_RANGE_DISCOUNT_MUTATION, {
    onCompleted,
  });
  const onSubmit = async () => {
    if (!loading) {
      try {
        const { ...input } = getValues();
        await updateRangeDiscountMutation({
          variables: {
            input: {
              categoryId: input.categoryId,
              lowerLimit: Number(input.lowerLimit),
              upperLimit: Number(input.upperLimit),
              discountRate: Number(input.discountRate),
              id: rangeDiscount?.id || '',
              userId: user?.me.id || '',
            },
          },
        });
      } catch (error) { }
    }
  };

  return (
    <div className="flex flex-col justify-center px-2 py-8 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label htmlFor="categoryId" className="label">
                Categoría
              </label>
              <div className="mt-1">
                <select
                  name="categoryId"
                  ref={register({ required: true })}
                  className="select"
                >
                  <option value="">{Labels.SELECTED_ONE}</option>
                  {categoryOptions.map((category: any) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="lowerLimit" className="label">
                Límite inferior
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    min: 0,
                  })}
                  name="lowerLimit"
                  min={0}
                  type="number"
                  step=".01"
                  className="input"
                />
                {errors.lowerLimit?.message && (
                  <FormError errorMessage={errors.lowerLimit?.message} />
                )}
                {errors.lowerLimit?.type === 'min' && (
                  <FormError errorMessage={FormErrorMessages.MIN_VALUE} />
                )}
              </div>
            </div>
            <div>
              <label htmlFor="upperLimit" className="label">
                Límite superior
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    min: 0,
                    validate: {
                      lessUpperLimit: (upperLimit) =>
                        Number(getValues('lowerLimit') || 0) < upperLimit ||
                        FormErrorMessages.LESS_UPPER_LIMIT,
                    },
                  })}
                  name="upperLimit"
                  min={0}
                  type="number"
                  step=".01"
                  className="input"
                />
                {errors.upperLimit?.message && (
                  <FormError errorMessage={errors.upperLimit?.message} />
                )}
                {errors.lowerLimit?.type === 'min' && (
                  <FormError errorMessage={FormErrorMessages.MIN_VALUE} />
                )}
              </div>
            </div>
            <div>
              <label htmlFor="discountRate" className="label">
                Porcentaje de descuento
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    min: 0.1,
                  })}
                  name="discountRate"
                  min={0.1}
                  type="number"
                  step=".01"
                  className="input"
                />
                {errors.discountRate?.message && (
                  <FormError errorMessage={errors.discountRate?.message} />
                )}
                {errors.discountRate?.type === 'min' && (
                  <FormError
                    errorMessage={FormErrorMessages.DISCOUNT_RATE_MIN_VALUE}
                  />
                )}
              </div>
            </div>
            <Button
              canClick={true}
              loading={loading}
              actionText={Labels.UPDATE}
            />
            {error && (
              <FormError errorMessage={Exceptions[error.message as any]} />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

const DEFAULT_PAGE_SIZE = 10;

export const RangeDiscounts = () => {
  const { data: categories } = useCategories();
  const { data: user } = useMe();

  const [pageCurrent, setPageCurrent] = useState(1);
  const [nodesPerPage, setNodesPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const [dateTo, setDateTo] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isModalEnableVisible, setIsModalEnableVisible] = useState(false);
  const [isModalUpdateVisible, setIsModalUpdateVisible] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [status, setStatus] = useState<DiscountStatus | null>(null);
  const [
    rangeDiscount,
    setRangeDiscount,
  ] = useState<rangeDiscountsQuery_rangeDiscounts_nodes_DiscountRange | null>(
    null,
  );

  const { data, loading, refetch } = useQuery<rangeDiscountsQuery>(
    RANGE_DISCOUNTS,
    {
      fetchPolicy: 'network-only',
      variables: {
        input: {
          pageCurrent,
          nodesPerPage,
          where: {
            categoryId,
            dateFrom,
            dateTo,
            status,
          },
        },
      },
    },
  );

  const onChange = (values: any) => {
    if (!values) {
      setDateFrom(null);
      setDateTo(null);
      return;
    }

    // Establecer la fecha desde con hora a 00:00
    const dateFrom = values[0].startOf('day').format(); // 00:00

    // Establecer la fecha hasta con hora a 23:59
    const dateTo = values[1].endOf('day').format(); // 23:59

    setDateFrom(dateFrom);
    setDateTo(dateTo);
  };

  const columns = [
    {
      title: 'Categoría',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Límite inferior',
      dataIndex: 'lowerLimit',
      key: 'lowerLimit',
    },
    {
      title: 'Límite superior',
      dataIndex: 'upperLimit',
      key: 'upperLimit',
    },
    {
      title: 'Porc. de descuento',
      dataIndex: 'discountRate',
      key: 'discountRate',
    },
    {
      title: 'Fecha de creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const rangeDiscounts = data?.rangeDiscounts.nodes as
    | rangeDiscountsQuery_rangeDiscounts_nodes_DiscountRange[]
    | null;

  const datasource = rangeDiscounts?.map(
    (item: rangeDiscountsQuery_rangeDiscounts_nodes_DiscountRange) => ({
      key: item.id,
      category: item.category.name,
      createdAt: item.createdAt,
      lowerLimit: item.lowerLimit,
      upperLimit: item.upperLimit,
      discountRate: `${item.discountPercentage}%`,
      status: (
        <Tag color={item.status === 'ACTIVE' ? 'geekblue' : 'error'}>
          {DiscountStatusSpanish[item.status]}
        </Tag>
      ),
      action: (
        <div className="flex items-center justify-start">
          <div
            onClick={() => {
              setRangeDiscount(item);
              setIsModalEnableVisible(true);
            }}
            className="flex-shrink-0 mr-3"
          >
            <Tooltip
              title={item.status === 'ACTIVE' ? 'desactivar' : 'activar'}
            >
              {item.status === 'ACTIVE' && (
                <EyeOffIcon
                  className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                  aria-hidden="true"
                />
              )}
              {item.status === 'INACTIVE' && (
                <EyeIcon
                  className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                  aria-hidden="true"
                />
              )}
            </Tooltip>
          </div>
          <div
            onClick={() => {
              setRangeDiscount(item);
              setIsModalUpdateVisible(true);
            }}
            className="flex-shrink-0"
          >
            <Tooltip title="editar">
              <PencilIcon
                className="w-6 h-6 mr-3 text-gray-400 cursor-pointer hover:text-blue-400"
                aria-hidden="true"
              />
            </Tooltip>
          </div>
          <div
            onClick={() => {
              setRangeDiscount(item);
              setIsModalDeleteVisible(true);
            }}
            className="flex-shrink-0"
          >
            <Tooltip title="eliminar">
              <TrashIcon
                className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                aria-hidden="true"
              />
            </Tooltip>
          </div>
        </div>
      ),
    }),
  );

  const onCompletedEnable = async (data: updateRangeDiscountMutation) => {
    const { updateDiscountRange: rangeDiscountId } = data;
    if (rangeDiscountId) {
      setErrorMessage(null);
      setIsModalEnableVisible(false);
      success('Se actualizó el descuento por rango satisfactoriamente');
      await refetch();
    }
  };
  const [updateRangeDiscountMutation, { loading: loadingEnable }] = useMutation<
    updateRangeDiscountMutation,
    updateRangeDiscountMutationVariables
  >(UPDATE_RANGE_DISCOUNT_MUTATION, {
    onCompleted: onCompletedEnable,
  });

  const enableOrDisableRangeDiscount = async (
    rangeDiscountId: string | undefined,
    isEnabled: boolean,
  ) => {
    if (!loadingEnable) {
      try {
        await updateRangeDiscountMutation({
          variables: {
            input: {
              id: rangeDiscountId || '',
              userId: user?.me.id || '',
              status: isEnabled
                ? DiscountStatus.ACTIVE
                : DiscountStatus.INACTIVE,
            },
          },
        });
      } catch (error) { }
    }
  };

  const onCompleted = (data: deleteRangeDiscountMutation) => {
    const { deleteDiscountRange: rangeDiscountId } = data;
    if (rangeDiscountId) {
      setIsModalDeleteVisible(false);
      success('Descuento por rango eliminado satisfactoriamente');
    }
    setErrorMessage(null);
  };

  const onError = (error: ApolloError) => {
    if (error) {
      setErrorMessage(error.message);
    }
  };

  const [deleteRangeDiscountMutation, { loading: loadingDelete }] = useMutation<
    deleteRangeDiscountMutation,
    deleteRangeDiscountMutationVariables
  >(DELETE_RANGE_DISCOUNT_MUTATION, {
    onCompleted,
    onError,
  });

  const deleteRangeDiscount = async (rangeDiscountId?: string) => {
    if (!loadingDelete) {
      try {
        await deleteRangeDiscountMutation({
          variables: {
            input: {
              id: rangeDiscountId || '',
              userId: user?.me.id || '',
            },
          },
        });
      } catch (error) { }
    }
  };

  return (
    <div>
      <Modal
        title={'Crear descuento por rango'}
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        afterClose={async () => {
          await refetch();
        }}
        child={
          <CreateRangeDiscount
            onOk={() => {
              setIsModalVisible(false);
              success('Descuento por rango creado satisfactoriamente');
            }}
          />
        }
      />
      <Modal
        title={'Actualizar descuento por rango'}
        visible={isModalUpdateVisible}
        onOk={() => setIsModalUpdateVisible(false)}
        onCancel={() => setIsModalUpdateVisible(false)}
        afterClose={async () => {
          await refetch();
        }}
        child={
          <UpdateRangeDiscount
            rangeDiscount={rangeDiscount}
            onOk={() => {
              setIsModalUpdateVisible(false);
              success('Descuento por rango actualizado satisfactoriamente');
            }}
          />
        }
      />
      <Modal
        title={`${rangeDiscount?.status === DiscountStatus.ACTIVE
          ? 'Desactivar'
          : 'Activar'
          } descuento por rango`}
        visible={isModalEnableVisible}
        onOk={async () => {
          await enableOrDisableRangeDiscount(
            rangeDiscount?.id,
            rangeDiscount?.status === DiscountStatus.INACTIVE,
          );
        }}
        onCancel={() => {
          setIsModalEnableVisible(false);
          setErrorMessage(null);
        }}
        footer={true}
        afterClose={async () => {
          setErrorMessage(null);
          await refetch();
        }}
        child={
          <div className="flex flex-col">
            <span className="mb-4">
              {`Estás seguro de ${rangeDiscount?.status === DiscountStatus.ACTIVE
                ? 'desactivar'
                : 'activar'
                } el descuento por rango?`}
            </span>
            {errorMessage && (
              <FormError errorMessage={Exceptions[errorMessage as any]} />
            )}
          </div>
        }
      />
      <Modal
        title={'Eliminar descuento por rango'}
        visible={isModalDeleteVisible}
        onOk={async () => {
          await deleteRangeDiscount(rangeDiscount?.id);
        }}
        onCancel={() => {
          setIsModalDeleteVisible(false);
          setErrorMessage(null);
        }}
        footer={true}
        afterClose={async () => {
          setErrorMessage(null);
          await refetch();
        }}
        child={
          <div className="flex flex-col">
            <span className="mb-4">
              Estás seguro de eliminar el descuento por rango?
            </span>
            {errorMessage && (
              <FormError errorMessage={Exceptions[errorMessage as any]} />
            )}
          </div>
        }
      />
      <div className="py-2">
        <div className="py-2 mx-auto max-w-7xl">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-900 leading-6">
              Descuentos por rango
            </h3>
            <button
              type="button"
              onClick={() => {
                setIsModalVisible(true);
              }}
              className="inline-flex items-center px-3 py-2 mr-3 text-sm font-medium text-white bg-indigo-600 border border-transparent shadow-sm leading-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <div className="flex items-center justify-between">
                <span className="pr-2">{Labels.CREATE}</span>
                <FontAwesomeIcon icon={faPlus} />
              </div>
            </button>
          </div>
          <div className="mt-5 mb-5 md:mt-0 md:col-span-2">
            <form className="flex flex-col items-center w-full py-8 bg-white shadow sm:rounded-3xl sm:px-4">
              <div className="mb-4 mr-auto">
                {Labels.FILTERS}
                <FontAwesomeIcon icon={faFilter} className="ml-3 text-xl" />
              </div>
              <div className="max-w-xl grid grid-cols-1 gap-5">
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">{Labels.FILTER_DATES}</span>
                  </label>
                  <RangePicker
                    placeholder={['Fecha desde', 'Fecha hasta']}
                    className="w-full rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={onChange}
                    disabledDate={(current) => moment().isBefore(current)}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="status"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">{Labels.FILTER_STATUS}</span>
                  </label>
                  <select
                    name="status"
                    onChange={(event) => {
                      const value = event.target.value;
                      setStatus(value ? (value as DiscountStatus) : null);
                    }}
                    className="w-full select"
                  >
                    <option value="">Todos</option>
                    {Object.keys(DiscountStatus).map((state) => (
                      <option key={state} value={state}>
                        {DiscountStatusSpanish[state as any]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="categoryId"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">Categorías:</span>
                  </label>
                  <select
                    name="categoryId"
                    onChange={(event) => {
                      const value = event.target.value;
                      setCategoryId(value === 'Todas' ? null : value);
                    }}
                    className="w-full select"
                  >
                    <option value="">Todas</option>
                    {categories?.categories.map((category: any) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </form>
          </div>
          <SkeletonTable
            active={true}
            loading={loading}
            columns={columns as SkeletonTableColumnsType[]}
          >
            <Table
              dataSource={datasource}
              columns={columns}
              pagination={{
                defaultPageSize: DEFAULT_PAGE_SIZE,
                pageSize: nodesPerPage,
                current: pageCurrent,
                total: data?.rangeDiscounts.meta.nodeCount,
                responsive: true,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30', '100'],
                onShowSizeChange: (current, size) => setNodesPerPage(size),
                onChange: (page) => setPageCurrent(page),
              }}
            />
          </SkeletonTable>
        </div>
      </div>
    </div>
  );
};
