import React, { useState } from 'react';
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/outline';
import { v4 as uuid } from 'uuid';
import { getFormattedValue } from '../../../utils/string-utils';
import {
    reviewOrders_adminOrders_nodes_AdminOrder,
} from '../../../__api__/reviewOrders';
import {
    updateResultOrderMutation,
    updateResultOrderMutationVariables,
} from '../../../__api__/updateResultOrderMutation';
import { useMe } from '../../../hooks/use-me';
import { Role } from '../../../__api__/globalTypes';
import { Labels } from '../../../enums/labels.enum';
import { useForm } from 'react-hook-form';
import { FormError } from '../../../components/form-error';
import { FormErrorMessages } from '../../../enums/form-error-messages.enum';
import { roundWithTwoDecimals } from '../../../utils/numeric-utils';
import { INPUT_TEXT_REGEX } from '../../../constants';
import { parseToShow, filterObject, keysToFilterDetail, Field } from '../../../utils/display-utils';
import { gql, useMutation } from '@apollo/client';
import { getExceptionMessage } from '../../../utils/exception-utils';

const UPDATE_RESULT_ORDER_MUTATION = gql`
  mutation updateResultOrderMutation($input: UpdateResultPurchaseOrderInput!) {
    updateResultPurchaseOrder(input: $input)
  }
`;

interface UpdateResultProps {
    order: reviewOrders_adminOrders_nodes_AdminOrder | null;
    onOk: () => void;
}


export const UpdateResultPurchaseOrder: React.FC<UpdateResultProps> = ({ order, onOk }) => {
    interface ResultTupleFieldProps {
        keyProp: string;
        defaultValue?: string;
        defaultName?: string;
    }
    const [result] = useState<any>((order?.result) ? order?.result : {fields: []});

    if(order == null || order == undefined)
    {
        return <div></div>;
    } 

    const ResultTupleField: React.FC<ResultTupleFieldProps> = ({ keyProp, defaultName, defaultValue }) => {
        const nameId = `name-${uuid()}`;
        const valueId = `value-${uuid()}`;
        return (
            <div className="px-3 py-3 border-2 border-r-2 rounded-2xl" key={keyProp}>
                <div
                    onClick={() => {
                        setFormElements((oldElements) =>
                            oldElements.filter(
                                (element) => element.props.keyProp !== keyProp,
                            ),
                        );
                    }}
                    className="flex justify-end"
                >
                    <TrashIcon
                        className="w-6 h-6 mr-3 cursor-pointer hover:text-blue-700"
                        aria-hidden="true"
                    />
                </div>
                <div className="mb-4" key={nameId}>
                    <label htmlFor={nameId} className="label">
                        Nombre
                    </label>
                    <div className="mt-1">
                        <input
                            ref={register({
                                required: FormErrorMessages.REQUIRED_FIELD,
                            })}
                            name={nameId}
                            type="text"
                            className="input"
                            defaultValue={defaultName ? defaultName : ''}
                        />
                        {errors[nameId]?.message && (
                            <FormError errorMessage={errors[nameId]?.message} />
                        )}
                    </div>
                </div>
                <div key={valueId}>
                    <label htmlFor={valueId} className="label">
                        Valor
                    </label>
                    <div className="mt-1">
                        <input
                            ref={register({
                                required: FormErrorMessages.REQUIRED_FIELD,
                            })}
                            name={valueId}
                            type="text"
                            className="input"
                            defaultValue={defaultValue ? defaultValue : ''}
                        />
                        {errors[valueId]?.message && (
                            <FormError errorMessage={errors[valueId]?.message} />
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const startResult: any[] = [];
    for (let i = 0; i < result.fields.length; i++) {
        startResult.push(<ResultTupleField keyProp={uuid()} defaultName={result.fields[i].name} defaultValue={result.fields[i].value} />);
    }
    const [formElements, setFormElements] = useState<any[]>(startResult);



    const { register, getValues, errors } = useForm({
        mode: 'onChange',
    });

    const key: string = uuid();
    const { data: user } = useMe();


    const onCompleted = async (data: updateResultOrderMutation) => {
        const { updateResultPurchaseOrder: orderId } = data;
        if (orderId) {
            onOk();
        }
    };

    const [
        updateOrderMutation,
        { loading: loadingMutation, error },
    ] = useMutation<updateResultOrderMutation, updateResultOrderMutationVariables>(
        UPDATE_RESULT_ORDER_MUTATION,
        { onCompleted },
    );

    const updateOrder = async (
        id: string,
        values?: { [x: string]: any },
    ) => {
        const fields: Field[] = [];
        let name: string | null, value: string | null;
        if (values) {
            Object.keys(values)
                .filter((key: string) => key !== 'comment')
                .forEach((key: string) => {
                    if (key.includes('name')) {
                        name = values[key];
                    }
                    if (key.includes('value')) {
                        value = values[key];
                    }
                    if (name && value) {
                        fields.push({ name, value });
                        name = null;
                        value = null;
                    }
                });
        }
        const result = fields.length > 0 ? { fields } : {fields: []};
        const comment = values ? values['comment'] : null;
        if (!loadingMutation) {
            try {
                await updateOrderMutation({
                    variables: {
                        input: {
                            userId: user?.me.id || '',
                            id,
                            comment,
                            result,
                        },
                    },
                });
            } catch (error) {console.log(error); }
        }
    };

    return (
        <div className="flex flex-col justify-center px-2 py-4 sm:px-6 lg:px-8">
            {error && <FormError errorMessage={getExceptionMessage(error)} />}
            {!error && (
                <div>
                    <div className="pb-10 space-y-6">
                        <div>
                            <h3 className="font-medium text-gray-900">Información</h3>
                            <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                                <div className="flex justify-between py-3 text-sm font-medium">
                                    <dt className="text-gray-500">Nombres</dt>
                                    <dd className="text-gray-900">{`${order?.customer.name} ${order?.customer.lastName}`}</dd>
                                </div>
                                <div className="flex justify-between py-3 text-sm font-medium">
                                    <dt className="text-gray-500">Identificación</dt>
                                    <dd className="text-gray-900">
                                        {order?.customer.identificationNumber}
                                    </dd>
                                </div>
                                <div className="flex justify-between py-3 text-sm font-medium">
                                    <dt className="text-gray-500">Celular</dt>
                                    <dd className="text-gray-900">{order?.customer.cellPhone}</dd>
                                </div>
                                <div className="flex justify-between py-3 text-sm font-medium">
                                    <dt className="text-gray-500">Producto</dt>
                                    <dd className="text-gray-900">{order?.product.name}</dd>
                                </div>
                                <div className="flex justify-between py-3 text-sm font-medium">
                                    <dt className="text-gray-500">Fecha del pedido</dt>
                                    <dd className="text-gray-900">{order?.createdAt}</dd>
                                </div>
                                {user?.me.role === Role.ADMIN && (
                                    <div>
                                        <div className="flex justify-between py-3 text-sm font-medium">
                                            <dt className="text-gray-500">Valor de compra</dt>
                                            <dd className="text-gray-900">{`$ ${getFormattedValue(
                                                order?.total || 0,
                                            )}`}</dd>
                                        </div>
                                        <div className="flex justify-between py-3 text-sm font-medium">
                                            <dt className="text-gray-500">Costo de producción</dt>
                                            <dd className="text-gray-900">
                                                {`$ ${getFormattedValue(order?.productionCost || 0)}`}
                                            </dd>
                                        </div>
                                        <div className="flex justify-between py-3 text-sm font-medium">
                                            <dt className="text-gray-500">Ganancia</dt>
                                            <dd className="text-gray-900">{`$ ${getFormattedValue(
                                                roundWithTwoDecimals(
                                                    (order?.total || 0) - (order?.productionCost || 0),
                                                ),
                                            )}`}</dd>
                                        </div>
                                    </div>
                                )}
                                {user?.me.role === Role.OPERATOR && (
                                    <div>
                                        <div className="flex justify-between py-3 text-sm font-medium">
                                            <dt className="text-gray-500">Valor de compra</dt>
                                            <dd className="text-gray-900">{`$ ${getFormattedValue(
                                                order?.total || 0,
                                            )}`}</dd>
                                        </div>
                                        <div className="flex justify-between py-3 text-sm font-medium">
                                            <dt className="text-gray-500">Costo de producción</dt>
                                            <dd className="text-gray-900">
                                                {`$ ${getFormattedValue(order?.productionCost || 0)}`}
                                            </dd>
                                        </div>
                                    </div>
                                )}
                            </dl>
                        </div>
                    </div>
                    <div className="pb-10 space-y-6">
                        <div>
                            <h3 className="font-medium text-gray-900">Detalle del pedido</h3>
                            <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                                {order?.adminDetail ? filterObject(keysToFilterDetail, order?.adminDetail.fields).map((field: Field) => (
                                    <div
                                        key={field.name}
                                        className="flex justify-between py-3 text-sm font-medium"
                                    >
                                        <dt className="text-gray-500">{field.name}</dt>
                                        <dd className="text-gray-900">{parseToShow(field.value)}</dd>
                                    </div>
                                )) : <div></div>}
                            </dl>
                        </div>
                    </div>

                    <div className="sm:mx-auto sm:w-full sm:max-w-xl">
                        <h3 className="mb-2 font-semibold">Actualizar pedido</h3>
                        <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
                            <form className="space-y-6">
                                <div
                                    onClick={() =>
                                        setFormElements((oldElements) => [
                                            ...oldElements,
                                            <ResultTupleField keyProp={key} />,
                                        ])
                                    }
                                    className="flex text-gray-700 cursor-pointer text-l group"
                                >
                                    <span className="mr-2 text-gray-700 group-hover:text-blue-700">
                                        Agregar campo de respuesta
                                    </span>
                                    <PlusCircleIcon
                                        className="w-6 h-6 mr-3 group-hover:text-blue-700"
                                        aria-hidden="true"
                                    />
                                </div>
                                {formElements &&
                                    formElements.map((element, index) => (
                                        <div key={index}>{element}</div>
                                    ))}
                                <div>
                                    <label htmlFor="comment" className="label">
                                        {Labels.COMMENT}
                                    </label>
                                    <div className="mt-1">
                                        <textarea
                                            ref={register({
                                                validate: {
                                                    isValidCharacters: (comment) =>
                                                        INPUT_TEXT_REGEX.test(comment) ||
                                                        FormErrorMessages.CHARACTERS,
                                                },
                                            })}
                                            name="comment"
                                            className="input"
                                            defaultValue="actualizado"
                                            placeholder={'Escribe un comentario opcional'}
                                        />
                                        {errors.comment?.message && (
                                            <FormError errorMessage={errors.comment?.message} />
                                        )}
                                    </div>
                                </div>
                                <div className="flex items-center justify-between">
                                    <button
                                        onClick={() =>
                                            updateOrder(
                                                order?.id || '',
                                                getValues(),
                                            )
                                        }
                                        type="button"
                                        className="flex justify-center w-full px-1 py-1 mr-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:opacity-90"
                                    >
                                        <div className="flex items-center justify-between">
                                            <span>{'Actualizar'}</span>
                                        </div>
                                    </button>
                                    <button
                                        onClick={() =>
                                            onOk()
                                        }
                                        type="button"
                                        className="flex justify-center w-full px-1 py-1 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:opacity-90"
                                    >
                                        <div className="flex items-center justify-between">
                                            <span>{'Cancelar'}</span>
                                        </div>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            )}
        </div>
    );
};
