import React from 'react';

interface IButtonProps {
  canClick: boolean;
  loading: boolean;
  actionText: string;
  onClick?: () => void;
}

export const Button: React.FC<IButtonProps> = ({
  canClick,
  loading,
  actionText,
  onClick,
}) => (
  <button
    onClick={onClick}
    className={`flex justify-center w-full px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
      canClick
        ? 'bg-indigo-600 hover:opacity-90'
        : 'bg-indigo-300 pointer-events-none'
    }`}
  >
    {loading ? 'Cargando...' : actionText}
  </button>
);
