import React from 'react';
import { Link } from 'react-router-dom';

interface IMenuOptionProps {
  item: string;
  path: string;
  style: string;
  action: () => void;
}

export const MenuOption: React.FC<IMenuOptionProps> = ({
  item,
  path,
  style,
  action,
}) => {
  return (
    <Link onClick={() => action()} to={path} className={style}>
      {item}
    </Link>
  );
};
