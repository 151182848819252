import React, { useState } from 'react';
import { ApolloError, gql } from '@apollo/client/core';
import { useMutation, useQuery } from '@apollo/client';
import {
  adminUsersQuery,
  adminUsersQuery_adminUsers,
} from '../../__api__/adminUsersQuery';
import { Modal as AModal, Table, Tag, Tooltip } from 'antd';
import { RoleSpanish, UserStatusSpanish } from '../../enums/spanish.enum';
import { Labels } from '../../enums/labels.enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { FormErrorMessages } from '../../enums/form-error-messages.enum';
import { FormError } from '../../components/form-error';
import { Button } from '../../components/button';
import { Exceptions } from '../../enums/exceptions.enum';
import { AdminRole, UserStatus } from '../../__api__/globalTypes';
import {
  createUserMutation,
  createUserMutationVariables,
} from '../../__api__/createUserMutation';
import { Modal } from '../../components/modal';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import {
  updateUserMutation,
  updateUserMutationVariables,
} from '../../__api__/updateUserMutation';
import { UPDATE_USER_MUTATION } from '../../common-mutations/common-mutations';
import {
  SkeletonTable,
  SkeletonTableColumnsType,
} from '../../components/skeleton-table';
import {
  EMAIL_REGEX,
  PASSWORD_VALID_CHARACTERS_REGEX,
  STRONG_PASSWORD_REGEX,
} from '../../constants';

const ADMIN_USERS = gql`
  query adminUsersQuery {
    adminUsers {
      id
      createdAt
      email
      status
      role
    }
  }
`;

const CREATE_USER_MUTATION = gql`
  mutation createUserMutation($input: CreateUserInput!) {
    createUser(input: $input)
  }
`;

const success = (content: string) => {
  AModal.success({
    content,
  });
};

interface CreateUserForm {
  email: string;
  password: string;
  role: AdminRole;
}

interface CreateUserProps {
  onOk: () => void;
}

const CreateUser: React.FC<CreateUserProps> = ({ onOk }) => {
  const {
    register,
    getValues,
    errors,
    handleSubmit,
    formState: { isValid },
  } = useForm<CreateUserForm>({
    mode: 'onChange',
  });

  const onCompleted = (data: createUserMutation) => {
    const { createUser: userId } = data;
    if (userId) {
      onOk();
    }
  };
  const [createUserMutation, { loading, error }] = useMutation<
    createUserMutation,
    createUserMutationVariables
  >(CREATE_USER_MUTATION, {
    onCompleted,
  });
  const onSubmit = async () => {
    if (!loading) {
      try {
        await createUserMutation({
          variables: {
            input: {
              ...getValues(),
            },
          },
        });
      } catch (error) {}
    }
  };

  return (
    <div className="flex flex-col justify-center px-2 py-8 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label htmlFor="email" className="label">
                {Labels.EMAIL}
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_EMAIL,
                    pattern: {
                      value: EMAIL_REGEX,
                      message: FormErrorMessages.VALID_EMAIL,
                    },
                  })}
                  name="email"
                  type="email"
                  className="input"
                />
                {errors.email?.message && (
                  <FormError errorMessage={errors.email?.message} />
                )}
              </div>
            </div>
            <div>
              <label htmlFor="password" className="label">
                {Labels.PASSWORD}
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_PASSWORD,
                    minLength: 8,
                    maxLength: 50,
                    validate: {
                      isValidCharacters: (password) =>
                        PASSWORD_VALID_CHARACTERS_REGEX.test(password) ||
                        FormErrorMessages.PASSWORD_ERROR_MESSAGE,
                      strongPassword: (password) =>
                        STRONG_PASSWORD_REGEX.test(password) ||
                        FormErrorMessages.PASSWORD_ERROR_MESSAGE,
                    },
                  })}
                  name="password"
                  type="password"
                  className="input"
                />
                {errors.password?.message && (
                  <FormError errorMessage={errors.password?.message} />
                )}
                {(errors.password?.type === 'minLength' ||
                  errors.password?.type === 'maxLength') && (
                  <FormError
                    errorMessage={FormErrorMessages.PASSWORD_ERROR_MESSAGE}
                  />
                )}
              </div>
            </div>
            <div>
              <label htmlFor="role" className="label">
                {Labels.ROLE}
              </label>
              <div className="mt-1">
                <select
                  name="role"
                  ref={register({ required: true })}
                  className="select"
                >
                  {Object.keys(AdminRole).map((role) => (
                    <option key={role} value={role}>
                      {RoleSpanish[role as any]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <Button
              canClick={isValid}
              loading={loading}
              actionText={Labels.CREATE}
            />
            {error && (
              <FormError errorMessage={Exceptions[error.message as any]} />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export const AdminUsers = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalEnableVisible, setIsModalEnableVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [user, setUser] = useState<adminUsersQuery_adminUsers | null>(null);

  const { data, loading, refetch } = useQuery<adminUsersQuery>(ADMIN_USERS, {
    fetchPolicy: 'network-only',
  });

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Rol',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Fecha de creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const datasource = data?.adminUsers?.map((user) => ({
    key: user.id,
    email: user.email,
    role: RoleSpanish[user.role],
    createdAt: user.createdAt,
    status: (
      <Tag color={user.status === 'ACTIVE' ? 'geekblue' : 'error'}>
        {UserStatusSpanish[user.status ? user.status : UserStatus.ACTIVE]}
      </Tag>
    ),
    action: (
      <div className="flex items-center justify-start">
        <div
          onClick={() => {
            setUser(user);
            setIsModalEnableVisible(true);
          }}
          className="flex-shrink-0"
        >
          <Tooltip title={user.status === 'ACTIVE' ? 'desactivar' : 'activar'}>
            {user.status === 'ACTIVE' && (
              <EyeOffIcon
                className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                aria-hidden="true"
              />
            )}
            {user.status === 'INACTIVE' && (
              <EyeIcon
                className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                aria-hidden="true"
              />
            )}
          </Tooltip>
        </div>
      </div>
    ),
  }));

  const onError = (error: ApolloError) => {
    if (error) {
      setErrorMessage(Exceptions[error.message as any]);
    }
  };

  const onCompleted = async (data: updateUserMutation) => {
    const { updateUser: userId } = data;
    if (userId) {
      setErrorMessage(null);
      setIsModalEnableVisible(false);
      success('Se actualizó el usuario satisfactoriamente');
      await refetch();
    }
  };

  const [updateUserMutation, { loading: loadingMutation }] = useMutation<
    updateUserMutation,
    updateUserMutationVariables
  >(UPDATE_USER_MUTATION, { onCompleted, onError });

  const enableOrDisableUser = async (
    userId: string | undefined,
    isEnabled: boolean,
  ) => {
    if (!loadingMutation) {
      try {
        await updateUserMutation({
          variables: {
            input: {
              userId: userId || '',
              status: isEnabled ? UserStatus.ACTIVE : UserStatus.INACTIVE,
            },
          },
        });
      } catch (error) {}
    }
  };

  return (
    <div className="py-2">
      <Modal
        title={Labels.CREATE_USER}
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        afterClose={async () => {
          await refetch();
        }}
        child={
          <CreateUser
            onOk={() => {
              setIsModalVisible(false);
              success('Usuario creado satisfactoriamente');
            }}
          />
        }
      />
      <Modal
        title={`${
          user?.status === UserStatus.ACTIVE ? 'Desactivar' : 'Activar'
        } usuario ${user?.email}`}
        visible={isModalEnableVisible}
        onOk={async () => {
          await enableOrDisableUser(
            user?.id,
            user?.status === UserStatus.INACTIVE,
          );
        }}
        onCancel={() => {
          setIsModalEnableVisible(false);
          setErrorMessage(null);
        }}
        footer={true}
        afterClose={async () => {
          setErrorMessage(null);
          await refetch();
        }}
        child={
          <div className="flex flex-col">
            <span className="mb-4">
              {`Estás seguro de ${
                user?.status === UserStatus.ACTIVE ? 'desactivar' : 'activar'
              } el usuario ${user?.email}?`}
            </span>
            {errorMessage && (
              <FormError errorMessage={Exceptions[errorMessage as any]} />
            )}
          </div>
        }
      />
      <div className="py-2 mx-auto max-w-7xl">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-medium text-gray-900 leading-6">
            {Labels.MENU_USERS}
          </h3>
          <button
            type="button"
            onClick={() => {
              setIsModalVisible(true);
            }}
            className="inline-flex items-center px-3 py-2 mr-2 text-sm font-medium text-white bg-indigo-600 border border-transparent shadow-sm leading-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <div className="flex items-center justify-between">
              <span className="pr-2">{Labels.CREATE}</span>
              <FontAwesomeIcon icon={faPlus} />
            </div>
          </button>
        </div>
        <SkeletonTable
          active={true}
          loading={loading}
          columns={columns as SkeletonTableColumnsType[]}
        >
          <Table dataSource={datasource} columns={columns} pagination={false} />
        </SkeletonTable>
      </div>
    </div>
  );
};
