import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
import { provinces } from '../__api__/provinces';

const PROVINCES_QUERY = gql`
  query provinces {
    provinces {
      id
      name
      cities {
        id
        name
      }
    }
  }
`;

export const useProvinces = () => {
  return useQuery<provinces>(PROVINCES_QUERY);
};
