import React, { useEffect, useRef } from 'react';
import { Card } from 'antd';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';


// Step 1: Define the product interface
export interface ProducttoShow {
    id: string;
    searchQuery: string;
    name: string;
    description: string;
    foto: string;
    handleCardClick: (product: ProducttoShow) => void;
}

// Step 2: Create the component
interface ProductShowroomProps {
  products: ProducttoShow[];
}

export const ProductShowroom: React.FC<ProductShowroomProps> = ({ products }) => {
  if(!products) return (<></>);
  if(products.length === 0) return (<></>);
  const itemsPerSlide = 3;
  const itemsPerSlideMobile = 1;
  const slides : JSX.Element[] = [];



  function redraw() {
    slides.length = 0;
    for (let i = 0; i < products.length; i += getItemsPerSlide()) {
      slides.push(
        <div key={i} style={{ display: 'flex', justifyContent: 'space-between', marginRight: "3em", marginLeft: "3em", marginBottom:"2em" }}>
          {products.slice(i, i + getItemsPerSlide()).map((product, index) => (
            <Card
              key={index}
              onClick={() => product.handleCardClick(product)}
              style={{ cursor: 'pointer' , flex: 1, margin: isMobile() ? '0':'0 10px'  }}
              cover={<img alt={product.name} src={product.foto} style={{ width: isMobile() ? '60%' : 'auto', height: isMobile() ? 'auto' :'20vh', objectFit: 'cover' }} />}
            >
              <Card.Meta title={product.name} description={product.description} />
            </Card>
          ))}
        </div>
      );
    }
  }

  function getItemsPerSlide(): number {
    if (window.innerWidth <= 768) {
      return itemsPerSlideMobile;
    }
    return itemsPerSlide;
  }

  function isMobile(): boolean {
    return window.innerWidth <= 768;
  }

  useEffect(() => {
    function handleResize() {
      redraw();
    }
  
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  redraw();

  const carouselRef = useRef(null);

  const renderArrowPrev = (onClickHandler: () => void, hasPrev: boolean, label: string) => {
    return hasPrev && (
      <button type="button" onClick={onClickHandler} aria-label={label} className="absolute z-10 left-4 top-1/2 transform -translate-y-1/2">
         <ChevronLeftIcon style={{ width: '2em', height: '2em' }}/>
      </button>
    );
  };
  
  const renderArrowNext = (onClickHandler: () => void, hasNext: boolean, label: string) => {
    return hasNext && (
      <button type="button" onClick={onClickHandler} aria-label={label} className="absolute z-10 right-4 top-1/2 transform -translate-y-1/2">
         <ChevronRightIcon style={{ width: '2em', height: '2em' }} />
      </button>
    );
  };
  
  return (
    <div className="relative">
      <Carousel autoPlay interval={10000} infiniteLoop showThumbs={false} showStatus={false} swipeable={false}
      renderArrowPrev={renderArrowPrev}
      renderArrowNext={renderArrowNext}
      ref={carouselRef}>
        {slides}
      </Carousel>
    </div>
  );
}