import React, { useState } from 'react';
import { FormErrorMessages } from '../../enums/form-error-messages.enum';
import { FormError } from '../../components/form-error';
import { useForm } from 'react-hook-form';
import { Labels } from '../../enums/labels.enum';
import { Button } from '../../components/button';
import {purchases_orders_nodes_PurchaseOrder } from '../../__api__/purchases';
import { createDevolucionMutation, createDevolucionMutationVariables } from '../../__api__/createDevolucionMutation';
import { gql, useMutation } from '@apollo/client';
import { Exceptions } from '../../enums/exceptions.enum';

const CREATE_DEVOLUCION = gql`
  mutation createDevolucionMutation($input: CrearDevolucionInput!) {
    createDevolucion(input: $input)
  }
`;

interface IDevolutionRequestProps {
  onOk: () => void;
  purchaseOrder: purchases_orders_nodes_PurchaseOrder | null;
}

interface DevolutionForm {
    request: string;
}

export const RequestDevolution: React.FC<IDevolutionRequestProps> = ({ onOk, purchaseOrder }) => {

    let primalLoading = false;
    const [errorCON,seterrorCON] = useState<string | null>(null);
    const {
        register,
        getValues,
        errors,
        handleSubmit,
        formState: { isValid },
    } = useForm<DevolutionForm>({
        mode: 'onChange',
    });

    const onCompleted = async (data: createDevolucionMutation) => {
        const { createDevolucion: id } = data;
        if (id) {
            primalLoading = true;
            onOk();
        }
    };

    const [createDevolucionMutation, { loading, error }] = useMutation<
        createDevolucionMutation,
        createDevolucionMutationVariables
    >(CREATE_DEVOLUCION, {
        onCompleted,
    });

    const onSubmit = async () => {
        if(primalLoading)
        {
        return;
        }
        primalLoading = true;
        if (!loading) {
            seterrorCON(null);
            try {
                const { request } = getValues();
                if(request=="")
                {
                    seterrorCON("Por favor ingresa la razon del reclamo");
                    return;
                }
                await createDevolucionMutation({
                variables: {
                    input: {
                        id: purchaseOrder?.id as string,
                        request: request,
                    },
                },
                });
            } catch (error : any) {
                //seterrorCON(error.toString());
            }
            finally
            {
                primalLoading = false;
            }
        }
    };


    return (
        <div className="flex flex-col justify-center px-2 py-8 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-xl">
                <div className="flex-1 min-w-0">
                <div className="flex items-center mb-4 space-x-3">
                    <h2 className="text-lg font-bold text-gray-900 leading-7">
                    Devolucion de {purchaseOrder?.product?.name}
                    </h2>
                </div>
                <h3 className="mb-6 text-base font-semibold text-gray-900 leading-7">
                    {purchaseOrder?.product?.description}
                </h3>
                <h4 className="mb-2 text-sm font-normal text-gray-900 leading-5">
                    Por favor ingresa la razon del Reclamo explicando el motivo de la misma
                </h4>
                </div>
                <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                    <div key="request">
                        <label htmlFor="request" className="label">
                        Razón del Reclamo
                        </label>
                        <div className="mt-1">
                        <textarea
                            ref={register({
                            required: FormErrorMessages.REQUIRED_FIELD,
                            maxLength: 1000
                            })}
                            name="request"
                            className="input"
                            maxLength={1000}
                        />
                        {errors["request"]?.message && (
                            <FormError errorMessage={errors["request"]?.message} />
                        )}
                        </div>
                    </div>
                    <Button
                    canClick={isValid}
                    loading={loading  || primalLoading}
                    actionText={Labels.DEVOLVER}
                    />
                    
                    {error && (
                        <FormError errorMessage={Exceptions[error.message as any] ? Exceptions[error.message as any] : error.message} />
                    )}
                </form>
                {errorCON && (
                    <FormError errorMessage={errorCON} />
                )}
                </div>
            </div>
        </div>
    );
};
