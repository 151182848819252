import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
import { categories } from '../__api__/categories';

const CATEGORIES = gql`
  query categories {
    categories {
      id
      imageURL
      name
    }
  }
`;

export const useCategories = () => {
  return useQuery<categories>(CATEGORIES);
};
