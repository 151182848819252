import React, { useState } from 'react';
import { FormErrorMessages } from '../../enums/form-error-messages.enum';
import { FormError } from '../../components/form-error';
import { useForm } from 'react-hook-form';
import { Labels } from '../../enums/labels.enum';
import { Exceptions } from '../../enums/exceptions.enum';
import { reviewDevoluciones_devoluciones_nodes_Devolucion } from '../../__api__/reviewDevoluciones';
import { DevType } from '../../__api__/globalTypes';
import { updateDevolucionMutation, updateDevolucionMutationVariables } from '../../__api__/updateDevolucionMutation';
import { UPDATE_DEVOLUTION_MUTATION } from '../../common-mutations/common-mutations';
import { useMutation } from '@apollo/client';

interface IDevolutionAnswerProps {
  onOk: () => void;
  devolution: reviewDevoluciones_devoluciones_nodes_Devolucion | null;
}

interface DevolutionForm {
    answer: string;
    amount : string;
}

export const AnswerDevolution: React.FC<IDevolutionAnswerProps> = ({ onOk, devolution }) => {

    const [errorCON,seterrorCON] = useState<string | null>(null);
    const {
        register,
        getValues,
        errors,
    } = useForm<DevolutionForm>({
        mode: 'onChange',
    });

    const onCompleted = async (data: updateDevolucionMutation) => {
        const { updateDevolucion: id } = data;
        if (id) {
            onOk();
        }
      };
    
      const [
        updateDevolucionMutation,
        { loading: loadingMutation, error },
      ] = useMutation<updateDevolucionMutation, updateDevolucionMutationVariables>(
        UPDATE_DEVOLUTION_MUTATION,
        { onCompleted },
      );
    
      const updateDevolucion = async (
        id: number,
        status: DevType,
        answer?: string,
        amount?: number) => {
        if (!loadingMutation) {
          try {
            await updateDevolucionMutation({
              variables: {
                input: {
                    id: id,
                    status: status,
                    answer: answer ? answer : "",
                    amount: amount ? amount : 0,
                },
              },
            });
          } catch (error) {}
        }
      };

    const onSubmit = async (status: DevType) => {
        seterrorCON("");
        const { answer, amount } = getValues();
        const amountNumber = Number(amount);
        if(Number.isNaN(amountNumber))
        {
            seterrorCON("La cantidad a devolver no es un numero");
            return;
        }
        if(!devolution)
        {
            seterrorCON("Error al cargar la devolucion");
            return;
        }
        if (amountNumber > (devolution.purchase ? devolution.purchase.total : 0) ) {
            seterrorCON("La cantidad a devolver no puede ser mayor a la cantidad solicitada");
            return;
        }
        if(amountNumber < 0)
        {
            seterrorCON("La cantidad a devolver no puede ser menor a 0");
            return;
        }
        if (answer === "" || answer === null || answer === undefined) {
            seterrorCON("La respuesta no puede estar vacia");
            return;
        }
        if(status === DevType.REJECTED && amountNumber > 0)
        {
            seterrorCON("No se puede devolver dinero si la devolucion es rechazada");
            return;
        }
        updateDevolucion(devolution.id, status, answer, status === DevType.REJECTED ? undefined : amountNumber);
    };
    

    return (
        <div className="flex flex-col justify-center px-2 py-8 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-xl">
                <div className="flex-1 min-w-0">
                <div className="flex items-center mb-4 space-x-3">
                    <h2 className="text-lg font-bold text-gray-900 leading-7">
                    Devolucion de {devolution?.purchase?.product?.name}
                    </h2>
                </div>
                <h3 className="mb-6 text-base font-semibold text-gray-900 leading-7">
                    {devolution?.purchase?.product?.description}
                </h3>
                <h4 className="mb-2 text-sm font-normal text-gray-900 leading-5">
                    Por favor ingrese la cantidad a devolver y la respuesta a la solicitud
                </h4>
                </div>
                <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
                <form className="space-y-6">
                    <div key="answer">
                        <label htmlFor="answer" className="label">
                        Respuesta de la solicitud
                        </label>
                        <div className="mt-1">
                        <textarea
                            ref={register({
                            required: FormErrorMessages.REQUIRED_FIELD,
                            maxLength: 1000
                            })}
                            name="answer"
                            className="input"
                            maxLength={1000}
                        />
                        {errors["answer"]?.message && (
                            <FormError errorMessage={errors["answer"]?.message} />
                        )}
                        </div>
                    </div>
                    <div key="amount">
                        <label htmlFor="answer" className="label">
                        Valor a devolver (Maximo {devolution?.purchase?.total})
                        </label>
                        <div className="mt-1">
                        <input
                            ref={register({
                                required: FormErrorMessages.REQUIRED_FIELD,
                                min: 0,
                            })}
                            name="amount"
                            min={0}
                            type="number"
                            step=".01"
                            className="input"
                            defaultValue={0}
                            />
                            {errors.amount?.message && (
                            <FormError errorMessage={errors.amount?.message} />
                            )}
                            {errors.amount?.type === 'min' && (
                            <FormError errorMessage={FormErrorMessages.MIN_VALUE} />
                            )}
                        </div>
                    </div>
                    <button
                      onClick={() =>
                        onSubmit(DevType.ACCEPTED)
                      }
                      type="button"
                      className="flex justify-center w-full px-1 py-1 mr-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:opacity-90"
                    >
                      <div className="flex items-center justify-between">
                        <span>{loadingMutation ? 'Cargando..' : Labels.DEVOLVER_APPROVED}</span>
                      </div>
                    </button>
                    <button
                      onClick={() =>
                        onSubmit(DevType.REJECTED)
                      }
                      type="button"
                      className="flex justify-center w-full px-1 py-1 mr-3 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:opacity-90"
                    >
                      <div className="flex items-center justify-between">
                        <span>{loadingMutation ? 'Cargando..' : Labels.DEVOLVER_REJECTED}</span>
                      </div>
                    </button>
                    
                    {error && (
                        <FormError errorMessage={Exceptions[error.message as any] ? Exceptions[error.message as any] : error.message} />
                    )}
                </form>
                {errorCON && (
                    <FormError errorMessage={errorCON} />
                )}
                </div>
            </div>
        </div>
    );
};
