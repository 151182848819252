import React from 'react';
import { FooterLogout } from '../components/footer-logout';
import { Link } from 'react-router-dom';
import logo from '../images/logo2.svg';
import { PointsDesign } from '../components/points-design';

const navigation = [{ name: 'Inicio', href: '/' }];

export const TermsOfService = () => {
  return (
    <div>
      <div className="px-4 pt-6 mx-auto max-w-7xl sm:px-6">
        <Link to="/">
          <span className="sr-only">Gamerecar</span>
          <img className="w-auto h-24" src={logo} alt="Gamerecar" />
        </Link>
      </div>
      <div className="relative py-8 overflow-hidden bg-white">
        <PointsDesign />
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="mx-auto text-lg max-w-prose">
            <h1>
              <span className="block text-xl font-semibold tracking-wide text-center text-indigo-600 uppercase">
                Términos del servicio
              </span>
              <span className="block mt-8 text-2xl font-extrabold tracking-tight text-center text-gray-900 leading-8">
                Descripción general
              </span>
            </h1>
            <p className="mt-8 text-xl text-gray-500 leading-8">
              Este sitio web es operado por GAMERECAR SAS En todo el
              sitio, los términos “nosotros”, “nos” y “nuestro” se refieren a
              GAMERECAR SAS. gamerecar.com ofrece este sitio web,
              que incluye toda la información, herramientas y servicios
              disponibles en este sitio para usted, el usuario, condicionado a
              su aceptación de todos los términos, condiciones, políticas y
              avisos establecidos aquí.
            </p>
          </div>
          <div className="mx-auto mt-6 text-gray-500 prose prose-indigo prose-lg">
            <p>
              Al visitar nuestro sitio y / o comprar algo de nosotros, usted
              participa en nuestro “Servicio” y acepta estar sujeto a los
              siguientes términos y condiciones (“Términos de Servicio”,
              “Términos”), incluidos los términos y condiciones adicionales y
              las políticas a las que se hace referencia en este documento y / o
              están disponibles por hipervínculo. Estos Términos de servicio se
              aplican a todos los usuarios del sitio, incluidos, entre otros,
              los usuarios que son navegadores, proveedores, clientes,
              comerciantes y / o contribuyentes de contenido.
            </p>
            <p>
              Lea atentamente estos Términos de servicio antes de acceder o
              utilizar nuestro sitio web. Al acceder o utilizar cualquier parte
              del sitio, usted acepta estar sujeto a estos Términos de Servicio.
              Si no está de acuerdo con todos los términos y condiciones de este
              acuerdo, no podrá acceder al sitio web ni utilizar ningún
              servicio. Si estos Términos de servicio se consideran una oferta,
              la aceptación se limita expresamente a estos Términos de servicio.
            </p>
            <p>
              Cualquier característica nueva o herramienta que se agregue a la
              tienda actual también estará sujeta a los Términos de Servicio.
              Puede revisar la versión más reciente de los Términos de Servicio
              en cualquier momento en esta página. Nos reservamos el derecho de
              actualizar, cambiar o reemplazar cualquier parte de estos Términos
              de Servicio publicando actualizaciones y / o cambios en nuestro
              sitio web. Es su responsabilidad revisar esta página
              periódicamente para ver los cambios. Su uso continuo o acceso al
              sitio web después de la publicación de cualquier cambio con la
              aceptación de dichos cambios.
            </p>
            <p>
              Nuestra tienda está alojada en Shopify Inc. Nos proporcionan la
              plataforma de comercio electrónico en línea que nos permite
              venderle nuestros productos y servicios.
            </p>
            <h4>SECCIÓN 1 - TÉRMINOS DE LA TIENDA ONLINE</h4>
            <p>
              Al aceptar estos Términos de servicio, usted declara que tiene al
              menos la mayoría de edad en su estado o provincia de residencia, o
              que tiene la mayoría de edad en su estado o provincia de
              residencia y nos ha dado su consentimiento para permitir que
              cualquiera de sus dependientes menores use este sitio.
            </p>
            <p>
              No puede usar nuestros productos para ningún propósito ilegal o no
              autorizado ni puede, al usar el Servicio, violar ninguna ley en su
              jurisdicción (incluidas, entre otras, las leyes de derechos de
              autor). No debe transmitir gusanos, virus o códigos de naturaleza
              destructiva. Una violación o violación de cualquiera de los
              Términos resultará en la terminación inmediata de sus Servicios.
            </p>
            <h4>SECCIÓN 2 - CONDICIONES GENERALES</h4>
            <p>
              Nos reservamos el derecho de rechazar el servicio a cualquier
              persona por cualquier motivo en cualquier momento.
            </p>
            <p>
              Usted comprende que su contenido (sin incluir la información de la
              tarjeta de crédito) puede transferirse sin cifrar e implicar (a)
              transmisiones a través de varias redes; y (b) cambios para cumplir
              y adaptar los requisitos técnicos de la conexión de redes o
              dispositivos. La información de la tarjeta de crédito siempre se
              cifra durante la transferencia a través de redes.{' '}
            </p>
            <p>
              Usted acepta no reproducir, duplicar, copiar, vender, revender o
              explotar ninguna parte del Servicio, el uso del Servicio o el
              acceso al Servicio o cualquier contacto en el sitio web a través
              del cual se presta el servicio, sin un escrito expreso permiso de
              nosotros
            </p>
            <p>
              Los encabezados utilizados en este acuerdo se incluyen solo por
              conveniencia y no limitarán ni afectarán estos Términos.
            </p>
            <h4>
              SECCIÓN 3 - EXACTITUD, COMPLETIDAD Y ACTUALIDAD DE LA INFORMACIÓN
            </h4>
            <p>
              No somos responsables si la información disponible en este sitio
              no es precisa, completa o actual. El material en este sitio se
              proporciona solo para información general y no se debe confiar en
              él ni utilizarlo como la única base para tomar decisiones sin
              consultar fuentes de información primarias, más precisas, más
              completas o más oportunas. Cualquier confianza en el material de
              este sitio es bajo su propio riesgo.
            </p>
            <p>
              Este sitio puede contener cierta información histórica. La
              información histórica, necesariamente, no es actual y se
              proporciona solo para su referencia. Nos reservamos el derecho de
              modificar el contenido de este sitio en cualquier momento, pero no
              tenemos la obligación de actualizar ninguna información en nuestro
              sitio. Usted acepta que es su responsabilidad monitorear los
              cambios en nuestro sitio.
            </p>
            <h4>SECCIÓN 4 - MODIFICACIONES AL SERVICIO Y PRECIOS</h4>
            <p>
              Los precios de nuestros productos están sujetos a cambios sin
              previo aviso. Nos reservamos el derecho en cualquier momento de
              modificar o descontinuar el Servicio (o cualquier parte o
              contenido del mismo) sin previo aviso en cualquier momento.
            </p>
            <p>
              No seremos responsables ante usted ni ante ningún tercero por
              cualquier modificación, cambio de precio, suspensión o
              interrupción del Servicio.
            </p>
            <h4>SECCIÓN 5 - PRODUCTOS O SERVICIOS</h4>
            <p>
              (si corresponde) y estar disponible exclusivamente en línea a
              través del sitio web. Estos productos o servicios pueden tener
              cantidades limitadas y están sujetos a devolución o cambio solo de
              acuerdo con nuestra Política de devolución.
            </p>
            <p>
              Hemos hecho todo lo posible para mostrar con la mayor precisión
              posible los colores e imágenes de nuestros productos que aparecen
              en la tienda. No podemos garantizar que la visualización de
              cualquier color en el monitor de su computadora sea precisa.
            </p>
            <p>
              Nos reservamos el derecho, pero no estamos obligados, de limitar
              las ventas de nuestros productos o servicios a cualquier persona,
              región geográfica o jurisdicción. Podemos ejercer este derecho
              caso por caso. Nos reservamos el derecho de limitar las cantidades
              de cualquier producto o servicio que ofrecemos. Todas las
              descripciones de productos o precios de productos están sujetas a
              cambios en cualquier momento sin previo aviso, a discreción de
              nosotros. Nos reservamos el derecho de descontinuar cualquier
              producto en cualquier momento. Cualquier oferta para cualquier
              producto o servicio realizado en este sitio es nula donde esté
              prohibida.
            </p>
            <p>
              No garantizamos que la calidad de los productos, servicios,
              información u otro material comprado u obtenido por usted cumpla
              con sus expectativas, o que cualquier error en el Servicio será
              corregido.
            </p>
            <h4>
              SECCIÓN 6 - EXACTITUD DE LA INFORMACIÓN DE FACTURACIÓN Y CUENTA
            </h4>
            <p>
              Nos reservamos el derecho de rechazar cualquier pedido que realice
              con nosotros. Podemos, a nuestro exclusivo criterio, limitar o
              cancelar las cantidades compradas por persona, por hogar o por
              pedido. Estas restricciones pueden incluir pedidos realizados por
              o bajo la misma cuenta de cliente, la misma tarjeta de crédito y /
              o pedidos que utilizan la misma dirección de facturación y / o
              envío. En el caso de que hagamos un cambio o cancelemos un pedido,
              podemos intentar notificarlo contactando con el correo electrónico
              y / o la dirección de facturación / número de teléfono
              proporcionado en el momento en que se realizó el pedido. Nos
              reservamos el derecho de limitar o prohibir los pedidos que, a
              nuestro exclusivo criterio, parecen ser realizados por
              distribuidores, revendedores o distribuidores.
            </p>
            <p>
              Usted acepta proporcionar información de compra y cuenta actual,
              completa y precisa para todas las compras realizadas en nuestra
              tienda. Usted acepta actualizar de inmediato su cuenta y otra
              información, incluyendo su dirección de correo electrónico y
              números de tarjeta de crédito y fechas de vencimiento, para que
              podamos completar sus transacciones y contactarlo según sea
              necesario.
            </p>
            <p>
              Para obtener más detalles, consulte nuestra Política de
              devoluciones.
            </p>
            <h4>SECCIÓN 7 - HERRAMIENTAS OPCIONALES</h4>
            <p>
              Podemos proporcionarle acceso a herramientas de terceros sobre las
              cuales no supervisamos ni tenemos control ni aportes.
            </p>
            <p>
              Usted reconoce y acepta que proporcionamos acceso a dichas
              herramientas “tal cual” y “según estén disponibles” sin garantías,
              representaciones o condiciones de ningún tipo y sin ningún tipo de
              respaldo. No tendremos ninguna responsabilidad derivada o
              relacionada con su uso de herramientas de terceros opcionales.
            </p>
            <p>
              Cualquier uso que usted haga de las herramientas opcionales
              ofrecidas a través del sitio es bajo su propio riesgo y
              discreción, y debe asegurarse de que está familiarizado y aprueba
              los términos en que las herramientas son proporcionadas por los
              proveedores externos relevantes.
            </p>
            <p>
              También podemos, en el futuro, ofrecer nuevos servicios y / o
              funciones a través del sitio web (incluido el lanzamiento de
              nuevas herramientas y recursos). Tales nuevas características y /
              o servicios también estarán sujetos a estos Términos de Servicio.
            </p>
            <h4>SECCIÓN 8 - ENLACES DE TERCEROS</h4>
            <p>
              Ciertos contenidos, productos y servicios disponibles a través de
              nuestro Servicio pueden incluir materiales de terceros.
            </p>
            <p>
              Los enlaces de terceros en este sitio pueden dirigirlo a sitios
              web de terceros que no están afiliados a nosotros. No somos
              responsables de examinar o evaluar el contenido o la precisión y
              no garantizamos ni tendremos ninguna responsabilidad por los
              materiales o sitios web de terceros, ni por ningún otro material,
              producto o servicio de terceros. No somos responsables de ningún
              daño o perjuicio relacionado con la compra o uso de bienes,
              servicios, recursos, contenido o cualquier otra transacción
              realizada en relación con sitios web de terceros. Revise
              detenidamente las políticas y prácticas de terceros y asegúrese de
              comprenderlas antes de realizar cualquier transacción. Las quejas,
              reclamos, inquietudes o preguntas relacionadas con productos de
              terceros deben dirigirse al tercero.
            </p>
            <h4>
              SECCIÓN 9 - COMENTARIOS DEL USUARIO, COMENTARIOS Y OTRAS
              COMUNICACIONES
            </h4>
            <p>
              Si, a nuestra solicitud, envía ciertas presentaciones específicas
              (por ejemplo, entradas de concursos) o sin una solicitud de
              nosotros envía ideas creativas, sugerencias, propuestas, planes u
              otros materiales, ya sea en línea, por correo electrónico, por
              correo postal o de otra manera (colectivamente, 'comentarios'),
              usted acepta que podemos, en cualquier momento, sin restricciones,
              editar, copie, publique, distribuya, traduzca y de otra manera use
              en cualquier medio cualquier comentario que nos envíe. No estamos
              y no tendremos ninguna obligación (1) de mantener ningún
              comentario confidencial; (2) pagar una compensación por cualquier
              comentario; o (3) para responder a cualquier comentario.
            </p>
            <p>
              Podemos, pero no tenemos la obligación de monitorear, eabandonar o
              eliminar contenido que, a nuestro exclusivo criterio, determinemos
              que es ilegal, ofensivo, amenazante, difamatorio, difamatorio,
              pornográfico, obsceno u objetable o viola la propiedad intelectual
              de cualquiera de las partes o estos Términos de servicio.
            </p>
            <p>
              Usted acepta que sus comentarios no violarán ningún derecho de
              terceros, incluidos los derechos de autor, marca registrada,
              privacidad, personalidad u otro derecho personal o de propiedad.
              Además, acepta que sus comentarios no contendrán material
              difamatorio o ilegal, abusivo u obsceno, ni contendrán ningún
              virus informático u otro malware que pueda afectar de alguna
              manera el funcionamiento del Servicio o cualquier sitio web
              relacionado. No puede utilizar una dirección de correo electrónico
              falsa, pretender ser otra persona que no sea usted mismo, o
              engañarnos a nosotros o a terceros en cuanto al origen de
              cualquier comentario. Usted es el único responsable de cualquier
              comentario que haga y de su precisión. No asumimos ninguna
              responsabilidad ni asumimos ninguna responsabilidad por los
              comentarios publicados por usted o cualquier tercero.
            </p>
            <h4>SECCIÓN 10 - INFORMACIÓN PERSONAL</h4>
            <p>
              Su envío de información personal a través de la tienda se rige por
              nuestra Política de privacidad. Para ver nuestra Política de
              privacidad.
            </p>
            <h4>SECCIÓN 11 - ERRORES, IMPRECISIONES Y OMISIONES</h4>
            <p>
              Ocasionalmente puede haber información en nuestro sitio o en el
              Servicio que contiene errores tipográficos, imprecisiones u
              omisiones que pueden relacionarse a descripciones de productos,
              precios, promociones, ofertas, gastos de envío de productos,
              tiempos de tránsito y disponibilidad. Nos reservamos el derecho de
              corregir cualquier error, inexactitud u omisión, y de cambiar o
              actualizar información o cancelar pedidos si alguna información en
              el Servicio o en cualquier sitio web relacionado es inexacta en
              cualquier momento sin previo aviso (incluso después de haber
              enviado su pedido).
            </p>
            <p>
              No asumimos ninguna obligación de actualizar, modificar o aclarar
              información en el Servicio o en cualquier sitio web relacionado,
              incluida, entre otras, información de precios, excepto según lo
              exija la ley. No se debe tomar ninguna fecha específica de
              actualización o actualización en el Servicio o en cualquier sitio
              web relacionado, para indicar que toda la información en el
              Servicio o en cualquier sitio web relacionado se ha modificado o
              actualizado.
            </p>
            <h4>SECCIÓN 12 - USOS PROHIBIDOS</h4>
            <p>
              Además de otras prohibiciones establecidas en los Términos de
              Servicio, tiene prohibido usar el sitio o su contenido: ( a) para
              cualquier propósito ilegal; (b) para solicitar a otros que
              realicen o participen en cualquier acto ilegal; (c) violar
              cualquier regulación, norma, ley u ordenanza local internacional,
              federal, provincial o estatal; (d) infringir o violar nuestros
              derechos de propiedad intelectual o los derechos de propiedad
              intelectual de terceros; (e) acosar, abusar, insultar, dañar,
              difamar, calumniar, menospreciar, intimidar o discriminar por
              motivos de género, orientación sexual, religión, origen étnico,
              raza, edad, origen nacional o discapacidad; (f) presentar
              información falsa o engañosa; (g) cargar o transmitir virus o
              cualquier otro tipo de código malicioso que se pueda o pueda usar
              de alguna manera que afecte la funcionalidad u operación del
              Servicio o de cualquier sitio web relacionado, otros sitios web o
              Internet; (h) para recopilar o rastrear la información personal de
              otros; (i) para spam, phishing, pharm, pretexto, araña, rastreo o
              raspado; (j) para cualquier propósito obsceno o inmoral; o (k)
              para interferir o eludir las características de seguridad del
              Servicio o cualquier sitio web relacionado, otros sitios web o
              Internet. Nos reservamos el derecho de terminar su uso del
              Servicio o cualquier sitio web relacionado por violar cualquiera
              de los usos prohibidos.
            </p>
            <h4>
              SECCIÓN 13 - DESCARGO DE RESPONSABILIDAD DE GARANTÍAS; LIMITACIÓN
              DE RESPONSABILIDAD
            </h4>
            <p>
              No garantizamos, representamos ni garantizamos que su uso de
              nuestro servicio será ininterrumpido, oportuno, seguro o libre de
              errores.
            </p>
            <p>
              No garantizamos que los resultados que se puedan obtener del uso
              del servicio sean precisos o confiables.
            </p>
            <p>
              Usted acepta que de vez en cuando podemos eliminar el servicio por
              períodos indefinidos o cancelar el servicio en cualquier momento,
              sin notificárselo.
            </p>
            <p>
              Usted acepta expresamente que el uso o la imposibilidad de usar el
              servicio es bajo su propio riesgo. El servicio y todos los
              productos y servicios entregados a usted a través del servicio se
              proporcionan (excepto según lo expresamente expresado por
              nosotros) “tal cual” y “según esté disponible” para su uso, sin
              ninguna representación, garantía o condición de ningún tipo, ya
              sea expresa o implícita. , incluidas todas las garantías o
              condiciones implícitas de comerciabilidad, calidad
              comercializable, idoneidad para un propósito particular,
              durabilidad, título y no infracción.
            </p>
            <p>
              En ningún caso Gamerecar, nuestros directores,
              funcionarios, empleados, afiliados, agentes, contratistas,
              pasantes, proveedores, proveedores de servicios o licenciantes
              serán responsables de cualquier lesión, pérdida, reclamación, o
              directa, indirecta, daños incidentales, punitivos, especiales o
              consecuentes de cualquier tipo, incluidos, entre otros, pérdida de
              ganancias, pérdida de ingresos, pérdida de ahorros, pérdida de
              datos, reemplazo n costos, muchos daños similares, ya sea por
              contrato, agravio (incluida negligencia), responsabilidad estricta
              o de otro tipo, derivados de su uso de cualquiera de los servicios
              o productos adquiridos utilizando el servicio, o por cualquier
              otro reclamo relacionado de alguna manera con su uso del servicio
              o cualquier producto, incluidos, entre otros, cualquier error u
              omisión en cualquier contenido, o cualquier pérdida o daño de
              cualquier tipo incurrido como resultado del uso del servicio o
              cualquier contenido (o producto) publicado, transmitido , o de
              otro modo disponible a través del servicio, incluso si se le
              informa de su posibilidad. Debido a que algunos estados o
              jurisdicciones no permiten la exclusión o la limitación de
              responsabilidad por daños consecuentes o incidentales, en dichos
              estados o jurisdicciones, nuestra responsabilidad se limitará en
              la medida máxima permitida por la ley.
            </p>
            <h4>SECCIÓN 14 - INDEMNIZACIÓN</h4>
            <p>
              Usted acepta indemnizar, defender y mantener indemne 
              Gamerecar y nuestra matriz, subsidiarias, afiliadas, socios,
              funcionarios, directores , agentes, contratistas, licenciantes,
              proveedores de servicios, subcontratistas, proveedores, pasantes y
              empleados, inofensivos ante cualquier reclamo o demanda, incluidos
              los honorarios razonables de abogados, realizados por un tercero
              debido a su incumplimiento de estos Términos de servicio o los
              documentos que incorporan por referencia, o su violación de
              cualquier ley o los derechos de un tercero.
            </p>
            <h4>SECCIÓN 15 - SEPARABILIDAD</h4>
            <p>
              En el caso de que se determine que alguna disposición de estos
              Términos de servicio es ilegal, nula o inaplicable, dicha
              disposición será, sin embargo, exigible en la mayor medida
              permitida por la ley aplicable, y la parte no exigible se
              considerará separada de estos Términos de Servicio, dicha
              determinación no afectará la validez y aplicabilidad de cualquier
              otra disposición restante.
            </p>
            <h4>SECCIÓN 16 - TERMINACIÓN</h4>
            <p>
              Las obligaciones y responsabilidades de las partes contraídas
              antes de la fecha de terminación sobrevivirán a la terminación de
              este acuerdo para todos los propósitos.
            </p>
            <p>
              Estos Términos de servicio son efectivos a menos que usted o
              nosotros los rescindamos. Puede rescindir estos Términos de
              servicio en cualquier momento notificándonos que ya no desea usar
              nuestros Servicios o cuando deja de usar nuestro sitio.
            </p>
            <p>
              Si, a nuestro exclusivo criterio, usted no cumple o sospechamos
              que ha incumplido cualquier término o disposición de estos
              Términos de Servicio, también podemos rescindir este acuerdo en
              cualquier momento sin previo aviso y usted seguirá siendo
              responsable de todo importes adeudados hasta la fecha de
              terminación inclusive; y / o en consecuencia puede negarle el
              acceso a nuestros Servicios (o cualquier parte de los mismos).
            </p>
            <h4>SECCIÓN 17 - ACUERDO COMPLETO</h4>
            <p>
              El incumplimiento por nuestra parte de ejercer o hacer cumplir
              cualquier derecho o disposición de estos Términos de Servicio no
              constituirá una renuncia a tal derecho o provisión.
            </p>
            <p>
              Estos Términos de servicio y cualquier política o regla operativa
              publicada por nosotros en este sitio o con respecto al Servicio
              constituye el acuerdo completo y el entendimiento entre usted y
              nosotros y rigen su uso del Servicio, reemplazando cualquier
              acuerdo anterior o contemporáneo, comunicaciones y propuestas, ya
              sean orales o escritas, entre usted y nosotros (incluidas, entre
              otras, las versiones anteriores de los Términos de servicio).
            </p>
            <p>
              Cualquier ambigüedad en la interpretación de estos Términos de
              Servicio no se interpretará en contra de la parte redactora.
            </p>
            <h4>SECCIÓN 18 - LEY APLICABLE</h4>
            <p>
              Estos Términos de Servicio y cualquier otro acuerdo por el cual le
              brindemos Servicios se regirán e interpretarán de acuerdo con las
              leyes de Ecuador.
            </p>
            <h4>SECCIÓN 19 - CAMBIOS A LOS TÉRMINOS DE SERVICIO</h4>
            <p>
              Puede revisar la versión más reciente de los Términos de Servicio
              en cualquier momento en esta página.
            </p>
            <p>
              Nos reservamos el derecho, a nuestra sola discreción, de
              actualizar, cambiar o reemplazar cualquier parte de estos Términos
              de Servicio mediante la publicación de actualizaciones y cambios
              en nuestro sitio web. Es su responsabilidad consultar
              periódicamente nuestro sitio web para ver los cambios. Su uso
              continuo o acceso a nuestro sitio web o al Servicio después de la
              publicación de cualquier cambio en estos Términos de servicio
              constituye la aceptación de esos cambios.
            </p>
            <h4>SECCIÓN 20 - INFORMACIÓN DE CONTACTO</h4>
            <p>
              Las preguntas sobre los Términos de servicio deben enviarse a
              gerencia@gamerecar.com.
            </p>
          </div>
        </div>
      </div>
      <FooterLogout navigation={navigation} />
    </div>
  );
};
