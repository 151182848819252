import React from 'react';
import { Modal } from 'antd';

interface IModalProps {
    title: string;
    visible: boolean;
    child: React.ReactChild;
    footer?: boolean;
    onOk?: (e: React.MouseEvent<HTMLElement>) => void;
    onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
    afterClose?: () => void;
}

export const ModalPayValida: React.FC<IModalProps> = ({
    title,
    visible,
    onOk,
    onCancel,
    footer,
    afterClose,
    child,
}) =>
    footer ? (
        <Modal
            title={title}
            visible={visible}
            onOk={onOk}
            onCancel={onCancel}
            okText={'Si'}
            cancelText={'No'}
            destroyOnClose={true}
            afterClose={afterClose}
            centered={true}
            width={1000}
        >
            {child}
        </Modal>
    ) : (
        <Modal
            title={title}
            visible={visible}
            onOk={onOk}
            onCancel={onCancel}
            okText={'Si'}
            cancelText={'No'}
            destroyOnClose={true}
            afterClose={afterClose}
            footer={null}
            centered={true}
            width={1000}
        //style={{ minWidth: '60%',width: 'fit-content',maxWidth: '80%' }}
        >
            {child}
        </Modal>
    );
