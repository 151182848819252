import React, { useEffect, useState } from 'react'
import { Labels } from '../../../../enums/labels.enum';
import { useForm } from 'react-hook-form';
import { FormErrorMessages } from '../../../../enums/form-error-messages.enum';
import { VALID_SIMPLE_NUMBER_REGEX } from '../../../../constants';
import { FormError } from '../../../../components/form-error';
import { FormLengthInputs } from '../../../../enums/form-length-inputs.enum';
import { Button } from '../../../../components/button';
import { Exceptions } from '../../../../enums/exceptions.enum';
import { parseFieldsToObject } from '../../../../utils/fields-utils';
import { getCurrentUnixTimestamp } from '../../../../utils/date-time-unix-utils';
import { consultTellerIP } from '../../../../third-api/ip-teller.service';
import { responsePayValidaConsult } from '../../../../__dto__/payvalida/responsePayValidaConsult.interface';
import { roundWithTwoDecimals } from '../../../../utils/numeric-utils';
import { Modal as AModal } from 'antd';
import { ResponsePayValida } from '../../../../rest-api/enum-response/response.enum';
import { v4 as uuidv4 } from 'uuid';
import { ApolloError, gql, useMutation } from '@apollo/client';
import { sha512 } from '../../../../utils/sha-512-crypto-utils';
import { checkLength, removeSpecialCharacters } from '../../../../utils/validate-utils';
import { createPurchaseOrderMutation, createPurchaseOrderMutationVariables } from '../../../../__api__/createPurchaseOrderMutation';
import { useMe } from '../../../../hooks/use-me';
import { FacType } from '../../../../__api__/globalTypes';

const CONSULT_PAYMENT_PAYVALIDA = gql`
  mutation consultCollectionPayValida($input: ConsultCollectionPayValidaInput!) {
    consultCollectionPayValida(input: $input) {
        code
    text
    data {     
      description   
      commerce
      payment_options {
        payment_type
        payment_reference
        payment_amount
      }
      timestamp
      checksum
    }
    client_percent
    owner_percent
    max_amount
    min_amount
    }
  }
`;


const REGISTER_TRANSACTION_PAYVALIDA = gql`mutation registerTransactionPayValida($input: RegisterTransactionPayValidaInput!) {
    registerTransactionPayValida(input: $input) {
      code
      text
      data {     
        description
        transaction_cost
        amount
        payment_reference
        transaction
        pv_order
        commerce
        timestamp
        authorization_code
        checksum
      }
    }
  }
  `;


const CANCEL_TRANSACTION_PAYVALIDA = gql`mutation cancelTransactionPayValida($input: CancelTransactionPayValidaInput!) {
    cancelTransactionPayValida(input: $input) {
      code
      text
      data {             
        transaction
        timestamp
        authorization_code
        checksum
      }
    }
  }
  `;

const CREATE_PURCHASE_ORDER = gql`
  mutation createPurchaseOrder($input: CreatePurchaseOrderInput!) {
    createPurchaseOrder(input: $input)
  }
`;




const BuyProductMttrPayvalida = ({ product, onOk, setLoadingPurcharse }) => {
    const initialResponsePayValidaConsult: responsePayValidaConsult = {
        code: '',
        text: '',
        data: {
            description: '',
            commerce: '',
            payment_options: [],
            timestamp: 0,
            checksum: ''
        },
        client_percent: 0,
        owner_percent: 0,
        max_amount: 0,
        min_amount: 0
    };
    const { data } = useMe();
    const [teller, setTeller] = useState('');
    const [transaction, setTransaction] = useState('');
    const [consultCollectionPayValida, { }] = useMutation(CONSULT_PAYMENT_PAYVALIDA);
    const [registerTransactionPayValida, { loading: isLoadingTransaction }] = useMutation(REGISTER_TRANSACTION_PAYVALIDA);
    const [cancelTransactionPayValida, { error: haveErrorCancelTransaction }] = useMutation(CANCEL_TRANSACTION_PAYVALIDA);
    const [createPurchaseOrder, { loading: isLoadingPurchase, error: errorPurchase }] = useMutation<
        createPurchaseOrderMutation,
        createPurchaseOrderMutationVariables
    >(CREATE_PURCHASE_ORDER, {
        onCompleted: (data) => {
            console.log('Order created successfully:', data);
            // Puedes realizar otras acciones aquí, como redireccionar o actualizar el estado
        },
    });

    const [responsePayValidaConsult, setResponsePayValidaConsult] = useState(initialResponsePayValidaConsult);
    const [errorCON, seterrorCON] = useState<string | null>(null);
    const [showCustomer, setShowCustomer] = useState<boolean>(false);
    const [loadingButton, setLoadingButton] = useState<boolean>(false);

    const {
        register,
        getValues,
        errors,
        handleSubmit,
        formState: { isValid },
    } = useForm({
        mode: 'onChange',
    });

    const handleEndTransaction = () => {
        onOk();
    }
    const handlePayClick = async (payment_type: number, payment_reference, amount: number) => {
        setLoadingPurcharse(true);
        if (data) {
            if (data.me) {
                if (data.me.customer?.balance) {
                    if (data.me.customer.balance < roundWithTwoDecimals(amount - (amount * responsePayValidaConsult.client_percent / 100))) {
                        errorAlert(Labels.PAVVALID_INSUFFICIENT_BALANCE)
                    } else {
                        if (payment_type === 1 && (amount < responsePayValidaConsult.min_amount || amount > responsePayValidaConsult.max_amount)) {
                            errorAlert(`El valor del pago  no es válido no puede ser menor que ${responsePayValidaConsult.min_amount} o mayor que ${responsePayValidaConsult.max_amount}`);
                        } else {
                            const { ...input } = getValues();
                            const contentForm = {
                                fields: Object.keys(input).map((key: string) => ({
                                    name: key,
                                    value: input[key],
                                })),
                            };
                            const parseContentForm = parseFieldsToObject(contentForm);

                            const netName = process.env.REACT_APP_NET_NAME_CASHIN == undefined ? "" : process.env.REACT_APP_NET_NAME_CASHIN;
                            const request_type = 1;
                            const fixedHash = process.env.REACT_APP_FIXED_HASH_CASHIN == undefined ? "" : process.env.REACT_APP_FIXED_HASH_CASHIN;
                            const keyValue = process.env.REACT_APP_KEY_VALUE_CASHIN == undefined ? "" : process.env.REACT_APP_KEY_VALUE_CASHIN;
                            const checksum = sha512(netName + payment_reference + transaction + amount + fixedHash);
                            const money = process.env.REACT_APP_MONEY_CODE == undefined ? "" : process.env.REACT_APP_MONEY_CODE;
                            const geographic_code = process.env.REACT_APP_GEOGRAPHIC_CODE == undefined ? "" : process.env.REACT_APP_GEOGRAPHIC_CODE;
                            const payload = {
                                reference: parseContentForm.reference_number,
                                payment_reference: payment_reference,
                                transaction: transaction,
                                amount: amount,
                                teller: teller, timestamp: getCurrentUnixTimestamp(),
                                terminal_code: "", geographic_code: geographic_code,
                                request_type: request_type,
                                money: money, netname: netName, checksum: checksum, keyvalue: keyValue

                            }
                            const resultTransaction = await registerTransactionPayValida({ variables: { input: payload } });
                            if (resultTransaction) {
                                console.log(resultTransaction.data)
                                const respuestaCashin = resultTransaction.data.registerTransactionPayValida;
                                if (respuestaCashin.text === ResponsePayValida.OK) {
                                    let facIdType: FacType = FacType.CONSUMIDORFINAL;
                                    switch (Number(parseContentForm.tipocliente)) {
                                        case 1:
                                            facIdType = FacType.CONSUMIDORFINAL;
                                            break;
                                        case 2:
                                            facIdType = FacType.CEDULA;
                                            break;
                                        case 3:
                                            facIdType = FacType.RUC;
                                            break;
                                        case 4:
                                            facIdType = FacType.PASAPORTE;
                                            break;
                                        default:
                                            alert(Number(getValues().tipocliente));
                                            seterrorCON("Tipo de Consumidor no reconocido");
                                            return;
                                    }
                                    try {
                                        const resultPurchaseOrder = await createPurchaseOrder({
                                            variables: {
                                                input: {
                                                    userId: data?.me.id || '',
                                                    productId: product?.id || '',
                                                    subid: product?.subid,
                                                    total: roundWithTwoDecimals(amount - (amount * responsePayValidaConsult.client_percent / 100)) || 0,
                                                    salePrice: amount,
                                                    productionCost: roundWithTwoDecimals(amount - (amount * responsePayValidaConsult.owner_percent / 100)) || 0,
                                                    orderDetail: {
                                                        fields: [
                                                            { name: "numero_referencia", value: payment_reference },
                                                            { name: "proveedor", value: "Pay valida cashin" }
                                                        ]
                                                    },
                                                    facData: {
                                                        factNames: facIdType == FacType.CONSUMIDORFINAL ? "" : parseContentForm.name,
                                                        facLastnames: facIdType == FacType.CONSUMIDORFINAL ? "" : parseContentForm.lastName,
                                                        facAddress: facIdType == FacType.CONSUMIDORFINAL ? "" : parseContentForm.direccion,
                                                        facID: facIdType == FacType.CONSUMIDORFINAL ? "" : parseContentForm.identificationNumber,
                                                        facIDType: facIdType
                                                    }
                                                },
                                            },
                                        });
                                        if (resultPurchaseOrder.data) {
                                            if (resultPurchaseOrder.data.createPurchaseOrder) {
                                                success(Labels.PAVVALID_SUCCESS_MESSAGE_LABEL);
                                                setLoadingPurcharse(false);
                                                handleEndTransaction();
                                            }
                                        } else {
                                            handleCancelTransaction();
                                            setLoadingPurcharse(false);
                                        }
                                    } catch (err) {
                                        const apolloError = err as ApolloError;
                                        const graphQLErrors = apolloError.graphQLErrors;

                                        if (graphQLErrors.length > 0) {
                                            const message = graphQLErrors[0]?.message;

                                            if (message === "InsufficientBalance") {
                                                handleCancelTransaction();
                                                // Mostrar mensaje en la UI
                                            } else {
                                                console.error("Error:", message);
                                            }
                                        }
                                    }


                                } else {
                                    setLoadingPurcharse(false);
                                    errorAlert(respuestaCashin.text);
                                    handleEndTransaction();
                                }
                            }

                            if (errorPurchase) {
                                handleCancelTransaction();

                            }
                        }


                    }
                }
            }
        }



    }

    const handleChangeAmountPayment = (index: number, newAmount: any) => {
        if (newAmount) {
            const newPaymentOptions = responsePayValidaConsult.data.payment_options.map((option, i) => (
                i === index ? {
                    ...option, payment_amount: parseFloat(newAmount),
                } : option
            ));

            setResponsePayValidaConsult({
                ...responsePayValidaConsult, data: {
                    ...responsePayValidaConsult.data,
                    payment_options: newPaymentOptions
                }
            });
            console.log("new payment", {
                ...responsePayValidaConsult, data: {
                    ...responsePayValidaConsult.data,
                    payment_options: newPaymentOptions
                }
            })
        } else {
            const newPaymentOptions = responsePayValidaConsult.data.payment_options.map((option, i) => (
                i === index ? {
                    ...option, payment_amount: null,
                } : option
            ));

            setResponsePayValidaConsult({
                ...responsePayValidaConsult, data: {
                    ...responsePayValidaConsult.data,
                    payment_options: newPaymentOptions
                }
            });
        }
    };

    const handleCancelTransaction = async () => {

        const netName = process.env.REACT_APP_NET_NAME_CASHIN == undefined ? "" : process.env.REACT_APP_NET_NAME_CASHIN;
        const fixedHash = process.env.REACT_APP_FIXED_HASH_CASHIN == undefined ? "" : process.env.REACT_APP_FIXED_HASH_CASHIN;
        const checksum = sha512(netName + transaction + fixedHash);
        const timestamp = getCurrentUnixTimestamp();
        const payload = {
            netname: netName,
            checksum: checksum,
            timestamp: timestamp,
            transaction: transaction,
        }
        const resultCancelTransaction = await cancelTransactionPayValida({ variables: { input: payload } });
        if (resultCancelTransaction) {
            console.log(resultCancelTransaction.data)
            const respuestaCancelTransaction = resultCancelTransaction.data.cancelTransactionPayValida;
            if (respuestaCancelTransaction.text === ResponsePayValida.OK) {
                setLoadingPurcharse(false);
                success(Labels.PAVVALID_CANCEL_MESSAGE_LABEL);
                handleEndTransaction();
            } else {
                setLoadingPurcharse(false);
                errorAlert(respuestaCancelTransaction.text);
                handleEndTransaction();
            }
        }
        if (haveErrorCancelTransaction) {
            setLoadingPurcharse(false);
            errorAlert(haveErrorCancelTransaction.message);
            handleEndTransaction();
        }

    }


    const onSearchPayment = async () => {
        setLoadingButton(true);
        const { ...input } = getValues();
        const contentForm = {
            fields: Object.keys(input).map((key: string) => ({
                name: key,
                value: input[key],
            })),
        };
        const parseContentForm = parseFieldsToObject(contentForm);
        const netName = process.env.REACT_APP_NET_NAME_CASHIN == undefined ? "" : process.env.REACT_APP_NET_NAME_CASHIN;
        const fixedHash = process.env.REACT_APP_FIXED_HASH_CASHIN == undefined ? "" : process.env.REACT_APP_FIXED_HASH_CASHIN;
        const keyValue = process.env.REACT_APP_KEY_VALUE_CASHIN == undefined ? "" : process.env.REACT_APP_KEY_VALUE_CASHIN;
        const checksum = sha512(netName + parseContentForm.reference_number + fixedHash)
        const geographic_code = process.env.REACT_APP_GEOGRAPHIC_CODE == undefined ? "" : process.env.REACT_APP_GEOGRAPHIC_CODE;
        const payload = {
            reference: parseContentForm.reference_number,
            request_type: 1,
            teller: teller, timestamp: getCurrentUnixTimestamp(),
            terminal_code: "", geographic_code: geographic_code,
            netname: netName, checksum: checksum, keyvalue: keyValue,
            product_id: product.id
        }

        const responseGql = await consultCollectionPayValida({ variables: { input: payload } });
        //const respuestaCashin: responsePayValidaConsult = data.consultCollectionPayValida;
        if (responseGql.data) {
            const respuestaCashin: responsePayValidaConsult = responseGql.data.consultCollectionPayValida;
            if (respuestaCashin.text === ResponsePayValida.OK) {
                setResponsePayValidaConsult(respuestaCashin);
                setLoadingButton(false);
            } else {
                setResponsePayValidaConsult(respuestaCashin);
                setLoadingButton(false);
                errorAlert(respuestaCashin.text);
            }
        }
    };

    const success = (content: string) => {
        AModal.success({
            content
        });
    };

    const errorAlert = (content: string) => {
        AModal.error({
            content
        });
    };


    const customerChange = e => {
        if (!e) {
            return;
        }
        const val = Number(getValues().tipocliente);
        setShowCustomer(val != 1);
    }

    useEffect(() => {

        console.log("producto", product)
        const fetchIp = async () => {
            try {
                const ip = await consultTellerIP();
                setTeller(ip);
                setTransaction(uuidv4());
            } catch (error) {
                console.error('Error al obtener la IP:', error);
            }
        };

        fetchIp();
    }, []);

    return (
        <div className="flex flex-col justify-center px-2 py-0 sm:px-6 lg:px-8">

            <div className="px-1 py-0 bg-white">
                <div>
                    <label htmlFor="tipocliente" className="label">
                        {Labels.CLI_SELECT_SERVICIO}
                    </label>
                    <div className="mt-1">
                        <select
                            name="tipocliente"
                            ref={register({ required: true })}
                            className="input"
                            onChange={customerChange}
                            defaultValue={1}
                        >
                            <option value="1">Consumidor Final</option>
                            <option value="2">Cedula</option>
                            <option value="3">RUC</option>
                            <option value="4">Pasaporte</option>
                        </select>
                    </div>
                </div>
                {showCustomer && (
                    <div>
                        <label htmlFor="name" className="label">
                            {Labels.NAME_FAC_SERVICIO}
                        </label>
                        <div className="mt-1">
                            <input
                                ref={register({ maxLength: 100 })}
                                name="name"
                                maxLength={100}
                                type="text"
                                className="input"
                            />
                        </div>
                    </div>)}

                {showCustomer && (
                    <div>
                        <label htmlFor="lastName" className="label">
                            {Labels.APELLIDO_FAC_SERVICIO}
                        </label>
                        <div className="mt-1">
                            <input
                                ref={register({ maxLength: 100 })}
                                name="lastName"
                                maxLength={100}
                                type="text"
                                className="input"
                            />
                        </div>
                    </div>)}

                {showCustomer && (
                    <div>
                        <label htmlFor="direccion" className="label">
                            {Labels.DIR_FAC_SERVICIO}
                        </label>
                        <div className="mt-1">
                            <input
                                ref={register({ maxLength: 150 })}
                                name="direccion"
                                maxLength={150}
                                type="text"
                                className="input"
                            />
                        </div>
                    </div>)}

                {showCustomer && (
                    <div>
                        <label htmlFor="identificationNumber" className="label">
                            {Labels.CI_FAC_SERVICIO}
                        </label>
                        <div className="mt-1">
                            <input
                                ref={register({ maxLength: 20, minLength: 10, pattern: /^[0-9]*$/ })}
                                name="identificationNumber"
                                type="tel"
                                pattern="[0-9]*"
                                minLength={10}
                                maxLength={20}
                                className="input"
                                onKeyDown={(e) => checkLength(e)}
                                onKeyUp={removeSpecialCharacters}
                            />
                        </div>
                    </div>)}
                <form onSubmit={handleSubmit(onSearchPayment)} className="space-y-6">
                    <div className="flex items-end space-x-4"> {/* Flex container para inputs y botón */}
                        <div className="w-1/3"> {/* Primer input */}
                            <label htmlFor="reference_number" className="label">
                                {Labels.PAYVALID_REFERENCE_NUMBER}
                            </label>
                            <div className="mt-1">
                                <input
                                    ref={register({
                                        required: FormErrorMessages.REQUIRED_PAYVALID_REFERENCE_NUMBER,
                                        minLength: FormLengthInputs.MIN_LENGTH_PAYVALID_REFERENCE_NUMBER,
                                        maxLength: FormLengthInputs.MAX_LENGTH_PAYVALID_REFERENCE_NUMBER,
                                        validate: {
                                            validNumber: (value) => VALID_SIMPLE_NUMBER_REGEX.test(value),
                                        },
                                    })}
                                    name="reference_number"
                                    minLength={FormLengthInputs.MIN_LENGTH_PAYVALID_REFERENCE_NUMBER}
                                    maxLength={FormLengthInputs.MAX_LENGTH_PAYVALID_REFERENCE_NUMBER}
                                    type="text"
                                    className="input"
                                />
                                {errors.reference_number?.message && (
                                    <FormError errorMessage={errors.reference_number?.message} />
                                )}
                                {(errors.reference_number?.type === 'minLength' ||
                                    errors.reference_number?.type === 'maxLength') && (
                                        <FormError errorMessage={FormErrorMessages.LENGTH_PAYVALID_REFERENCE_NUMBER} />
                                    )}
                            </div>
                        </div>

                        <div className="w-1/6 flex items-center"> {/* Botón */}
                            <Button
                                canClick={isValid}
                                loading={loadingButton}
                                actionText={Labels.PAVVALID_SEARCH_LABEL}
                            />
                        </div>
                    </div>

                    {errors && (
                        <FormError errorMessage={Exceptions[errors.message as any]} />
                    )}
                </form>
            </div>

            {
                responsePayValidaConsult.data.commerce !== "" && responsePayValidaConsult.data.payment_options && <div className="px-2 py-1 border-2 border-zinc-800">
                    {responsePayValidaConsult && <div className="flex items-center px-6 py-2">
                        <div className="w-2 h-2 bg-black rounded-full mr-3"></div>
                        <div>
                            <h2 className="text-lg font-bold text-gray-900">
                                {responsePayValidaConsult.data && responsePayValidaConsult.data?.commerce}
                            </h2>
                        </div>
                    </div>}
                    <p className="px-2 underline text-base text-gray-900 mt-1">
                        {responsePayValidaConsult.data && responsePayValidaConsult.data?.description}
                    </p>

                    {
                        responsePayValidaConsult.data.payment_options.length > 0 &&
                        responsePayValidaConsult.data.payment_options.map((item, index) =>
                            <div className="px-2 py-0 w-full" key={`item-detailpayment-${index}`}>
                                <div className="flex justify-between text-sm text-gray-600">
                                    <div className="flex-1">
                                        <p className="font-medium">{Labels.PAYVALID_REFERENCE_NUMBER}</p>
                                        <p>{item.payment_reference}</p>
                                    </div>

                                    <div className="flex-1 text-right">
                                        <div className="flex items-center space-x-2 justify-end">
                                            {item.payment_type === 2 && <span className="lex-shrink-0 inline-block px-2 text-base font-medium text-red-500 line-through bg-orange-200 rounded-full py-0.5">
                                                $ {roundWithTwoDecimals(item.payment_amount ? item.payment_amount : 0)}
                                            </span>}

                                            {item.payment_type === 1 && <input type="number" className="input" value={item.payment_amount ? item.payment_amount : ''} key={`input-payvalida-cashout-${index}`} onChange={(e) => handleChangeAmountPayment(index, e.target.value)} />}

                                            {item.payment_amount ? <span className="flex-shrink-0 inline-block px-2 text-base font-medium text-blue-900 bg-blue-200 rounded-full py-0.5">
                                                $ {roundWithTwoDecimals(item.payment_amount - ((item.payment_amount) * responsePayValidaConsult.client_percent / 100))}
                                            </span> :
                                                <span className="flex-shrink-0 inline-block px-2 text-base font-medium text-blue-900 bg-blue-200 rounded-full py-0.5">
                                                    $ 0.00
                                                </span>}
                                        </div>

                                    </div>
                                </div>
                                <div className="flex justify-end">
                                    <div className="mt-2 border-2 border-red-500 rounded px-2 py-1 inline-block">
                                        <span className="font-bold text-gray-700">Precio de Venta:</span>
                                        <span className="text-gray-900 font-bold">$ {roundWithTwoDecimals(item.payment_amount ? item.payment_amount : 0)}</span>
                                    </div>
                                </div>
                                <div className="flex justify-end py-4">
                                    {(item.payment_amount && !isLoadingTransaction && !isLoadingPurchase) && <button onClick={() => handlePayClick(item.payment_type, item.payment_reference, item.payment_amount ? item.payment_amount : 0)} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                                        {Labels.PAVVALID_PAY_LABEL}
                                    </button>}
                                    {(isLoadingTransaction || isLoadingPurchase) && <div className="flex items-center justify-center bg-gray-300 animate-pulse rounded px-2 py-2">
                                        <span className='font-semibold'>{Labels.PAVVALIDCASHOUT_LOADINGPAYMENT}</span>
                                        <div className="w-6 h-6 border-4 border-blue-500 border-t-transparent rounded-full animate-pulse"></div>
                                    </div>

                                    }
                                </div>
                            </div>)
                    }
                    {errorCON && (
                        <FormError errorMessage={errorCON} />
                    )}

                </div>

            }



        </div>

    )
}

export default BuyProductMttrPayvalida;