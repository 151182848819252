export interface Field {
  name: string;
  value: string;
}
//function parsetoShow 
//args: JSON or string
//return: string
export const parseToShow = (value: any) => {
    if (value === null || value === undefined) {
      return '';
    }
    if (typeof value === 'string') {
      if(!Number.isNaN(Number(value))){
        if(Number(value) % 1 !== 0){
          return Number(value).toFixed(2);
        }
      }
      return value;
    }
    if(typeof value === 'number'){
      //if number has more than 2 decimals, round it
      if(value % 1 !== 0){
        return value.toFixed(2);
      }
    }
    if (Array.isArray(value)) {
      // If the array's elements are objects, iterate over them and create a string representation
      if (typeof value[0] === 'object') {
        return value.map((val, index) => `Item ${index + 1}:\n${parseToShow(val)}`).join('\n');
      }
      // If the array's elements are not objects, join them with commas
      return value.join(', ');
    }
    if (typeof value === 'object') {
      // Create a string representation of the object
      let result = '';
      for (const [key, val] of Object.entries(value)) {
        result += `${key}: ${parseToShow(val)}\n`;
      }
      return result;
    }
    return value;
  };

//list of keys to filter from a Details
export const keysToFilterDetail = ['formKey','formData',"name_factura","lastname_factura","direccion_factura","idnumber_factura","id_consulta","codigo_TRX"];

//list of keys to filter from a Result
export const keysToFilterResult = ['recibo'];
export const keysToFilterResultMTTR = ['transaction_id','pin_code','denomination_id','order_id'];

export const keysToFilterModalDetail = ['id_consulta','id_operator','codigo_paquete','service_id','autorizacion_proveedor','formKey','formData',"name_factura","lastname_factura","direccion_factura","idnumber_factura",'codigo_TRX','fecha_TRX'];
export const keysToFilterModalResult = ['respuesta_proveedor','proveedorTRX','codigoTRX','recibo'];
export const keysToFilterModalProduct = ["description","categoryName"];

//function to filter unwanted elements from an array
//args: keys: unwanted values, object: array to filter
//return: object without unwanted keys
export const filterObject = (keys: string[], object: any) => {
  if(!keys || !object) return [];
  return object.filter((item: any) => !keys.includes(item.name));
};

//function that transforms formKey from base64 to an any object, then iterates over formData to filter unwanted keys and returns an array of objects of filtered data
//args: formData: array of objects, formKey: base64 string
//return: array of objects
export const parseFormData = (formData: any, formKey: string) => {
  if(!formKey || !formData) return [];
  const parsedFormKey = JSON.parse(atob(formKey));
  //iterate over parsedFormKey to filter unwanted keys
  const returnValues : any[] = [];
  for(let i = 0; i < parsedFormKey.length; i++){
    returnValues.push({name: parsedFormKey[i].name, value: formData[parsedFormKey[i].id]});
  }
  return returnValues;
}

//function to extract a value from an array of details or results
//args: array: array of objects, key: key to search for
//return: value of the array element with the key
export const getValueFromArray = (array: any, key: string) => {
  for(let i = 0; i < array.length; i++){
    if(array[i].name === key){
      return array[i].value;
    }
  }
  return undefined;
}
