import React, { useState } from 'react';
import { Labels } from '../../enums/labels.enum';
import { FormErrorMessages } from '../../enums/form-error-messages.enum';
import { FormError } from '../../components/form-error';
import { Button } from '../../components/button';
import { Exceptions } from '../../enums/exceptions.enum';
import { useForm } from 'react-hook-form';
import { ApolloError, gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client';
import {
  createRegisterLinkMutation,
  createRegisterLinkMutationVariables,
} from '../../__api__/createRegisterLinkMutation';
import { EMAIL_REGEX } from '../../constants';

const CREATE_REGISTER_LINK_MUTATION = gql`
  mutation createRegisterLinkMutation($input: EmailInput!) {
    createRegisterLink(input: $input)
  }
`;

interface CreateRegisterLinkForm {
  email: string;
}

export const RegisterLink = () => {
  const [registerLink, setRegisterLink] = useState<string | null>(null);

  const {
    register,
    getValues,
    errors,
    handleSubmit,
    setValue,
    formState,
  } = useForm<CreateRegisterLinkForm>({
    mode: 'onChange',
  });

  const onCompleted = (data: createRegisterLinkMutation) => {
    const { createRegisterLink: registerLink } = data;
    if (registerLink) {
      setRegisterLink(registerLink);
      setValue('email', null);
    }
  };
  const onError = (error: ApolloError) => {
    if (error) {
      setRegisterLink(null);
    }
  };
  const [createRegisterLinkMutation, { loading, error }] = useMutation<
    createRegisterLinkMutation,
    createRegisterLinkMutationVariables
  >(CREATE_REGISTER_LINK_MUTATION, {
    onCompleted,
    onError,
  });
  const onSubmit = async () => {
    if (!loading) {
      try {
        await createRegisterLinkMutation({
          variables: {
            input: {
              email: getValues('email'),
            },
          },
        });
      } catch (error) {}
    }
  };

  return (
    <div className="py-6">
      <div>
        <h3 className="text-lg font-medium text-left text-gray-900 leading-6">
          {Labels.REGISTER_LINK}
        </h3>
        <div className="flex flex-col items-center mb-4">
          <div className="w-full mt-8 md:max-w-2xl">
            <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                <div>
                  <label htmlFor="email" className="label">
                    {Labels.EMAIL}
                  </label>
                  <div className="mt-1">
                    <input
                      ref={register({
                        required: FormErrorMessages.REQUIRED_EMAIL,
                        pattern: {
                          value: EMAIL_REGEX,
                          message: FormErrorMessages.VALID_EMAIL,
                        },
                      })}
                      name="email"
                      type="email"
                      className="input"
                    />
                    {errors.email?.message && (
                      <FormError errorMessage={errors.email?.message} />
                    )}
                  </div>
                </div>
                <Button
                  canClick={formState.isValid}
                  loading={loading}
                  actionText={Labels.CREATE}
                />
                {error && (
                  <FormError errorMessage={Exceptions[error.message as any]} />
                )}
              </form>
              {registerLink && (
                <div className="mt-4 mb-2">
                  <h5 className="mb-2 font-semibold">Link generado:</h5>
                  <a
                    href={registerLink}
                    className="mt-4 text-gray-500 break-words text-md"
                    target="_blank"
                  >
                    {registerLink}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
