export enum MRExceptions {
    Default = <any>'No se pudo Finalizar la Orden, Intente de nuevo en unos minutos o contactenos',
    MR1033 = <any>'Se ha generado la orden de pago exitosamente en una transaccion anterior',
    MR1146 = <any>'La informacion provista es incompleta',
    MR1147 = <any>'La informacion provista no es correcta, por favor verificar',
    MR1150 = <any>'La informacion provista no es correcta, por favor verificar',
    MR1154 = <any>'El Producto no esta disponible para la venta',
    MR1155 = <any>'El Producto no esta disponible para la venta',
    MR1158 = <any>'No se encontro la cuenta a acreditar, intente de nuevo en unos minutos',
    MR1159 = <any>'No se encontro la cuenta a acreditar, intente de nuevo en unos minutos',
    MR1160 = <any>'El Producto no esta disponible para la venta',
    MR1161 = <any>'El Producto no esta disponible para la venta',
    MR1162 = <any>'La informacion provista no es correcta, por favor verificar',
    MR1163 = <any>'La informacion provista no es correcta, por favor verificar',
    MR1164 = <any>'La informacion provista no es correcta, por favor verificar',
    MR1165 = <any>'La informacion provista no es correcta, por favor verificar',
    MR1166 = <any>'La informacion provista no es correcta, por favor verificar',
    MR1168 = <any>'La informacion provista no es correcta, por favor verificar',
    MR1170 = <any>'La informacion provista no es correcta, por favor verificar',
    MR1172 = <any>'La informacion provista no es correcta, por favor verificar',
    MR1173 = <any>'El servidor no se encuentra disponible, intente de nuevo en unos minutos',
    MR1179 = <any>'Region bloqueada para el producto',
    MR1180 = <any>'La informacion provista no es correcta, por favor verificar',
    MR1181 = <any>'El Producto no esta disponible para la venta',
    MR1182 = <any>'La informacion provista no es correcta, por favor verificar',
    MR1183 = <any>'El Producto esta en mantenimiento, por favor trate en una hora',
    MR1184 = <any>'El Producto no esta disponible para la venta',
    MR1186 = <any>'El Producto no esta disponible para la venta',
    MR1192 = <any>'La conexion con el servidor se cerro, por favor volver a intentar en unos minutos sin salair del cuadro',
    MR1194 = <any>'La informacion provista no es correcta, por favor verificar',
    MR1196 = <any>'El usuario no es elegible para realizar la transaccion',
    MR1270 = <any>'La informacion provista no es correcta, por favor verificar',
    MR1271 = <any>'La informacion provista no es correcta, por favor verificar',
    MR1272 = <any>'La informacion provista no es correcta, por favor verificar',
    MR1300 = <any>'Giftcard sin Stock, tratar más tarde',
}