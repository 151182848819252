import { PencilIcon, TrashIcon } from '@heroicons/react/outline';
import React, { useState } from 'react';
import { Labels } from '../../enums/labels.enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Modal as AModal, Table, Tag, Tooltip } from 'antd';
import { ApolloError, gql } from '@apollo/client/core';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { FormErrorMessages } from '../../enums/form-error-messages.enum';
import { FormError } from '../../components/form-error';
import { Button } from '../../components/button';
import { Exceptions } from '../../enums/exceptions.enum';
import { Modal } from '../../components/modal';
import { AccountType, BankAccountStatus } from '../../__api__/globalTypes';
import {
  createBankAccountMutation,
  createBankAccountMutationVariables,
} from '../../__api__/createBankAccountMutation';
import {
  AccountTypeSpanish,
  BankAccountStatusSpanish,
} from '../../enums/spanish.enum';
import { banksQuery } from '../../__api__/banksQuery';
import {
  checkLength,
  removeSpecialCharacters,
} from '../../utils/validate-utils';
import {
  updateBankAccountMutation,
  updateBankAccountMutationVariables,
} from '../../__api__/updateBankAccountMutation';
import {
  deleteBankAccountMutation,
  deleteBankAccountMutationVariables,
} from '../../__api__/deleteBankAccountMutation';
import {
  bankAccountsQuery,
  bankAccountsQuery_bankAccounts,
} from '../../__api__/bankAccountsQuery';
import {
  SkeletonTable,
  SkeletonTableColumnsType,
} from '../../components/skeleton-table';

const BANKS = gql`
  query banksQuery {
    banks {
      id
      name
    }
  }
`;

const BANK_ACCOUNTS = gql`
  query bankAccountsQuery {
    bankAccounts {
      accountHolder
      accountNumber
      accountType
      bankCode
      bankName
      id
      status
    }
  }
`;

const CREATE_BANK_ACCOUNT_MUTATION = gql`
  mutation createBankAccountMutation($input: CreateBankAccountInput!) {
    createBankAccount(input: $input)
  }
`;

const UPDATE_BANK_ACCOUNT_MUTATION = gql`
  mutation updateBankAccountMutation($input: UpdateBankAccountInput!) {
    updateBankAccount(input: $input)
  }
`;

const DELETE_BANK_ACCOUNT_MUTATION = gql`
  mutation deleteBankAccountMutation($input: DeleteBankAccountInput!) {
    deleteBankAccount(input: $input)
  }
`;

const success = (content: string) => {
  AModal.success({
    content,
  });
};

interface CreateBankAccountForm {
  accountHolder: string;
  accountNumber: string;
  accountType: AccountType;
  bankCode: string;
}

interface CreateBankAccountProps {
  onOk: () => void;
}

const CreateBankAccount: React.FC<CreateBankAccountProps> = ({ onOk }) => {
  const { data: banks } = useQuery<banksQuery>(BANKS);

  const {
    register,
    getValues,
    errors,
    handleSubmit,
    formState: { isValid },
  } = useForm<CreateBankAccountForm>({
    mode: 'onChange',
  });

  const onCompleted = (data: createBankAccountMutation) => {
    const { createBankAccount: bankAccountId } = data;
    if (bankAccountId) {
      onOk();
    }
  };
  const [createBankAccountMutation, { loading, error }] = useMutation<
    createBankAccountMutation,
    createBankAccountMutationVariables
  >(CREATE_BANK_ACCOUNT_MUTATION, {
    onCompleted,
  });
  const onSubmit = async () => {
    if (!loading) {
      try {
        const { ...input } = getValues();
        await createBankAccountMutation({
          variables: {
            input,
          },
        });
      } catch (error) {}
    }
  };

  return (
    <div className="flex flex-col justify-center px-2 py-8 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label htmlFor="accountHolder" className="label">
                Titular de la cuenta
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    minLength: 3,
                    maxLength: 200,
                    validate: {
                      isValidCharacters: (accountHolder) =>
                        /^[áéíóúÁÉÍÓÚñÑa-zA-Z ]*$/.test(accountHolder) ||
                        FormErrorMessages.CHARACTERS,
                    },
                  })}
                  name="accountHolder"
                  minLength={3}
                  maxLength={200}
                  type="text"
                  className="input"
                />
                {errors.accountHolder?.message && (
                  <FormError errorMessage={errors.accountHolder?.message} />
                )}
                {(errors.accountHolder?.type === 'minLength' ||
                  errors.accountHolder?.type === 'maxLength') && (
                  <FormError errorMessage={FormErrorMessages.LENGTH} />
                )}
              </div>
            </div>
            <div>
              <label htmlFor="bankCode" className="label">
                Banco
              </label>
              <div className="mt-1">
                <select
                  name="bankCode"
                  ref={register({ required: true })}
                  className="select"
                >
                  <option value="">{Labels.SELECTED_ONE}</option>
                  {banks?.banks.map((bank) => (
                    <option key={bank.id} value={bank.id}>
                      {bank.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="accountType" className="label">
                Tipo de cuenta
              </label>
              <div className="mt-1">
                <select
                  name="accountType"
                  ref={register({ required: true })}
                  className="select"
                >
                  <option value="">{Labels.SELECTED_ONE}</option>
                  {Object.keys(AccountType).map((accountType) => (
                    <option key={accountType} value={accountType}>
                      {AccountTypeSpanish[accountType as any]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="accountNumber" className="label">
                No. de cuenta
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    minLength: 1,
                    maxLength: 50,
                    validate: {
                      isValidCharacters: (accountNumber) =>
                        /^[0-9]*$/.test(accountNumber) ||
                        FormErrorMessages.CHARACTERS,
                    },
                  })}
                  name="accountNumber"
                  minLength={1}
                  maxLength={50}
                  onKeyDown={(e) => checkLength(e)}
                  onKeyUp={removeSpecialCharacters}
                  className="input"
                />
                {errors.accountNumber?.message && (
                  <FormError errorMessage={errors.accountNumber?.message} />
                )}
                {(errors.accountNumber?.type === 'minLength' ||
                  errors.accountNumber?.type === 'maxLength') && (
                  <FormError errorMessage={FormErrorMessages.LENGTH} />
                )}
              </div>
            </div>
            <Button
              canClick={isValid}
              loading={loading}
              actionText={Labels.CREATE}
            />
            {error && (
              <FormError errorMessage={Exceptions[error.message as any]} />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

interface UpdateBankAccountForm {
  bankCode?: string;
  accountNumber?: string;
  accountHolder?: string;
  accountType?: AccountType;
  status?: BankAccountStatus;
}

interface UpdateBankAccountProps {
  bankAccount: bankAccountsQuery_bankAccounts | null;
  onOk: () => void;
}

const UpdateBankAccount: React.FC<UpdateBankAccountProps> = ({
  bankAccount,
  onOk,
}) => {
  const { data: banks } = useQuery<banksQuery>(BANKS);

  const {
    register,
    getValues,
    errors,
    handleSubmit,
  } = useForm<UpdateBankAccountForm>({
    mode: 'onChange',
    defaultValues: {
      accountNumber: bankAccount?.accountNumber,
      accountHolder: bankAccount?.accountHolder,
      accountType: bankAccount?.accountType,
      bankCode: bankAccount?.bankCode,
      status: bankAccount?.status,
    },
  });

  const onCompleted = (data: updateBankAccountMutation) => {
    const { updateBankAccount: bankAccountId } = data;
    if (bankAccountId) {
      onOk();
    }
  };
  const [updateBankAccountMutation, { loading, error }] = useMutation<
    updateBankAccountMutation,
    updateBankAccountMutationVariables
  >(UPDATE_BANK_ACCOUNT_MUTATION, {
    onCompleted,
  });
  const onSubmit = async () => {
    if (!loading) {
      try {
        await updateBankAccountMutation({
          variables: {
            input: {
              ...getValues(),
              id: bankAccount?.id || '',
            },
          },
        });
      } catch (error) {}
    }
  };

  return (
    <div className="flex flex-col justify-center px-2 py-8 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label htmlFor="accountHolder" className="label">
                Titular de la cuenta
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    minLength: 3,
                    maxLength: 200,
                    validate: {
                      isValidCharacters: (accountHolder) =>
                        /^[áéíóúÁÉÍÓÚñÑa-zA-Z ]*$/.test(accountHolder) ||
                        FormErrorMessages.CHARACTERS,
                    },
                  })}
                  name="accountHolder"
                  minLength={3}
                  maxLength={200}
                  type="text"
                  className="input"
                />
                {errors.accountHolder?.message && (
                  <FormError errorMessage={errors.accountHolder?.message} />
                )}
                {(errors.accountHolder?.type === 'minLength' ||
                  errors.accountHolder?.type === 'maxLength') && (
                  <FormError errorMessage={FormErrorMessages.LENGTH} />
                )}
              </div>
            </div>
            <div>
              <label htmlFor="bankCode" className="label">
                Banco
              </label>
              <div className="mt-1">
                <select
                  name="bankCode"
                  ref={register({ required: true })}
                  className="select"
                >
                  <option value="">{Labels.SELECTED_ONE}</option>
                  {banks?.banks.map((bank) => (
                    <option
                      selected={bankAccount?.bankCode === bank.id}
                      key={bank.id}
                      value={bank.id}
                    >
                      {bank.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="accountType" className="label">
                Tipo de cuenta
              </label>
              <div className="mt-1">
                <select
                  name="accountType"
                  ref={register({ required: true })}
                  className="select"
                >
                  <option value="">{Labels.SELECTED_ONE}</option>
                  {Object.keys(AccountType).map((accountType) => (
                    <option key={accountType} value={accountType}>
                      {AccountTypeSpanish[accountType as any]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="accountNumber" className="label">
                No. de cuenta
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    minLength: 1,
                    maxLength: 50,
                    validate: {
                      isValidCharacters: (accountNumber) =>
                        /^[0-9]*$/.test(accountNumber) ||
                        FormErrorMessages.CHARACTERS,
                    },
                  })}
                  name="accountNumber"
                  minLength={1}
                  maxLength={50}
                  onKeyDown={(e) => checkLength(e)}
                  onKeyUp={removeSpecialCharacters}
                  className="input"
                />
                {errors.accountNumber?.message && (
                  <FormError errorMessage={errors.accountNumber?.message} />
                )}
                {(errors.accountNumber?.type === 'minLength' ||
                  errors.accountNumber?.type === 'maxLength') && (
                  <FormError errorMessage={FormErrorMessages.LENGTH} />
                )}
              </div>
            </div>
            <div>
              <label htmlFor="status" className="label">
                Tipo de cuenta
              </label>
              <div className="mt-1">
                <select
                  name="status"
                  ref={register({ required: true })}
                  className="select"
                >
                  {Object.keys(BankAccountStatus).map((status) => (
                    <option key={status} value={status}>
                      {BankAccountStatusSpanish[status as any]}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <Button
              canClick={true}
              loading={loading}
              actionText={Labels.UPDATE}
            />
            {error && (
              <FormError errorMessage={Exceptions[error.message as any]} />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export const AdminBankAccounts = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [
    bankAccount,
    setBankAccount,
  ] = useState<bankAccountsQuery_bankAccounts | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isModalUpdateVisible, setIsModalUpdateVisible] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);

  const onCompleted = (data: deleteBankAccountMutation) => {
    const { deleteBankAccount: bankAccountId } = data;
    if (bankAccountId) {
      setIsModalDeleteVisible(false);
      success('Cuenta bancaria eliminada satisfactoriamente');
    }
    setErrorMessage(null);
  };

  const onError = (error: ApolloError) => {
    if (error) {
      setErrorMessage(error.message);
    }
  };

  const [deleteBankAccountMutation, { loading: loadingDelete }] = useMutation<
    deleteBankAccountMutation,
    deleteBankAccountMutationVariables
  >(DELETE_BANK_ACCOUNT_MUTATION, {
    onCompleted,
    onError,
  });

  const deleteBankAccount = async (bankAccountId?: string) => {
    if (!loadingDelete) {
      try {
        await deleteBankAccountMutation({
          variables: {
            input: {
              id: bankAccountId || '',
            },
          },
        });
      } catch (error) {}
    }
  };

  const { data, loading, refetch } = useQuery<bankAccountsQuery>(
    BANK_ACCOUNTS,
    {
      fetchPolicy: 'network-only',
    },
  );

  const columns = [
    {
      title: 'Titular de la cuenta',
      dataIndex: 'accountHolder',
      key: 'accountHolder',
    },
    {
      title: 'Banco',
      dataIndex: 'bank',
      key: 'bank',
    },
    {
      title: 'Tipo de cuenta',
      dataIndex: 'accountType',
      key: 'accountType',
    },
    {
      title: 'No. de cuenta',
      dataIndex: 'accountNumber',
      key: 'accountNumber',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const datasource = data?.bankAccounts?.map((item) => ({
    key: item.id,
    accountHolder: item.accountHolder,
    bank: item.bankName,
    accountType: AccountTypeSpanish[item.accountType],
    accountNumber: item.accountNumber,
    status: (
      <Tag color={item.status === 'ACTIVE' ? 'geekblue' : 'error'}>
        {BankAccountStatusSpanish[item.status]}
      </Tag>
    ),
    action: (
      <div className="flex items-center justify-start">
        <div
          onClick={() => {
            setBankAccount(item);
            setIsModalUpdateVisible(true);
          }}
          className="flex-shrink-0"
        >
          <Tooltip title="editar">
            <PencilIcon
              className="w-6 h-6 mr-3 text-gray-400 cursor-pointer hover:text-blue-400"
              aria-hidden="true"
            />
          </Tooltip>
        </div>
        <div
          onClick={() => {
            setBankAccount(item);
            setIsModalDeleteVisible(true);
          }}
          className="flex-shrink-0"
        >
          <Tooltip title="eliminar">
            <TrashIcon
              className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
              aria-hidden="true"
            />
          </Tooltip>
        </div>
      </div>
    ),
  }));

  return (
    <div>
      <Modal
        title={'Eliminar cuenta bancaria'}
        visible={isModalDeleteVisible}
        onOk={async () => {
          await deleteBankAccount(bankAccount?.id);
        }}
        onCancel={() => {
          setIsModalDeleteVisible(false);
          setErrorMessage(null);
        }}
        footer={true}
        afterClose={async () => {
          setErrorMessage(null);
          await refetch();
        }}
        child={
          <div className="flex flex-col">
            <span className="mb-4">
              Estás seguro de eliminar la cuenta bancaria{' '}
              <span className="font-semibold text-blue-400">
                {`${bankAccount?.bankName}-${bankAccount?.accountNumber}`}
              </span>
              ?
            </span>
            {errorMessage && (
              <FormError errorMessage={Exceptions[errorMessage as any]} />
            )}
          </div>
        }
      />
      <Modal
        title={'Crear cuenta bancaria'}
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        afterClose={async () => {
          await refetch();
        }}
        child={
          <CreateBankAccount
            onOk={() => {
              setIsModalVisible(false);
              success('Cuenta bancaria creada satisfactoriamente');
            }}
          />
        }
      />
      <Modal
        title={'Actualizar cuenta bancaria'}
        visible={isModalUpdateVisible}
        onOk={() => setIsModalUpdateVisible(false)}
        onCancel={() => setIsModalUpdateVisible(false)}
        afterClose={async () => {
          await refetch();
        }}
        child={
          <UpdateBankAccount
            bankAccount={bankAccount}
            onOk={() => {
              setIsModalUpdateVisible(false);
              success('Cuenta bancaria actualizada satisfactoriamente');
            }}
          />
        }
      />
      <div className="py-2">
        <div className="py-2 mx-auto max-w-7xl">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-900 leading-6">
              Cuentas bancarias
            </h3>
            <button
              type="button"
              onClick={() => {
                setIsModalVisible(true);
              }}
              className="inline-flex items-center px-3 py-2 mr-3 text-sm font-medium text-white bg-indigo-600 border border-transparent shadow-sm leading-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <div className="flex items-center justify-between">
                <span className="pr-2">{Labels.CREATE}</span>
                <FontAwesomeIcon icon={faPlus} />
              </div>
            </button>
          </div>
          <SkeletonTable
            active={true}
            loading={loading}
            columns={columns as SkeletonTableColumnsType[]}
          >
            <Table
              dataSource={datasource}
              columns={columns}
              pagination={false}
            />
          </SkeletonTable>
        </div>
      </div>
    </div>
  );
};
