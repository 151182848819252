import CryptoJS from 'crypto-js';
/**
 * Calcula el hash SHA-512 de una cadena usando la API SubtleCrypto.
 *
 * @param {string} data - La cadena a hashear.
 * @returns {string} - El hash SHA-512 en formato hexadecimal.
 */

export const sha512 = (data) => {
  return CryptoJS.SHA512(data).toString(CryptoJS.enc.Hex);
}
