import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
import { fetchFamilies } from '../__api__/fetchFamilies';

const FAMILIES = gql`
  query fetchFamilies {
    families {
      id
      name
      index
    }
  }
`;
export const useFamilies = () => {
  return useQuery<fetchFamilies>(FAMILIES);
};
