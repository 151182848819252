/**
 * Transforma un array de objetos con las propiedades 'name' y 'value'
 * en un objeto JSON con pares clave-valor.
 *
 * @param {Object} data - El objeto con el array de campos.
 * @returns {Object} - El objeto transformado.
 */
export const parseFieldsToObject = (data) => {
    // Verifica si 'fields' es un array
    if (!Array.isArray(data.fields)) {
        throw new Error('El objeto proporcionado no contiene un array de campos válido.');
    }

    // Transforma el array en un objeto
    return data.fields.reduce((acc, field) => {
        acc[field.name] = field.value;
        return acc;
    }, {});
}