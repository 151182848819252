import { Role } from '../__api__/globalTypes';
import { Labels } from '../enums/labels.enum';

interface MenuItem {
  name: string;
  path: string;
  isFirstOption: boolean;
}

const customerCommonMenu = [
  {
    name: Labels.MENU_START,
    path: '/',
    isFirstOption: true,
  },
  {
    name: Labels.MENU_CONSIGNMENT,
    path: '/consignments',
    isFirstOption: false,
  },
  {
    name: Labels.MENU_PRODUCTS,
    path: '/products',
    isFirstOption: false,
  },
  {
    name: Labels.MENU_PURCHASES,
    path: '/purchases',
    isFirstOption: false,
  },
];

const adminCommonMenu = [
  {
    name: Labels.MENU_START,
    path: '/',
    isFirstOption: true,
  },
  {
    name: Labels.MENU_CONSIGNMENT,
    path: '/review-consignments',
    isFirstOption: false,
  },
  {
    name: Labels.MENU_ORDERS,
    path: '/review-orders',
    isFirstOption: false,
  },
  {
    name: Labels.MENU_DEVOLUCIONES,
    path: '/review-devoluciones',
    isFirstOption: false,
  },
  {
    name: Labels.MENU_PRESET_ACCOUNTS,
    path: '/preset-accounts',
    isFirstOption: false,
  },
  {
    name: Labels.MENU_SETTINGS,
    path: '/settings',
    isFirstOption: false,
  },
];

const adminMenu = [
  {
    name: Labels.MENU_PRODUCTS,
    path: '/admin-products',
    isFirstOption: false,
  },
  {
    name: Labels.MENU_REPORTS,
    path: '/reports',
    isFirstOption: false,
  },
];

const distributorMenu = [
  {
    name: Labels.MENU_START,
    path: '/',
    isFirstOption: true,
  },
  {
    name: Labels.MENU_ORDERS,
    path: '/review-orders',
    isFirstOption: false,
  },
  {
    name: Labels.MENU_SETTINGS,
    path: '/settings',
    isFirstOption: false,
  },
];

export const menuByRole = (role: Role | undefined): MenuItem[] => {
  if (role === Role.MAIN_CUSTOMER) {
    return [
      ...customerCommonMenu,
      {
        name: Labels.MENU_USERS,
        path: '/users',
        isFirstOption: false,
      },
    ];
  }
  if (role === Role.SUBSIDIARY) {
    return [...customerCommonMenu];
  }
  if (role === Role.ADMIN) {
    return [...adminCommonMenu, ...adminMenu];
  }
  if (role === Role.OPERATOR) {
    return [...adminCommonMenu];
  }
  if (role === Role.DISTRIBUTOR) {
    return [...distributorMenu];
  }
  return [];
};
