import React, { useEffect, useState } from 'react';
import { useMe } from '../../hooks/use-me';
import { Labels } from '../../enums/labels.enum';
import { useForm } from 'react-hook-form';
import { Titles } from '../../enums/titles.enum';
import { Helmet } from 'react-helmet-async';
import { FormErrorMessages } from '../../enums/form-error-messages.enum';
import { FormError } from '../../components/form-error';
import {
  checkLength,
  removeSpecialCharacters,
} from '../../utils/validate-utils';
import { Button } from '../../components/button';
import { Exceptions } from '../../enums/exceptions.enum';
import { gql } from '@apollo/client/core';
import { useProvinces } from '../../hooks/use-provinces';
import {
  updateProfileMutation,
  updateProfileMutationVariables,
} from '../../__api__/updateProfileMutation';
import { Modal } from 'antd';
import { useMutation } from '@apollo/client';
import { provinces_provinces_cities } from '../../__api__/provinces';
import {
  changePasswordMutation,
  changePasswordMutationVariables,
} from '../../__api__/changePasswordMutation';
import { Role } from '../../__api__/globalTypes';
import {
  EMAIL_REGEX,
  PASSWORD_VALID_CHARACTERS_REGEX,
  STRONG_PASSWORD_REGEX,
} from '../../constants';

const UPDATE_PROFILE_MUTATION = gql`
  mutation updateProfileMutation($input: UpdateCustomerInput!) {
    updateCustomer(input: $input)
  }
`;

const CHANGE_PASSWORD_MUTATION = gql`
  mutation changePasswordMutation($input: ChangePasswordInput!) {
    changePassword(input: $input)
  }
`;

interface IFormProps {
  name?: string;
  lastName?: string;
  cellPhone?: string;
  provinceId?: string | null;
  cityId?: string | null;
  email?: string;
}

interface ChangePasswordProps {
  currentPassword: string;
  newPassword: string;
}

export const MyProfile = () => {
  const { data: provinces } = useProvinces();
  const { data, refetch } = useMe();

  const success = (content: string) => {
    Modal.success({
      content,
    });
  };
  const {
    register,
    getValues,
    errors,
    handleSubmit,
    watch,
  } = useForm<IFormProps>({
    mode: 'onChange',
    defaultValues: {
      name: data?.me.customer?.name,
      lastName: data?.me.customer?.lastName,
      cellPhone: data?.me.customer?.cellPhone,
      email: data?.me.email,
      provinceId: data?.me.customer?.provinceId,
      cityId: data?.me.customer?.cityId,
    },
  });

  const {
    register: registerPassword,
    getValues: getValuesPassword,
    errors: errorsPassword,
    handleSubmit: handleSubmitPassword,
    reset,
  } = useForm<ChangePasswordProps>({
    mode: 'onChange',
  });

  const onCompletedPassword = async (data: changePasswordMutation) => {
    const { changePassword: result } = data;
    if (result) {
      reset();
      success('Actualización de contraseña exitosa');
    }
  };

  const [
    changePasswordMutation,
    { loading: loadingPassword, error: errorPassword },
  ] = useMutation<changePasswordMutation, changePasswordMutationVariables>(
    CHANGE_PASSWORD_MUTATION,
    { onCompleted: onCompletedPassword },
  );

  const onSubmitPassword = async () => {
    if (!loadingPassword) {
      try {
        const { ...input } = getValuesPassword();
        await changePasswordMutation({
          variables: {
            input: {
              ...input,
              userId: data?.me.id || '',
            },
          },
        });
      } catch (error) {}
    }
  };

  const onCompleted = async (data: updateProfileMutation) => {
    const { updateCustomer: customerId } = data;
    if (customerId) {
      await refetch();
      success('Actualización de perfil exitosa');
    }
  };

  const [updateProfileMutation, { loading, error }] = useMutation<
    updateProfileMutation,
    updateProfileMutationVariables
  >(UPDATE_PROFILE_MUTATION, { onCompleted });
  const [cities, setCities] = useState<
    provinces_provinces_cities[] | undefined
  >([]);
  const citiesByProvinceId = (provinceId: string | null | undefined) => {
    return (
      provinces?.provinces?.find((province: any) => province.id === provinceId)
        ?.cities || []
    );
  };

  const provinceId = watch('provinceId');

  useEffect(() => {
    if (
      [Role.MAIN_CUSTOMER, Role.SUBSIDIARY].includes(
        data?.me.role || Role.SUBSIDIARY,
      )
    ) {
      setCities(
        citiesByProvinceId(provinceId || data?.me.customer?.provinceId),
      );
    }
  });

  const onSubmit = async () => {
    if (!loading) {
      try {
        const { ...input } = getValues();
        await updateProfileMutation({
          variables: {
            input: {
              ...input,
              id: data?.me.customer?.id || '',
              userId: data?.me.id || '',
            },
          },
        });
      } catch (error) {}
    }
  };

  return (
    <div className="flex flex-col justify-center px-2 py-8 sm:px-6 lg:px-8">
      <Helmet>
        <title>{Titles.MY_PROFILE}</title>
      </Helmet>
      <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-xl">
        {[Role.MAIN_CUSTOMER, Role.SUBSIDIARY].includes(
          data?.me.role || Role.SUBSIDIARY,
        ) && (
          <div>
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <h2 className="mt-1 text-xl font-bold text-center text-gray-900">
                {Labels.MY_PROFILE}
              </h2>
            </div>
            <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
              <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                <div>
                  <label htmlFor="name" className="label">
                    {Labels.NAME}
                  </label>
                  <div className="mt-1">
                    <input
                      ref={register({
                        required: FormErrorMessages.REQUIRED_NAME,
                        minLength: 3,
                        maxLength: 50,
                        validate: {
                          isValidCharacters: (name) =>
                            /^[áéíóúÁÉÍÓÚñÑa-zA-Z ]*$/.test(name) ||
                            FormErrorMessages.CHARACTERS,
                        },
                      })}
                      name="name"
                      minLength={3}
                      maxLength={50}
                      type="text"
                      className="input"
                    />
                    {errors.name?.message && (
                      <FormError errorMessage={errors.name?.message} />
                    )}
                    {(errors.name?.type === 'minLength' ||
                      errors.name?.type === 'maxLength') && (
                      <FormError errorMessage={FormErrorMessages.LENGTH} />
                    )}
                  </div>
                </div>
                <div>
                  <label htmlFor="lastName" className="label">
                    {Labels.LAST_NAME}
                  </label>
                  <div className="mt-1">
                    <input
                      ref={register({
                        required: FormErrorMessages.REQUIRED_LAST_NAME,
                        minLength: 3,
                        maxLength: 50,
                        validate: {
                          isValidCharacters: (lastName) =>
                            /^[áéíóúÁÉÍÓÚñÑa-zA-Z ]*$/.test(lastName) ||
                            FormErrorMessages.CHARACTERS,
                        },
                      })}
                      name="lastName"
                      minLength={3}
                      maxLength={50}
                      type="text"
                      className="input"
                    />
                    {errors.lastName?.message && (
                      <FormError errorMessage={errors.lastName?.message} />
                    )}
                    {(errors.lastName?.type === 'minLength' ||
                      errors.lastName?.type === 'maxLength') && (
                      <FormError errorMessage={FormErrorMessages.LENGTH} />
                    )}
                  </div>
                </div>
                <div>
                  <label htmlFor="cellPhone" className="label">
                    {Labels.CELL_PHONE}
                  </label>
                  <div className="mt-1">
                    <input
                      ref={register({
                        required: FormErrorMessages.REQUIRED_CELL_PHONE,
                        minLength: 10,
                        maxLength: 10,
                        validate: {
                          isValidCharacters: (cellPhone) =>
                            /^[0-9]*$/.test(cellPhone) ||
                            FormErrorMessages.CHARACTERS,
                        },
                      })}
                      name="cellPhone"
                      type="tel"
                      pattern="[0-9]*"
                      minLength={10}
                      maxLength={10}
                      className="input"
                      onKeyDown={(e) => checkLength(e)}
                      onKeyUp={removeSpecialCharacters}
                    />
                    {errors.cellPhone?.message && (
                      <FormError errorMessage={errors.cellPhone?.message} />
                    )}
                    {(errors.cellPhone?.type === 'minLength' ||
                      errors.cellPhone?.type === 'maxLength') && (
                      <FormError
                        errorMessage={FormErrorMessages.CELL_PHONE_LENGTH}
                      />
                    )}
                  </div>
                </div>
                <div>
                  <label htmlFor="provinceId" className="label">
                    {Labels.PROVINCE}
                  </label>
                  <div className="mt-1">
                    <select
                      name="provinceId"
                      ref={register({ required: true })}
                      className="select"
                    >
                      {provinces?.provinces?.map((province: any) => (
                        <option
                          selected={
                            data?.me.customer?.provinceId === province.id
                          }
                          key={province.id}
                          value={province.id}
                        >
                          {province.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <label htmlFor="cityId" className="label">
                    {Labels.CITY}
                  </label>
                  <div className="mt-1">
                    <select
                      name="cityId"
                      ref={register({ required: true })}
                      className="select"
                    >
                      <option value="">{Labels.SELECTED_ONE}</option>
                      {cities?.map((city) => (
                        <option
                          selected={data?.me.customer?.cityId === city.id}
                          key={city.id}
                          value={city.id}
                        >
                          {city.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <label htmlFor="email" className="label">
                    {Labels.EMAIL}
                  </label>
                  <div className="mt-1">
                    <input
                      ref={register({
                        required: FormErrorMessages.REQUIRED_EMAIL,
                        pattern: {
                          value: EMAIL_REGEX,
                          message: FormErrorMessages.VALID_EMAIL,
                        },
                      })}
                      name="email"
                      type="email"
                      className="input"
                    />
                    {errors.email?.message && (
                      <FormError errorMessage={errors.email?.message} />
                    )}
                  </div>
                </div>
                <Button
                  canClick={true}
                  loading={loading}
                  actionText={Labels.UPDATE_PROFILE}
                />
                {error && (
                  <FormError errorMessage={Exceptions[error.message as any]} />
                )}
              </form>
            </div>
          </div>
        )}
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-6 text-xl font-bold text-center text-gray-900">
            Actualizar contraseña
          </h2>
        </div>
        <div className="px-4 py-8 mt-2 bg-white shadow sm:rounded-3xl sm:px-10">
          <form
            onSubmit={handleSubmitPassword(onSubmitPassword)}
            className="space-y-6"
          >
            <div>
              <label htmlFor="currentPassword" className="label">
                Contraseña actual
              </label>
              <div className="mt-1">
                <input
                  ref={registerPassword({
                    required: FormErrorMessages.REQUIRED_PASSWORD,
                    minLength: 8,
                    maxLength: 50,
                    validate: {
                      isValidCharacters: (currentPassword) =>
                        PASSWORD_VALID_CHARACTERS_REGEX.test(currentPassword) ||
                        FormErrorMessages.PASSWORD_ERROR_MESSAGE,
                      strongPassword: (currentPassword) =>
                        STRONG_PASSWORD_REGEX.test(currentPassword) ||
                        FormErrorMessages.PASSWORD_ERROR_MESSAGE,
                    },
                  })}
                  name="currentPassword"
                  minLength={8}
                  maxLength={50}
                  type="password"
                  className="input"
                />
                {errorsPassword.currentPassword?.message && (
                  <FormError
                    errorMessage={errorsPassword.currentPassword?.message}
                  />
                )}
                {(errorsPassword.currentPassword?.type === 'minLength' ||
                  errorsPassword.currentPassword?.type === 'maxLength') && (
                  <FormError
                    errorMessage={FormErrorMessages.PASSWORD_ERROR_MESSAGE}
                  />
                )}
              </div>
            </div>
            <div>
              <label htmlFor="newPassword" className="label">
                Nueva contraseña
              </label>
              <div className="mt-1">
                <input
                  ref={registerPassword({
                    required: FormErrorMessages.REQUIRED_PASSWORD,
                    minLength: 8,
                    maxLength: 50,
                    validate: {
                      isValidCharacters: (newPassword) =>
                        PASSWORD_VALID_CHARACTERS_REGEX.test(newPassword) ||
                        FormErrorMessages.PASSWORD_ERROR_MESSAGE,
                      strongPassword: (newPassword) =>
                        STRONG_PASSWORD_REGEX.test(newPassword) ||
                        FormErrorMessages.PASSWORD_ERROR_MESSAGE,
                    },
                  })}
                  name="newPassword"
                  minLength={8}
                  maxLength={50}
                  type="password"
                  className="input"
                />
                {errorsPassword.newPassword?.message && (
                  <FormError
                    errorMessage={errorsPassword.newPassword?.message}
                  />
                )}
                {(errorsPassword.newPassword?.type === 'minLength' ||
                  errorsPassword.newPassword?.type === 'maxLength') && (
                  <FormError
                    errorMessage={FormErrorMessages.PASSWORD_ERROR_MESSAGE}
                  />
                )}
              </div>
            </div>
            <Button
              canClick={true}
              loading={loadingPassword}
              actionText={'Actualizar contraseña'}
            />
            {errorPassword && (
              <FormError
                errorMessage={Exceptions[errorPassword.message as any]}
              />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
