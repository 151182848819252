import React from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import { Role } from '../__api__/globalTypes';
import { Landing } from '../pages/main-customer/landing';
import { Header } from '../components/header';
import { useMe } from '../hooks/use-me';
import { MyProfile } from '../pages/user/my-profile';
import { Footer } from '../components/footer';
import { Consignments } from '../pages/main-customer/consignments';
import { Products } from '../pages/main-customer/products';
import { Purchases } from '../pages/main-customer/purchases';
import { Labels } from '../enums/labels.enum';
import { Users } from '../pages/main-customer/users';
import { Dashboard } from '../pages/admin-customer/dashboard';
import { ReviewConsignments } from '../pages/admin-customer/review-consignments';
import { ReviewOrders } from '../pages/admin-customer/review-orders';
import { PresetAccounts } from '../pages/admin-customer/preset-accounts';
import { Settings } from '../pages/admin-customer/settings';
import { ProductSettings } from '../pages/admin-customer/product-settings';
import { ReportCustomerCredit } from '../pages/admin-customer/report-customer-credit';
import { DownloadNotaVenta } from '../pages/main-customer/downloadNotaVenta';
import { ReviewDevoluciones } from '../pages/admin-customer/admin-devoluciones';

const commonCustomerRoutes = [
  {
    path: '/',
    component: <Landing />,
  },
  {
    path: '/consignments',
    component: <Consignments />,
  },
  {
    path: '/products',
    component: <Products />,
  },
  {
    path: '/products/:search',
    component: <Products />,
  },
  {
    path: '/purchases',
    component: <Purchases />,
  },
  {
    path: '/downloadNotaVenta',
    component: <DownloadNotaVenta />,
  },
];

const mainCustomerRoutes = [
  ...commonCustomerRoutes,
  {
    path: '/users',
    component: <Users />,
  },
];

const commonRoutes = [
  {
    path: '/my-profile',
    component: <MyProfile />,
  },
];

const commonAdminRoutes = [
  {
    path: '/',
    component: <Dashboard />,
  },
  {
    path: '/review-consignments',
    component: <ReviewConsignments />,
  },
  {
    path: '/review-orders',
    component: <ReviewOrders />,
  },
  {
    path: '/review-devoluciones',
    component: <ReviewDevoluciones />,
  },
  {
    path: '/preset-accounts',
    component: <PresetAccounts />,
  },
  {
    path: '/settings',
    component: <Settings />,
  },
];

const distributorRoutes = [
  {
    path: '/',
    component: <Dashboard />,
  },
  {
    path: '/review-orders',
    component: <ReviewOrders />,
  },
  {
    path: '/settings',
    component: <Settings />,
  },
];

const adminRoutes = [
  {
    path: '/admin-products',
    component: <ProductSettings />,
  },
  {
    path: '/reports',
    component: <ReportCustomerCredit />,
  },
];

export const LoggedInRouter = () => {
  const { data, loading, error } = useMe();
  if (loading || !data || error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <span className="text-xl font-medium tracking-widest">
          {Labels.LOADING}
        </span>
      </div>
    );
  }
  return (
    <Router>
      <Header />
      <Switch>
        {data.me.role === Role.MAIN_CUSTOMER &&
          mainCustomerRoutes.map((route) => (
            <Route exact key={route.path} path={route.path}>
              {route.component}
            </Route>
          ))}
        {data.me.role === Role.SUBSIDIARY &&
          commonCustomerRoutes.map((route) => (
            <Route exact key={route.path} path={route.path}>
              {route.component}
            </Route>
          ))}
        {commonRoutes.map((route) => (
          <Route key={route.path} path={route.path}>
            {route.component}
          </Route>
        ))}
        {(data.me.role === Role.ADMIN || data.me.role === Role.OPERATOR) &&
          commonAdminRoutes.map((route) => (
            <Route exact key={route.path} path={route.path}>
              {route.component}
            </Route>
          ))}
        {data.me.role === Role.ADMIN &&
          adminRoutes.map((route) => (
            <Route exact key={route.path} path={route.path}>
              {route.component}
            </Route>
          ))}
        {data.me.role === Role.DISTRIBUTOR &&
          distributorRoutes.map((route) => (
            <Route exact key={route.path} path={route.path}>
              {route.component}
            </Route>
          ))}
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
};
