import React, { useState } from 'react';
import { gql } from '@apollo/client/core';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/client';
import {
  createProductAccountMutation,
  createProductAccountMutationVariables,
} from '../../__api__/createProductAccountMutation';
import { Labels } from '../../enums/labels.enum';
import { FormError } from '../../components/form-error';
import { Select } from 'antd';
import { Button } from '../../components/button';
import { Exceptions } from '../../enums/exceptions.enum';
import { productsForCustomersQuery } from '../../__api__/productsForCustomersQuery';
import { v4 as uuid } from 'uuid';
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/outline';
import { FormErrorMessages } from '../../enums/form-error-messages.enum';

const { Option } = Select;

interface Field {
  name: string;
  value: string;
}

const PRODUCTS_QUERY = gql`
  query productsForCustomersQuery {
    productsForCustomers {
      category {
        imageURL
      }
      products {
        id
        name
      }
    }
  }
`;

const CREATE_PRODUCT_ACCOUNT_MUTATION = gql`
  mutation createProductAccountMutation($input: CreateProductAccountInput!) {
    createProductAccount(input: $input)
  }
`;

interface CreateProductAccountProps {
  onOk: () => void;
}

export const CreateProductAccount: React.FC<CreateProductAccountProps> = ({
  onOk,
}) => {
  const { data: products } = useQuery<productsForCustomersQuery>(
    PRODUCTS_QUERY,
  );

  const [formElements, setFormElements] = useState<any[]>([]);

  const {
    register,
    getValues,
    errors,
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });

  const onCompleted = (data: createProductAccountMutation) => {
    const { createProductAccount: productAccountId } = data;
    if (productAccountId) {
      onOk();
    }
  };

  const [createProductAccountMutation, { loading, error }] = useMutation<
    createProductAccountMutation,
    createProductAccountMutationVariables
  >(CREATE_PRODUCT_ACCOUNT_MUTATION, { onCompleted });

  const onSubmit = async () => {
    const { ...input } = getValues();
    const fields: Field[] = [];
    let name: string | null, value: string | null;
    if (input) {
      Object.keys(input).forEach((key: string) => {
        if (key.startsWith('name')) {
          name = input[key];
        }
        if (key.startsWith('value')) {
          value = input[key];
        }
        if (name && value) {
          fields.push({ name, value });
          name = null;
          value = null;
        }
      });
    }
    const accountInfo = fields.length > 0 ? { fields } : null;
    if (!loading) {
      try {
        await createProductAccountMutation({
          variables: {
            input: {
              productId: input.productId.split('|')[0],
              accountInfo,
            },
          },
        });
      } catch (error) {}
    }
  };

  interface ResultTupleFieldProps {
    keyProp: string;
  }

  const ResultTupleField: React.FC<ResultTupleFieldProps> = ({ keyProp }) => {
    const nameId = `name-${uuid()}`;
    const valueId = `value-${uuid()}`;
    return (
      <div className="px-3 py-3 border-2 border-r-2 rounded-2xl" key={keyProp}>
        <div
          onClick={() => {
            setFormElements((oldElements) =>
              oldElements.filter(
                (element) => element.props.keyProp !== keyProp,
              ),
            );
          }}
          className="flex justify-end"
        >
          <TrashIcon
            className="w-6 h-6 mr-3 cursor-pointer hover:text-blue-700"
            aria-hidden="true"
          />
        </div>
        <div className="mb-4" key={nameId}>
          <label htmlFor={nameId} className="label">
            Nombre
          </label>
          <div className="mt-1">
            <input
              ref={register({
                required: FormErrorMessages.REQUIRED_FIELD,
              })}
              name={nameId}
              type="text"
              className="input"
            />
            {errors[nameId]?.message && (
              <FormError errorMessage={errors[nameId]?.message} />
            )}
          </div>
        </div>
        <div key={valueId}>
          <label htmlFor={valueId} className="label">
            Valor
          </label>
          <div className="mt-1">
            <input
              ref={register({
                required: FormErrorMessages.REQUIRED_FIELD,
              })}
              name={valueId}
              type="text"
              className="input"
            />
            {errors[valueId]?.message && (
              <FormError errorMessage={errors[valueId]?.message} />
            )}
          </div>
        </div>
      </div>
    );
  };

  const key: string = uuid();

  return (
    <div className="flex flex-col justify-center px-2 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label htmlFor="productId" className="label">
                {Labels.PRODUCT}
              </label>
              <div className="mt-1">
                <Controller
                  control={control}
                  defaultValue={null}
                  name="productId"
                  rules={{ required: true }}
                  render={({ onChange }) => (
                    <Select
                      className="w-a"
                      showSearch
                      style={{ width: 326 }}
                      onChange={(productId) => onChange(productId)}
                      placeholder="Selecciona un producto"
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return (
                          option?.value
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {products &&
                        products.productsForCustomers.flatMap((category) =>
                          category?.products?.map((product) => (
                            <Option
                              key={product.id}
                              value={`${
                                product.id
                              }|${product.name.toLowerCase()}`}
                            >
                              <div className="flex">
                                <img
                                  className="w-8 h-6 mt-0.5"
                                  src={category.category.imageURL}
                                  alt={product.id}
                                />
                                <span className="ml-2">{product.name}</span>
                              </div>
                            </Option>
                          )),
                        )}
                    </Select>
                  )}
                />
              </div>
            </div>
            <div
              onClick={() =>
                setFormElements((oldElements) => [
                  ...oldElements,
                  <ResultTupleField keyProp={key} />,
                ])
              }
              className="flex mt-4 mb-4 text-gray-700 cursor-pointer text-l group"
            >
              <span className="mr-2 text-gray-700 group-hover:text-blue-700">
                Agregar información de la cuenta
              </span>
              <PlusCircleIcon
                className="w-6 h-6 mr-3 group-hover:text-blue-700"
                aria-hidden="true"
              />
            </div>
            {formElements &&
              formElements.map((element, index) => (
                <div className="mb-4" key={index}>
                  {element}
                </div>
              ))}
            <Button
              canClick={isValid && formElements?.length > 0}
              loading={loading}
              actionText={Labels.CREATE}
            />
            {error && (
              <FormError errorMessage={Exceptions[error.message as any]} />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
