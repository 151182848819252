import React from 'react';
import { FooterLogout } from '../components/footer-logout';
import { Link } from 'react-router-dom';
import logo from '../images/logo2.svg';
import { PointsDesign } from '../components/points-design';

const navigation = [{ name: 'Inicio', href: '/' }];

export const Privacy = () => {
  return (
    <div>
      <div className="px-4 pt-6 mx-auto max-w-7xl sm:px-6">
        <Link to="/">
          <span className="sr-only">Gamerecar</span>
          <img className="w-auto h-24" src={logo} alt="Gamerecar" />
        </Link>
      </div>
      <div className="relative py-8 overflow-hidden bg-white">
        <PointsDesign />
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="mx-auto text-lg max-w-prose">
            <h1>
              <span className="block text-xl font-semibold tracking-wide text-center text-indigo-600 uppercase">
                Política de privacidad
              </span>
            </h1>
          </div>
          <div className="mx-auto mt-6 text-gray-500 prose prose-indigo prose-lg">
            <h4>SECCIÓN 1 - ¿QUÉ HACEMOS CON TU INFORMACIÓN?</h4>
            <p>
              Cuando compras algo de nuestra tienda, como parte del proceso de
              compra venta, nosotros recolectamos la información personal que
              nos das tales como nombre, dirección y correo electrónico.
            </p>
            <p>
              Cuando navegas en nuestra tienda, también recibimos de manera
              automática la dirección de protocolo de internet de tu computadora
              (IP) con el fin de proporcionarnos información que nos ayuda a
              conocer acerca de su navegador y sistema operativo.
            </p>
            <p>
              Email marketing (si es aplicable): Con tu permiso, podremos
              enviarte correos electrónicos acerca de nuestra tienda, nuevos
              productos y otras actualizaciones.
            </p>
            <h4>SECCIÓN 2 - CONSENTIMIENTO</h4>
            <p>¿Cómo obtienen mi consentimiento?</p>
            <p>
              Cuando nos provees tu información personal para completar una
              transacción, verificar tu tarjeta de crédito, crear una orden,
              concertar un envío o hacer una devolución, implicamos que aceptas
              la recolección y uso por esa razón específica solamente.
            </p>
            <p>
              Si te pedimos tu información personal por una razón secundaria,
              como marketing, te pediremos directamente tu expreso
              consentimiento, o te daremos la oportunidad de negarte.
            </p>
            <p>¿Cómo puedo anular mi consentimiento?</p>
            <p>
              Si luego de haber aceptado cambias de opinión, puedes anular tu
              consentimiento para que te contactemos, por la recolección, uso o
              divulgación de tu información, en cualquier momento,
              contactándonos a gerencia@gamerecar.com o escribiéndonos a:
              Gamerecar PICHINCHA / QUITO / CENTRO HISTORICO / LOS RIOS Y JOSE ANTEPARA
              Quito, Ecuador.
            </p>
            <h4>SECCIÓN 3 - DIVULGACIÓN</h4>
            <p>
              Podemos divulgar tu información personal si se nos requiere por
              ley o si violas nuestros Términos de Servicio.
            </p>
            
            <h4>SECCIÓN 5 - SERVICIOS DE TERCEROS</h4>
            <p>
              En general, los servicios de terceros utilizados por nosotros solo
              recopilarán, usarán y divulgarán tu información en la medida que
              sea necesaria para que les permita desempeñar los servicios que
              nos proveen.
            </p>
            <p>
              Sin embargo, algunos proveedores de servicios de terceros, tales
              como pasarelas de pago y otros procesadores de transacciones de
              pago, tienen sus propias políticas de privacidad con respecto a la
              información que estamos obligados a proporcionarles para las
              transacciones relacionadas con las compras.
            </p>
            <p>
              Para estos proveedores, te recomendamos que leas las políticas de
              privacidad para que puedas entender la manera en que tu
              información personal será manejada.
            </p>
            <p>
              En particular, recuerda que algunos proveedores pueden estar
              ubicados o contar con instalaciones que se encuentran en una
              jurisdicción diferente a ti o nosotros. Así que si deseas proceder
              con una transacción que involucra los servicios de un proveedor a
              terceros, tu información puede estar sujeta a las leyes de la
              jurisdicción (jurisdicciones) en que se encuentra el proveedor de
              servicios o sus instalaciones.
            </p>
            <p>
              Una vez que abandonas el sitio web de nuestra tienda o te
              rediriges a un sitio o aplicación de terceros, ya no estás siendo
              regulados por la presente Política de Privacidad o los Términos de
              Servicio de nuestra página web.
            </p>
            <p>Enlaces:</p>
            <p>
              Cuando haces clic en enlaces de nuestra tienda, puede que seas
              redirigido fuera de nuestro sitio. No somos responsables por las
              prácticas de privacidad de otros sitios y te recomendamos leer sus
              normas de privacidad.
            </p>
            <h4>SECCIÓN 6 - SEGURIDAD</h4>
            <p>
              Para proteger tu información personal, tomamos precauciones
              razonables y seguimos las mejores prácticas de la industria para
              asegurarnos de que no haya pérdida de manera inapropiada, mal uso,
              acceso, divulgación, alteración o destrucción de la misma.
            </p>
            <p>
              SI nos proporcionas la información de tu tarjeta de crédito, dicha
              información es encriptada mediante la tecnología Secure Socket
              Layer (SSL) y se almacena con un cifrado AES-256. Aunque ningún
              método de transmisión a través de Internet o de almacenamiento
              electrónico es 100% seguro, seguimos todos los requisitos de
              PCI-DSS e implementamos normas adicionales aceptadas por la
              industria.
            </p>
            <h4>SECCIÓN 7 - EDAD DE CONSENTIMIENTO</h4>
            <p>
              Al utilizar este sitio, declaras que tienes al menos la mayoría de
              edad en tu estado o provincia de residencia, o que tienes la
              mayoría de edad en tu estado o provincia de residencia y que nos
              has dado tu consentimiento para permitir que cualquiera de tus
              dependientes menores use este sitio.
            </p>
            <h4>SECCIÓN 8 - CAMBIOS EN ESTA POLÍTICA DE PRIVACIDAD</h4>
            <p>
              Nos reservamos el derecho de modificar esta política de privacidad
              en cualquier momento, asique por favor revísala frecuentemente.
              Cambios y aclaraciones entrarán en vigencia inmediatamente después
              de su publicación en el sitio web. Si hacemos cambios materiales a
              esta política, notificaremos aquí que ha sido actualizada, por lo
              que cual estás enterado de qué información recopilamos, cómo y
              bajo qué circunstancias, si las hubiere, la utilizamos y/o
              divulgamos.
            </p>
            <p>
              Si nuestra tienda es adquirida o fusionada con otra empresa, tu
              información puede ser transferida a los nuevos propietarios, para
              que podamos seguir vendiéndote productos.
            </p>
            <h4>PREGUNTAS E INFORMACIÓN DE CONTACTO</h4>
            <p>
              Si quieres: acceder, corregir, enmendar o borrar cualquier
              información personal que poseamos sobre ti, registrar una queja, o
              simplemente quieres más información contacta a nuestro Oficial de
              Cumplimiento de Privacidad gerencia@gamerecar.com o por correo
              postal a Gamerecar   
            </p>
          </div>


        <div className="mx-auto mt-6 text-gray-500 prose prose-indigo prose-lg">
          <div className="mx-auto text-lg max-w-prose">
              <h1>
                <span className="block text-xl font-semibold tracking-wide text-center text-indigo-600 uppercase">
                AVISO DE PRIVACIDAD Y PROTECCIÓN DE DATOS PERSONALES
                </span>
              </h1>
            </div>
            <p>
            En Gamerecar consideramos muy importante que el usuario sea consciente de cómo recopilamos y qué hacemos 
            con sus datos personales, razón por la cual hemos preparado la siguiente Política de Privacidad y Protección de
             Datos Personales (en adelante, “Política de Privacidad”) para informar sobre el tratamiento de datos personales 
             recopilados a través de nuestra Página Web. La presente política busca dar cumplimiento a las disposiciones legales 
             aplicables en materia de protección de datos personales y privacidad de la información personal, garantizando el 
             tratamiento seguro de los datos personales e información privada de los clientes y los colaboradores de Gamerecar.
            Particularmente, esta Política de Privacidad busca dar cumplimiento a lo establecido en la Ley Orgánica de Protección 
            de Datos Personales publicada en el Registro Oficial Suplemento 459 de 26 de mayo de 2021, a través de la cual se 
            establecen las disposiciones generales que regulan la materia (en adelante, “LOPDP”).
            </p>
          <h4>Responsable del tratamiento:</h4>
          <p>
          Gamerecar.com domicilio ubicado en PICHINCHA / QUITO / CENTRO HISTORICO / LOS RIOS  Y JOSE ANTEPARA, Quito, Distrito 
          Metropolitano, de la ciudad de Quito, Ecuador (en adelante, simplemente “Gamerecar”).
          Gamerecar en su calidad de responsable de tratamiento de datos personales está comprometido a respetar la intimidad, 
          privacidad y el adecuado tratamiento de los datos personales de los usuarios, los cuales se nos ha provisto en 
          condición de usuario de nuestra página web (en adelante, simplemente “la web”).
          En caso de dudas relativas al tratamiento de los datos personales del usuario, se podrá contactar con Gamerecar 
          a través de la siguiente dirección de correo electrónico: gerencia@gamerecar.com
          </p>
          <h4>Información recopilada:</h4>
          <p>
          El usuario no está obligado a entregar ninguna información personal al consultar nuestro sitio web.
          Sin embargo, el usuario puede optar por registrarse en nuestra plataforma para hacer uso de los servicios 
          que brindamos por lo que los siguientes datos serán ingresados.
          Nombres y Apellidos
          Cédula (número de identificación)
          Ciudad
          Dirección de correo electrónico
          Número de teléfono de contacto
          Al hacer “clic” en el botón “Aceptar términos y condiciones” (o equivalente) incorporado en los citados 
          formularios de receptación de datos personales, el usuario declara que la información y los datos que en ellos 
          ha facilitado son exactos y veraces.
          </p>
          <h4>Base que legitima el tratamiento.</h4>
          <p>
          Gamerecar realizará el tratamiento de los datos personales proporcionados por el usuario, en base a su consentimiento 
          expreso (Art. 7.1 LOPDP), solicitado para el caso concreto y en la aceptación expresa del contenido de esta Política 
          de Privacidad.
          El tratamiento de los datos personales provistos se basará en el consentimiento dado por el titular de los datos 
          personales al momento de proporcionar sus datos, así como en el interés legítimo de Gamerecar para llevar a cabo dicho 
          tratamiento, amparado en la normativa vigente.
          Gamerecar se encuentra facultado para utilizar la información del usuario para dar cumplimiento a las distintas obligaciones
           legales o requerimientos de autoridad competente a los que esté obligado a cumplir, así como para el cumplimiento de sus 
           intereses legítimos.
          Adicionalmente, para llevar a cabo el tratamiento de datos personales de los usuarios en la gestión de la contratación de 
          servicios, facturación y envíos correspondientes, la base legitimadora radicará en la ejecución del contrato de servicios 
          suscrito con el titular de los datos (Art. 7.5 LOPDP).
          El titular podrá revocar en cualquier momento el consentimiento conferido para el tratamiento de sus datos personales por 
          Gamerecar, revocatoria que tendrá efectos únicamente hacia el futuro. Esta revocatoria no afectará la legalidad del 
          tratamiento realizado antes de la revocatoria del consentimiento, ratificando la licitud de los tratamientos efectuados 
          con anterioridad.
          El responsable del tratamiento de datos personales podrá retener los datos personales que sean necesarios para cumplir 
          con las finalidades contractuales vigentes o con las obligaciones legales con las que Gamerecar deba cumplir.
          </p>
          <h4>Finalidad para el tratamiento de datos personales y categorías de datos.</h4>
          <p>
          Sus datos personales serán tratados, de manera manual o parcialmente automatizada, para cumplir con el objeto social de 
          Gamerecar y proporcionarle nuestros bienes o servicios al usuario, incluyendo las siguientes finalidades específicas:
          Atender y dar contestación a sus preguntas, consultas, requerimientos o solicitudes, contactándole para brindarle la 
          información requerida, a través de los formularios de registro y cotización habilitados o a través de nuestro servicio 
          al cliente. Gestionar la contratación de servicios que realice a través de la web, así como la facturación y entrega 
          correspondiente.
          Cumplir con las obligaciones jurídicas, como el proporcionar cotizaciones, llevar a cabo ventas, procesar pedidos e 
          informar su estado.
          Remitir periódicamente comunicaciones electrónicas sobre nuestros productos, servicios, eventos, descuentos, novedades, 
          próximos lanzamientos y noticias de interés relacionadas con nuestra oferta comercial, salvo que se indique lo contrario
           o el usuario se oponga o revoque su consentimiento.
          Remitir encuestas para conocer la opinión del usuario sobre los productos o servicios proporcionados por Gamerecar, 
          conociendo la experiencia y valoración de estos y la calidad del servicio.
          Realizar informes estadísticos anónimos respecto a los hábitos de acceso y la actividad desarrollada por los usuarios 
          en la web, así como el uso de nuestros productos o servicio para mejorar nuestra oferta comercial.
          Gestionar las acciones que correspondan para el desarrollo de la etapa precontractual, contractual y post contractual 
          con Gamerecar y terceros contratados, respecto de los productos y servicios ofrecidos. Así como dar cumplimiento a las 
          leyes aplicables y órdenes de autoridades competentes.
          </p>
          <h4>Fines administrativos y contables.</h4>
          <p>
          Realizar grabaciones de las comunicaciones con el cliente.
          Dar a conocer, transferir y/o comunicar sus datos personales dentro y fuera del país, a cualquier empresa o a terceros 
          como consecuencia de un contrato, ley o vínculo lícito que así lo requiera. Transferir y/o comunicar los datos personales 
          a las compañías matrices, filiales, subsidiarias, dentro y fuera del país de su residencia.
          Elaborar un perfil comercial del usuario y realizar acciones comerciales ajustadas al mismo, utilizando sus datos derivados 
          de la gestión de los productos adquiridos (datos de navegación, hábitos de acceso, tráfico), salvo que se indique lo contrario 
          o el usuario se oponga o revoque su consentimiento.
          Valoración de información en caso de brindar servicios de mantenimiento del vehículo liviano o pesado y de esa manera dar 
          el adecuado seguimiento.
          Trazabilidad para identificar a los titulares en caso de necesitar realizar una campaña de RECALL.
          En el caso de que nos remita su CV o se inscriba en las diferentes ofertas de trabajo que podamos publicar, trataremos sus 
          datos con el fin de valorar y gestionar su solicitud de empleo y en su caso, llevar a cabo las actuaciones necesarias para 
          la selección y contratación de personal, a fin de ofertarle puestos que se ajusten a tu perfil. Salvo que se indique lo contrario,
           la aportación de los datos requeridos es necesaria, por lo que su no aportación impedirá la continuidad del proceso de selección.
          Dar cumplimiento a las obligaciones legalmente establecidas, así como verificar el cumplimiento de las obligaciones contractuales,
           incluida la prevención de fraude.
          Adicionalmente, Gamerecar  podrá tratar los siguientes datos personales, de manera enunciativa pero no limitativa, para el 
          cumplimiento de las finalidades accesorias:
          </p>
          <h4>Datos sobre preferencias, características personales o datos de interés.</h4>
          <p>
          Enviar comunicaciones de marketing personalizadas relacionadas con los productos y servicios de manera electrónica 
          (incluidos correos electrónicos, SMS, MMS, notificaciones de redes sociales, aplicaciones móviles)
          Realizar invitaciones a eventos, ofertar nuevos productos y todas aquellas actividades asociadas por la relación comercial 
          o vínculo existente con Gamerecar .
          Compartir sus datos personales para una comunicación de marketing personalizada con nuestros socios comerciales de confianza
           debidamente seleccionados y garantizando el cumplimiento de las medidas técnicas y organizativas necesarias para la protección
            de la información.
          Realizar encuestas de mercado basadas en datos personales agregados de manera anonimizada.
          Para dar inicio a una relación comercial con Gamerecar , y mantener un esquema jurídico y normativo correcto, sustentable en 
          el tiempo y adecuado siendo un requerimiento obligatorio en la legislación aplicable, es necesario contar con su autorización 
          para cumplir con las finalidades específicas antes detalladas.
          Por otro lado, el usuario estará facultado opcionalmente para otorgar su consentimiento para que Gamerecar  trate sus datos 
          personales de conformidad con las finalidades accesorias. 
          </p>
          <h4>En caso de que la voluntad del usuario sea no entregar.</h4>
          <p>
          Su consentimiento a las finalidades accesorias, Gamerecar , las compañías 
          del grupo y socios comerciales se obligan a respetar la decisión, por tanto, no podrán enviarle al usuario comunicaciones de 
          marketing personalizadas por medios automatizados.
          Al no contar con el consentimiento a las finalidades accesorias, esto de ninguna manera será un motivo para negar servicios 
          y/o productos.
          El usuario manifiesta que los datos personales proporcionados son verdaderos, exactos, completos, actuales y verificados, 
          siendo responsable de su autenticidad, así como de cualquier daño o perjuicio, directo o indirecto, que se pueda derivar 
          de la entrega de información incorrecta o falsa.
          En caso de proporcionar datos de terceros, el usuario manifiesta contar con el consentimiento para la comunicación y 
          transferencia internacional de los datos personales y se compromete a trasladar la información del tratamiento de dichos 
          datos personales a su titular, manteniendo indemne de toda responsabilidad a Gamerecar 
          </p>
          <h4>Período de retención.</h4>
          <p>
          Sus datos personales serán tratados y se conservarán durante el tiempo necesario para cumplir con las finalidades 
          señaladas. Cuando Gamerecar  ya no necesite utilizar sus datos personales para cumplir con las finalidades que motivaron 
          su compilación, o para cumplir con obligaciones contractuales o legales, estos serán eliminados del sistema o tomaremos 
          las medidas necesarias para proceder con la anonimización de sus datos personales, de modo que ya no pueda ser identificado
           con ellos.
          El derecho a la eliminación no procederá cuando Gamerecar  requiera conservar su información, incluyendo sus datos 
          personales, para cumplir con las obligaciones legales exigidas por los entes de control con los que se debe reportar.
          </p>
          <h4>Comunicación o transferencia de datos personales.</h4>
          <p>
          Gamerecar  podrá comunicar los datos personales de los usuarios a terceros a los que haya encargado una prestación 
          de servicios puntual, como en el caso de una empresa de asistencia en carretera, de soporte técnico e informático, 
          mediante proveedores de servicios externos que actúan como encargados del tratamiento en virtud de las instrucciones 
          impartidas por Gamerecar , prestando determinados servicios de páginas web, proveedores de servicios de marketing o 
          proveedores de servicios de soporte informático. Durante la prestación de tales servicios, los proveedores de 
          servicios externos pueden tener acceso a o pueden tratar sus datos personales, que deberán hacerlo de acuerdo a las 
          instrucciones específicas de Gamerecar . Estos proveedores de servicios estarán sujetos a las obligaciones contractuales
           obligados a implementar las medidas de seguridad técnicas y organizativas apropiadas para proteger los datos personales 
           de los usuarios.
          Gamerecar  podrá comunicar sus datos personales por orden judicial, resolución o mandato motivado de autoridad competente, 
          autoridades gubernamentales, para lo cual se deberá establecer medidas adecuadas para salvaguardar los derechos fundamentales 
          y libertades del titular. El fundamento jurídico de dicho tratamiento es el cumplimiento de una obligación legal a la que 
          Gamerecar  está sujeta o tiene legítimos intereses, como el ejercicio o la defensa de acciones legales.
          </p>
          <h4>Derechos de los titulares.</h4>
          <p>
          Además de su derecho a revocar en cualquier momento el consentimiento que nos haya dado, el usuario es titular de los 
          siguientes derechos adicionales, cuando se cumplan los requisitos legales pertinentes:
        Derecho de acceso a la información sobre los datos personales almacenados por nosotros, gratuitamente y sin necesidad de 
        presentar justificación alguna, de conformidad con el artículo 13 de la LOPDP.
        Derecho de rectificación y actualización de datos inexactos o incompletos, presentando los justificativos pertinentes al 
        caso, de conformidad con el artículo 14 de la LOPDP.
        Derecho de eliminación de los datos almacenados por nosotros, cuando se cumpla con lo establecido en el artículo 15 de la 
        LOPDP, y siempre que no se deban observar períodos de retención legales o contractuales y/o no existan otras obligaciones 
        o derechos legales con respecto al almacenamiento continuo que deba cumplir Gamerecar  en Ecuador.
        Derecho de oposición al tratamiento y procesamiento de datos personales, cuando se cumpla con lo establecido en el artículo 
        16 de la LOPDP, salvo que el responsable acredite motivos legítimos e imperiosos para el tratamiento que prevalezca sobre 
        los intereses, los derechos y las libertades del titular, o para la formulación, el ejercicio o la defensa de reclamaciones.
        </p>
          <h4>Derecho a la portabilidad de los datos de acuerdo con el artículo 17 de la LOPDP.</h4>
          <p>
        Derecho a la suspensión del tratamiento de datos personales, cuando se cumpla con lo establecido en el artículo 19 de la 
        LOPDP, salvo que el responsable acredite que su tratamiento será, únicamente, para la formulación, el ejercicio o la defensa 
        de reclamaciones; con el objeto de proteger los derechos de otra persona natural o jurídica o por razones de interés pública 
        importante.
        Derecho a presentar una queja ante una autoridad de control; por regla general, puede dirigirse a la autoridad de control 
        de su lugar de residencia o lugar de trabajo habitual o a la sede de nuestra empresa.
        Si tiene alguna pregunta sobre la protección de datos, puede enviar un correo electrónico a gerencia@gamerecar.com. 
        El término de respuesta a cualquier solicitud será de 15 días hábiles contados a partir del día en que se recibió el 
        requerimiento correspondiente, de acuerdo con lo que establece la LOPDP. La respuesta será comunicada dentro del término 
        antes mencionado al domicilio o dirección de correo electrónico que el Titular hubiere proporcionado.
        Los derechos de los titulares de datos personales, según lo establecido en la LOPDP, podrán ser resueltos por la 
        Superintendencia de Protección de Datos de Ecuador, que también conocerá cualquier reclamación realizada por los titulares.  
          </p>
          <h4>Medidas de seguridad.</h4>
          <p>
          Gamerecar  ha implementado medidas de seguridad técnicas, organizativas, jurídicas y administrativas para proteger
           su información personal, aplicando estrictas políticas y estándares internos de control relativos al manejo, 
           tratamiento y salvaguarda de la información personal. Estas medidas son adecuadas con respecto de la naturaleza de 
           los datos objeto de tratamiento.
            Sus datos siempre serán confidenciales. En los casos en los que Gamerecar  utilice prestadores de servicios para 
            el mantenimiento de sus servidores y que éstos se encuentren fuera de Ecuador, las transferencias internacionales
             serán regularizadas de acuerdo con lo dispuesto por la LOPDP, garantiza la protección, integridad y seguridad de 
             los datos personales.
          </p>
          <h4>Cookies</h4>
          <p>
          Además de los datos personales que proporciona activamente el usuario, cuando este proporcione su consentimiento previo, 
          de acuerdo con la política de cookies implementada en el navegador, la web puede recopilar, tratar y almacenar automáticamente
           cierta información sobre usted, mediante tecnologías de recopilación de información. Una cookie es un archivo de texto que 
           se descarga en el equipo (ordenador o dispositivo móvil) con la finalidad de almacenar datos que podrán ser actualizados y 
           recuperados por la entidad responsable de su instalación.
          La información recabada a través de las cookies puede incluir la dirección IP, fecha y hora de visitas al sitio web, las
           páginas visionadas dentro del sitio web, el tiempo que ha estado en nuestro sitio web y los sitios visitados justo antes 
           y después del mismo y otros datos de navegación. Así, las cookies permiten obtener información sobre sus hábitos de navegación.
          Su información será protegida con los más altos estándares de seguridad y no serán entregados a terceros, a menos de que 
          sea requerido por Ley o disposición judicial.
          </p>
          <h4>Menores</h4>
          <p>
          Los menores de edad no deberán usar los servicios disponibles a través de la web, sin la previa autorización de sus padres, 
          tutores o representantes legales, quienes serán los únicos responsables de todos los actos realizados a través de la Web 
          por los menores a su cargo, incluso si los mencionados menores han hecho uso de nuestros formularios incluyendo datos 
          personales y la marcación, en su caso, de las casillas que los acompañan.
          </p>
          <h4>Modificaciones</h4>
          <p>
          El presente Aviso de Privacidad podrá ser modificado en cualquier momento por Gamerecar  con la finalidad de atender 
          novedades legislativas, así como modificaciones de políticas internas de la firma.
          Gamerecar  SAS Ecuador 
          </p>        
        </div>

        </div>
      </div>
      <FooterLogout navigation={navigation} />
    </div>
  );
};
