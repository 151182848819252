import React from 'react';
import { Tabs } from 'antd';
import { ProductCategory } from './product-category';
import { AdminProducts } from './admin-products';
import { AdminOperators } from './admin-operators';
import { AdminOperatorsInternational } from './admin-operators-international';
import { AdminServiciosBasicos } from './admin-serviciosbasicos';
import { Titles } from '../../enums/titles.enum';
import { Helmet } from 'react-helmet-async';

const { TabPane } = Tabs;

export const ProductSettings = () => {
  return (
    <div className="py-6">
      <Helmet>
        <title>{Titles.PRODUCTS}</title>
      </Helmet>
      <div className="px-2 py-2 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <Tabs defaultActiveKey="1">
          <TabPane
            className="py-0"
            style={{ overflow: 'scroll' }}
            tab="Categorías"
            key="1"
          >
            <ProductCategory />
          </TabPane>
          <TabPane style={{ overflow: 'scroll' }} tab="Productos" key="2">
            <AdminProducts />
          </TabPane>
          <TabPane style={{ overflow: 'scroll' }} tab="Operadoras" key="3">
            <AdminOperators />
          </TabPane>
          <TabPane style={{ overflow: 'scroll' }} tab="Operadoras Internacionales" key="4">
            <AdminOperatorsInternational />
          </TabPane>
          <TabPane style={{ overflow: 'scroll' }} tab="Servicios Basicos" key="5">
            <AdminServiciosBasicos />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};
