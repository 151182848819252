export const LOCALSTORAGE_USER_ID = 'userId';
export const INPUT_TEXT_REGEX = /^[áéíóúÁÉÍÓÚñÑA-Za-z0-9 @#?!%$*.,_-]*$/;
export const PASSWORD_VALID_CHARACTERS_REGEX = /^[a-zA-Z0-9@#?!%$*]*$/;
export const STRONG_PASSWORD_REGEX = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#?!%$*])/;
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const VALID_NUMBER_REGEX = /^\d{0,10}(?:[.,]\d{1,3})?$/;
export const VALID_TEXT_NUMBER_REGEX = /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.)\d+)?$/;
export const VALID_PHONE_COUNTRY_REGEX = /^[\+\d]{1,4}$/;
export const VALID_PHONE_REGEX = /^[0-9]{10}$/;
export const VALID_SIMPLE_NUMBER_REGEX = /^[0-9]*$/;
export const DOC_HEADER="GAMERECAR"
export const DOC_FOOTER="La transacción fue realizada por la plataforma https://gamerecar.com/"
export const TITLE_END=" | Gamerecar"

