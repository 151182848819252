import {
  PencilIcon,
} from '@heroicons/react/outline';
import React, { useState, useMemo } from 'react';
import { Labels } from '../../enums/labels.enum';
import { Modal as AModal, Table, Tooltip } from 'antd';
import { gql } from '@apollo/client/core';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useMe } from '../../hooks/use-me';
import { FormErrorMessages } from '../../enums/form-error-messages.enum';
import { FormError } from '../../components/form-error';
import { Button } from '../../components/button';
import { Exceptions } from '../../enums/exceptions.enum';
import { Modal } from '../../components/modal';
import {
  updateServiciosBasicosMutation,
  updateServiciosBasicosMutationVariables,
} from '../../__api__/updateServiciosBasicosMutation';
import {
  fetchServiciosBasicos,
  fetchServiciosBasicos_serviciosbasicos,
} from '../../__api__/fetchServiciosBasicos';
import {
  SkeletonTable,
  SkeletonTableColumnsType,
} from '../../components/skeleton-table';
import { useCategories } from '../../hooks/use-categories';


const SERVICIOS = gql`
  query fetchServiciosBasicos {
    serviciosbasicos {
      id
      filter
      categoryId
      clientPercent
      clientPercentApi
    }
  }
`;


const UPDATE_SERVICIO_MUTATION = gql`
  mutation updateServiciosBasicosMutation($input: UpdateServicioBasicoInput!) {
    updateServicioBasico(input: $input)
  }
`;


const success = (content: string) => {
  AModal.success({
    content,
  });
};

interface UpdateServicioBasicoForm {
  filter?: string;
  categoryId?: string;
  clientPercent?: number;
  clientPercentApi?: number;
}

interface UpdateServicioBasicoProps {
  serviciobasico: fetchServiciosBasicos_serviciosbasicos | null;
  onOk: () => void;
}

const UpdateServicioBasico: React.FC<UpdateServicioBasicoProps> = ({ serviciobasico, onOk }) => {
  const { data: user } = useMe();
  const { data: categories } = useCategories();

  const {
    register,
    getValues,
    errors,
    handleSubmit,
    formState: { isValid },
  } = useForm<UpdateServicioBasicoForm>({
    mode: 'onChange',
    defaultValues: {
      filter: serviciobasico?.filter,
      categoryId: serviciobasico?.categoryId,
      clientPercent: serviciobasico?.clientPercent,
      clientPercentApi: serviciobasico?.clientPercentApi
    },
  });

  const onCompleted = (data: updateServiciosBasicosMutation) => {
    const { updateServicioBasico: serviciobasicoId } = data;
    if (serviciobasicoId) {
      onOk();
    }
  };
  const [updateServicioBasicoMutation, { loading, error }] = useMutation<
    updateServiciosBasicosMutation,
    updateServiciosBasicosMutationVariables
  >(UPDATE_SERVICIO_MUTATION, {
    onCompleted,
  });
  const onSubmit = async () => {
    if (!loading) {
      try {
        const { filter, categoryId,clientPercent,clientPercentApi} = getValues();
        await updateServicioBasicoMutation({
          variables: {
            input: {
              id: serviciobasico?.id == undefined ? 0 : serviciobasico?.id,
              userId: user?.me.id || '',
              filter,
              categoryId,
              clientPercent:Number(clientPercent),
              clientPercentApi:Number(clientPercentApi)
            },
          },
        });
      } catch (error) {}
    }
  };
  const categoryOptions = useMemo(() => categories?.categories || [], [
    categories,
  ]);

  return (
    <div className="flex flex-col justify-center px-2 py-8 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">

            <div>
              <label htmlFor="filter" className="label">
                {Labels.SERVICIO_FILTER}
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.SERVICE_FILTER_LENGTH,
                    minLength: 1,
                    maxLength: 300})}
                  name="filter"
                  minLength={1}
                  maxLength={300}
                  type="text"
                  className="input"
                />
                {errors.filter?.message && (
                  <FormError errorMessage={errors.filter?.message} />
                )}
                {(errors.filter?.type === 'minLength' ||
                  errors.filter?.type === 'maxLength') && (
                  <FormError errorMessage={FormErrorMessages.OPERATOR_NAME_LENGTH} />
                )}
              </div>
            </div>

            <div>
              <label htmlFor="clientPercent" className="label">
                Porcentaje de Descuento para el Cliente (Cambiar esto cambia a TODOS LOS SERVICIOS)
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    min: 0,
                    max: 99
                  })}
                  name="clientPercent"
                  min={0}
                  max={99}
                  type="number"
                  step=".01"
                  className="input"
                />
                {errors.clientPercent?.message && (
                  <FormError errorMessage={errors.clientPercent?.message} />
                )}
                {errors.clientPercent?.type === 'min' ||  errors.clientPercent?.type === 'max' && (
                  <FormError errorMessage={FormErrorMessages.PERCENT_VALUE} />
                )}
              </div>
            </div>

            <div>
              <label htmlFor="clientPercentApi" className="label">
                Porcentaje de Descuento para el Cliente en API (Cambiar esto cambia a TODOS LOS SERVICIOS)
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    min: 0,
                    max: 99
                  })}
                  name="clientPercentApi"
                  min={0}
                  max={99}
                  type="number"
                  step=".01"
                  className="input"
                />
                {errors.clientPercentApi?.message && (
                  <FormError errorMessage={errors.clientPercentApi?.message} />
                )}
                {errors.clientPercentApi?.type === 'min' ||  errors.clientPercentApi?.type === 'max' && (
                  <FormError errorMessage={FormErrorMessages.PERCENT_VALUE} />
                )}
              </div>
            </div>

            <div>
              <label htmlFor="categoryId" className="label">
                Categoria Asignada
              </label>
              <div className="mt-1">
                <select
                  name="categoryId"
                  ref={register({ required: true })}
                  className="input"
                >
                  <option value="">{Labels.SELECTED_ONE}</option>
                  {categoryOptions.map((category: any) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <Button
              canClick={isValid}
              loading={loading}
              actionText={Labels.UPDATE}
            />
            {error && (
              <FormError errorMessage={Exceptions[error.message as any]} />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export const AdminServiciosBasicos = () => {
  const [serviciobasico, setServicioBasico] = useState<fetchServiciosBasicos_serviciosbasicos | null>(
    null,
  );
  const [isModalUpdateVisible, setIsModalUpdateVisible] = useState(false);

  const { data, loading, refetch } = useQuery<fetchServiciosBasicos>(SERVICIOS, {
    fetchPolicy: 'network-only',
  });

  const columns = [
    {
      title: 'Editar',
      dataIndex: 'action',
      key: 'action',
    },
    {
      title: '% Descuento Cliente',
      dataIndex: 'clientPercent',
      key: 'clientPercent',
    },
    {
      title: '% Descuento Cliente API',
      dataIndex: 'clientPercentApi',
      key: 'clientPercentApi',
    },
    {
      title: 'RegEx',
      dataIndex: 'filter',
      key: 'filter',
    },
  ];

  const datasource = data?.serviciosbasicos?.map((item) => ({
    key: item.id,
    filter: item.filter,
    clientPercent: item.clientPercent+"%",
    clientPercentApi: item.clientPercentApi+"%",
    action: (
      <div className="flex items-center justify-start">
        <div
          onClick={() => {
            setServicioBasico(item);
            setIsModalUpdateVisible(true);
          }}
          className="flex-shrink-0"
        >
          <Tooltip title="editar">
            <PencilIcon
              className="w-6 h-6 mr-3 text-gray-400 cursor-pointer hover:text-blue-400"
              aria-hidden="true"
            />
          </Tooltip>
        </div>
      </div>
    ),
  }));

  return (
    <div>
      <Modal
        title={Labels.UPDATE_SERVICIO}
        visible={isModalUpdateVisible}
        onOk={() => setIsModalUpdateVisible(false)}
        onCancel={() => setIsModalUpdateVisible(false)}
        afterClose={async () => {
          await refetch();
        }}
        child={
          <UpdateServicioBasico
            serviciobasico={serviciobasico}
            onOk={() => {
              setIsModalUpdateVisible(false);
              success('Servicio Basico actualizado satisfactoriamente');
            }}
          />
        }
      />
      <div className="py-6">
        <div className="py-2 mx-auto px-1.5 max-w-7xl">
          <SkeletonTable
            active={true}
            loading={loading}
            columns={columns as SkeletonTableColumnsType[]}
          >
            <Table
              dataSource={datasource}
              columns={columns}
              pagination={false}
            />
          </SkeletonTable>
        </div>
      </div>
    </div>
  );
};
