import {
  PencilIcon,
} from '@heroicons/react/outline';
import React, { useState, useMemo } from 'react';
import { Labels } from '../../enums/labels.enum';
import { Modal as AModal, Table, Tooltip } from 'antd';
import { gql } from '@apollo/client/core';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useMe } from '../../hooks/use-me';
import { FormErrorMessages } from '../../enums/form-error-messages.enum';
import { FormError } from '../../components/form-error';
import { Button } from '../../components/button';
import { Exceptions } from '../../enums/exceptions.enum';
import { Modal } from '../../components/modal';
import {
  updateOperatorMutation,
  updateOperatorMutationVariables,
} from '../../__api__/updateOperatorMutation';
import {
  fetchOperators,
  fetchOperators_operators,
} from '../../__api__/fetchOperators';
import {
  SkeletonTable,
  SkeletonTableColumnsType,
} from '../../components/skeleton-table';
import { useCategories } from '../../hooks/use-categories';


const OPERATORS = gql`
  query fetchOperators {
    operators {
      id
      name
      code
      categoryId
      extra
      clientPercent
      clientPercentApi
      ownerPercent
    }
  }
`;


const UPDATE_OPERATOR_MUTATION = gql`
  mutation updateOperatorMutation($input: UpdateOperatorInput!) {
    updateOperator(input: $input)
  }
`;


const success = (content: string) => {
  AModal.success({
    content,
  });
};

interface UpdateOperatorForm {
  name?: string;
  code?: string;
  categoryId?: string;
  extra?: boolean;
  clientPercent?: number;
  clientPercentApi?: number;
  ownerPercent?: number;
}

interface UpdateOperatorProps {
  operator: fetchOperators_operators | null;
  onOk: () => void;
}

const UpdateOperator: React.FC<UpdateOperatorProps> = ({ operator, onOk }) => {
  const { data: user } = useMe();
  const { data: categories } = useCategories();

  const {
    register,
    getValues,
    errors,
    handleSubmit,
    formState: { isValid },
  } = useForm<UpdateOperatorForm>({
    mode: 'onChange',
    defaultValues: {
      name: operator?.name,
      code: operator?.code,
      categoryId: operator?.categoryId,
      extra: operator?.extra == null ? false:operator?.extra,
      clientPercent: operator?.clientPercent,
      clientPercentApi: operator?.clientPercentApi,
      ownerPercent: operator?.ownerPercent
    },
  });

  const onCompleted = (data: updateOperatorMutation) => {
    const { updateOperator: operatorId } = data;
    if (operatorId) {
      onOk();
    }
  };
  const [updateOperatorMutation, { loading, error }] = useMutation<
    updateOperatorMutation,
    updateOperatorMutationVariables
  >(UPDATE_OPERATOR_MUTATION, {
    onCompleted,
  });
  const onSubmit = async () => {
    if (!loading) {
      try {
        const { name, code, categoryId,extra,clientPercent,clientPercentApi,ownerPercent} = getValues();
        await updateOperatorMutation({
          variables: {
            input: {
              id: operator?.id == undefined ? 0 : operator?.id,
              userId: user?.me.id || '',
              name,
              code,
              categoryId,
              extra,
              clientPercent:Number(clientPercent),
              clientPercentApi:Number(clientPercentApi),
              ownerPercent:Number(ownerPercent)
            },
          },
        });
      } catch (error) {}
    }
  };
  const categoryOptions = useMemo(() => categories?.categories || [], [
    categories,
  ]);

  return (
    <div className="flex flex-col justify-center px-2 py-8 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">

            <div>
              <label htmlFor="name" className="label">
                {Labels.OPERATOR_NAME}
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_OPERATOR_NAME,
                    minLength: 2,
                    maxLength: 300,
                    validate: {
                      isValidCharacters: (name) =>
                        /^[áéíóúÁÉÍÓÚñÑa+-zA-Z ]*$/.test(name) ||
                        FormErrorMessages.CHARACTERS,
                    },
                  })}
                  name="name"
                  minLength={2}
                  maxLength={10}
                  type="text"
                  className="input"
                />
                {errors.name?.message && (
                  <FormError errorMessage={errors.name?.message} />
                )}
                {(errors.name?.type === 'minLength' ||
                  errors.name?.type === 'maxLength') && (
                  <FormError errorMessage={FormErrorMessages.OPERATOR_NAME_LENGTH} />
                )}
              </div>
            </div>

            <div>
              <label htmlFor="code" className="label">
                {Labels.OPERATOR_CODE}
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_OPERATOR_CODE,
                    minLength: 1,
                    maxLength: 300,
                    validate: {
                      isValidCharacters: (name) =>
                        /^[áéíóúÁÉÍÓÚñÑa+-zA-Z ]*$/.test(name) ||
                        FormErrorMessages.CHARACTERS,
                    },
                  })}
                  name="code"
                  minLength={1}
                  maxLength={3}
                  type="text"
                  className="input"
                />
                {errors.code?.message && (
                  <FormError errorMessage={errors.code?.message} />
                )}
                {(errors.code?.type === 'minLength' ||
                  errors.code?.type === 'maxLength') && (
                  <FormError errorMessage={FormErrorMessages.OPERATOR_NAME_LENGTH} />
                )}
              </div>
            </div>

            <div>
              <label htmlFor="clientPercent" className="label">
                Porcentaje de Descuento para el Cliente
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    min: 0,
                    max: 99
                  })}
                  name="clientPercent"
                  min={0}
                  max={99}
                  type="number"
                  step=".01"
                  className="input"
                />
                {errors.clientPercent?.message && (
                  <FormError errorMessage={errors.clientPercent?.message} />
                )}
                {errors.clientPercent?.type === 'min' ||  errors.clientPercent?.type === 'max' && (
                  <FormError errorMessage={FormErrorMessages.PERCENT_VALUE} />
                )}
              </div>
            </div>

            <div>
              <label htmlFor="clientPercentApi" className="label">
                Porcentaje de Descuento para el Cliente en API
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    min: 0,
                    max: 99
                  })}
                  name="clientPercentApi"
                  min={0}
                  max={99}
                  type="number"
                  step=".01"
                  className="input"
                />
                {errors.clientPercentApi?.message && (
                  <FormError errorMessage={errors.clientPercentApi?.message} />
                )}
                {errors.clientPercentApi?.type === 'min' ||  errors.clientPercentApi?.type === 'max' && (
                  <FormError errorMessage={FormErrorMessages.PERCENT_VALUE} />
                )}
              </div>
            </div>

            <div>
              <label htmlFor="ownerPercent" className="label">
                Porcentaje de Descuento para el calculo del costo de produccion
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    min: 0,
                    max: 99
                  })}
                  name="ownerPercent"
                  min={0}
                  max={99}
                  type="number"
                  step=".01"
                  className="input"
                />
                {errors.ownerPercent?.message && (
                  <FormError errorMessage={errors.ownerPercent?.message} />
                )}
                {errors.ownerPercent?.type === 'min' ||  errors.ownerPercent?.type === 'max' && (
                  <FormError errorMessage={FormErrorMessages.PERCENT_VALUE} />
                )}
              </div>
            </div>

            <div className="flex -align-center">
              <label htmlFor="extra" className="mt-1 label">
                Es la operadora por defecto?(Solo incluye paquetes que no tienen operadora)
              </label>
              <div className="mt-1">
                <input
                  ref={register()}
                  name="extra"
                  type="checkbox"
                  className="w-4 h-4 ml-2 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                />
              </div>
            </div>
            <div>
              <label htmlFor="categoryId" className="label">
                Categoria Asignada
              </label>
              <div className="mt-1">
                <select
                  name="categoryId"
                  ref={register({ required: true })}
                  className="input"
                >
                  <option value="">{Labels.SELECTED_ONE}</option>
                  {categoryOptions.map((category: any) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <Button
              canClick={isValid}
              loading={loading}
              actionText={Labels.UPDATE}
            />
            {error && (
              <FormError errorMessage={Exceptions[error.message as any]} />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export const AdminOperators = () => {
  const [operator, setOperator] = useState<fetchOperators_operators | null>(
    null,
  );
  const [isModalUpdateVisible, setIsModalUpdateVisible] = useState(false);

  const { data, loading, refetch } = useQuery<fetchOperators>(OPERATORS, {
    fetchPolicy: 'network-only',
  });

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Codigo',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: '%Descuento Cliente',
      dataIndex: 'clientPercent',
      key: 'clientPercent',
    },
    {
      title: '% Descuento Cliente API',
      dataIndex: 'clientPercentApi',
      key: 'clientPercentApi',
    },
    {
      title: '% Descuento Costo Producción',
      dataIndex: 'ownerPercent',
      key: 'ownerPercent',
    },
    {
      title: 'Editar',
      dataIndex: 'action',
      key: 'action',
    }
  ];

  const datasource = data?.operators?.map((item) => ({
    key: item.id,
    name: item.name,
    code: item.code,
    clientPercent: item.clientPercent+"%",
    clientPercentApi: item.clientPercentApi+"%",
    ownerPercent: item.ownerPercent+"%",
    action: (
      <div className="flex items-center justify-start">
        <div
          onClick={() => {
            setOperator(item);
            setIsModalUpdateVisible(true);
          }}
          className="flex-shrink-0"
        >
          <Tooltip title="editar">
            <PencilIcon
              className="w-6 h-6 mr-3 text-gray-400 cursor-pointer hover:text-blue-400"
              aria-hidden="true"
            />
          </Tooltip>
        </div>
      </div>
    ),
  }));

  return (
    <div>
      <Modal
        title={Labels.UPDATE_OPERATOR}
        visible={isModalUpdateVisible}
        onOk={() => setIsModalUpdateVisible(false)}
        onCancel={() => setIsModalUpdateVisible(false)}
        afterClose={async () => {
          await refetch();
        }}
        child={
          <UpdateOperator
            operator={operator}
            onOk={() => {
              setIsModalUpdateVisible(false);
              success('Operador actualizado satisfactoriamente');
            }}
          />
        }
      />
      <div className="py-6">
        <div className="py-2 mx-auto px-1.5 max-w-7xl">
          <SkeletonTable
            active={true}
            loading={loading}
            columns={columns as SkeletonTableColumnsType[]}
          >
            <Table
              dataSource={datasource}
              columns={columns}
              pagination={false}
            />
          </SkeletonTable>
        </div>
      </div>
    </div>
  );
};
