import React, { useState } from 'react';
import { FormErrorMessages } from '../../../../enums/form-error-messages.enum';
import { FormError } from '../../../../components/form-error';
import { useForm } from 'react-hook-form';
import { Labels } from '../../../../enums/labels.enum';
import { Button } from '../../../../components/button';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
    consultarMTTRMutation,
    consultarMTTRMutationVariables
} from '../../../../__api__/consultarMTTRMutation';
import { Exceptions } from '../../../../enums/exceptions.enum';
import { INPUT_TEXT_REGEX } from '../../../../constants';
import { roundWithTwoDecimals } from '../../../../utils/numeric-utils';
import { MRExceptions } from '../../../../enums/mr-exceptions.enum';
import { IBuyProductMTTRProps } from '../buy-product-mttr-props.interface';
import { CONSULTAR_MTTR } from '../../../../common-mutations/common-mutations';
import { accountInitializationMTTR } from '../../../../__api__/accountInitializationMTTR';


const ACCOUNT_INITIALIZATION = gql`
  query accountInitializationMTTR($input: MTTRAccountInitializationInput!) {
    accountInitializationMTTR(input: $input) {
      data
      code
      mensaje
  }
}
`;

export const BuyProductMTTRNetDragon: React.FC<IBuyProductMTTRProps> = ({ onOk, product }) => {
  const [errorCON,seterrorCON] = useState<string | null>(null);
  const [errorCON2,seterrorCON2] = useState<string | null>("Descargando Servidores");
  const [runQuery, setRunQuery] = useState(true);
  const [serverOptions, setserverOptions] = useState<any>([]);

  const { data: serverData } = useQuery<accountInitializationMTTR>(
    ACCOUNT_INITIALIZATION,
    {
      fetchPolicy: 'network-only',
      variables: {
        input: {
          mttr_id: product?.mttrID ? product?.mttrID : "",
        },
      },
      skip: !runQuery,
      onCompleted: () => {
        if(serverData?.accountInitializationMTTR?.code!="200")
        {
          seterrorCON2("No fue posible encontrar los Servidores, tratar mas tarde");
          return;
        }
        const end:any[] = [];
        //iterate over every key in the object serverData?.accountInitializationMTTR?.data?.serverList
        for (const key in serverData?.accountInitializationMTTR?.data?.serverList) {
          //if the key is not a property of the object, skip it
          if (serverData?.accountInitializationMTTR?.data?.serverList.hasOwnProperty(key))
          {
            for(let i=0; i<serverData?.accountInitializationMTTR?.data?.serverList[key].length; i++)
            {
              end.push({id: serverData?.accountInitializationMTTR?.data?.serverList[key][i].serverId, name:key+":"+serverData?.accountInitializationMTTR?.data?.serverList[key][i].serverName+"-"+serverData?.accountInitializationMTTR?.data?.serverList[key][i].serverId});
            }
          }
        }
        console.log(end);
        setserverOptions(end);
        setRunQuery(false);
        seterrorCON(null);
      },
    },
  );

  const {
    register,
    getValues,
    errors,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
  });

  const onCompleted = async (data: consultarMTTRMutation) => {
    const {consultarMTTR : consulta } = data;
    if (consulta) {
      if(consulta.code!="200")
      {
        const errorMR = "MR"+consulta.mensaje?.split(":")[1];
        seterrorCON(MRExceptions[errorMR]? MRExceptions[errorMR] : MRExceptions.Default);
        return;
      }
      consulta.data.order_id = generateRandomString(24);
      consulta.data.server_id = getValues().server_id;
      onOk(consulta.data);
    }
  };
  const [consultarMTTRMutation, { loading, error }] = useMutation<
    consultarMTTRMutation,
    consultarMTTRMutationVariables
  >(CONSULTAR_MTTR, {
    onCompleted,
  });

  function generateRandomString(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    const timestamp = Math.floor(Date.now() / 1000);
    return (timestamp + result).slice(0, length);
  }


  const onSubmit = async () => {
    if (!loading) {
      seterrorCON(null);
      try {
        const { ...input } = getValues();
        if(input.server_id=="-1")
        {
          seterrorCON("Seleccione un Servidor");
          return;
        }
        await consultarMTTRMutation({
          variables: {
            input: {
                mttr_id: product?.mttrID ? product?.mttrID : "",
                values: input
            },
          },
        });
      } catch (error : any) {
        const errorMR = "MR"+(error.toString()?.split(":"))[2];
        seterrorCON(MRExceptions[errorMR]? MRExceptions[errorMR] : MRExceptions.Default);
      }
    }
  };

  return (
    <div className="flex flex-col justify-center px-2 py-8 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="flex-1 min-w-0">
          <div className="flex items-center mb-4 space-x-3">
            <h2 className="text-lg font-bold text-gray-900 leading-7">
              {product?.name}
            </h2>
            {(product?.discount || 0) > 0 && (
              <span className="flex-shrink-0 inline-block px-2 text-base font-medium text-red-500 line-through bg-orange-200 rounded-full py-0.5">
                $ {roundWithTwoDecimals(product?.price || 0)}
              </span>
            )}
            <span className="flex-shrink-0 inline-block px-2 text-base font-medium text-blue-900 bg-blue-200 rounded-full py-0.5">
              $ {roundWithTwoDecimals(product?.priceWithDiscount || 0)}
            </span>
          </div>
          <h3 className="mb-6 text-base font-semibold text-gray-900 leading-7">
            {product?.description}
            <br/>
            {((product?.pvp || 0) > 0) && (<div style={{border:"2px solid red", width:"fit-content", borderRadius: "10px", padding:"2px"}}>Precio de Venta: <span style={{fontWeight: "bold", fontSize:"1rem"}}>${roundWithTwoDecimals(product?.pvp || 0)}</span></div>)}    

          </h3>
          <h4 className="mb-2 text-sm font-normal text-gray-900 leading-5">
            Requerimos de la siguiente información para la compra de tu producto
          </h4>
        </div>
        {runQuery ? (<h4 className="mb-2 text-sm font-normal text-gray-900 leading-5">
            {errorCON2}
          </h4>) : (
            <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              
            <div>
                <label htmlFor="account_id" className="label">
                  {Labels.ACCOUNT_USER}
                </label>
                <div className="mt-1">
                  <input
                    ref={register({
                      required: FormErrorMessages.REQUEIRED_COUNTRY_CODE,
                      minLength: 1,
                      maxLength: 50,
                      validate: {
                        validNumber: (value) => INPUT_TEXT_REGEX.test(value),
                      },
                    })}
                    name="account_id"
                    minLength={1}
                    maxLength={50}
                    type="text"
                    className="input"
                  />
                  {errors.account_id?.message && (
                    <FormError errorMessage={errors.account_id?.message} />
                  )}
                  {(errors.account_id?.type === 'minLength' ||
                    errors.account_id?.type === 'maxLength') && (
                    <FormError errorMessage={FormErrorMessages.LENGTH} />
                  )}
                </div>
              </div>
              <div>
                <label htmlFor="server_id" className="label">
                  {Labels.SERVER_ID}
                </label>
                <div className="mt-1">
                  <select
                    name="server_id"
                    ref={register({ required: true })}
                    className="input"
                    defaultValue={"-1"}
                  >
                    <option value="-1">Seleccione un Servidor</option>
                    {serverOptions.map((server) => (
                      <option key={server.id} value={server.id}>
                        {server.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <Button
                canClick={isValid}
                loading={false}
                actionText={Labels.BUY}
              />
              {errors && (
                <FormError errorMessage={Exceptions[errors.message as any]} />
              )}
              {error && (
                    <FormError errorMessage={Exceptions[error.message as any]} />
                  )}
            </form>
            {errorCON && (
                  <FormError errorMessage={errorCON} />
                )}
          </div>
          )}
        
      </div>
    </div>
  );
};
