import React from 'react';
import { FooterLogout } from '../components/footer-logout';
import { Link } from 'react-router-dom';
import logo from '../images/logo2.svg';
import { PointsDesign } from '../components/points-design';

const navigation = [{ name: 'Inicio', href: '/' }];

export const RefundPolicy = () => {
  return (
    <div>
      <div className="px-4 pt-6 mx-auto max-w-7xl sm:px-6">
        <Link to="/">
          <span className="sr-only">Gamerecar</span>
          <img className="w-auto h-24" src={logo} alt="Gamerecar" />
        </Link>
      </div>
      <div className="relative py-8 overflow-hidden bg-white">
        <PointsDesign />
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="mx-auto text-lg max-w-prose">
            <h1>
              <span className="block text-xl font-semibold tracking-wide text-center text-indigo-600 uppercase">
                Política de reembolso
              </span>
              <span className="block mt-8 text-2xl font-extrabold tracking-tight text-center text-gray-900 leading-8">
                Devoluciones
              </span>
            </h1>
          </div>
          <div className="mx-auto mt-6 text-gray-500 prose prose-indigo prose-lg">
            <p>
              Nuestra política dura 30 días. Si han pasado 30 días desde su
              compra, desafortunadamente no podemos ofrecerle un reembolso o
              cambio.
            </p>
            <p>
              Para ser elegible para una devolución, su artículo debe estar sin
              usar y en las mismas condiciones en que lo recibió. También debe
              estar en el embalaje original.
            </p>
            <p>
              Varios tipos de productos están exentos de ser devueltos. Los
              productos perecederos como alimentos, flores, periódicos o
              revistas no pueden devolverse. Tampoco aceptamos productos que
              sean productos íntimos o sanitarios, materiales peligrosos o
              líquidos o gases inflamables.
            </p>
            <p>Artículos adicionales no retornables:</p>
            <ul>
              <li>Tarjetas de regalo</li>
              <li>Productos de software descargables</li>
              <li>Algunos artículos de salud y cuidado personal</li>
            </ul>
            <p>
              Para completar su devolución, requerimos un recibo o comprobante
              de compra.
            </p>
            <p>No envíe su compra de vuelta al fabricante.</p>
            <p>
              Hay ciertas situaciones en las que solo se otorgan reembolsos
              parciales (si corresponde)
            </p>
            <ul>
              <li>Libro con signos evidentes de uso</li>
              <li>
                CD, DVD, cinta VHS, software, videojuego, cinta de cassette o
                disco de vinilo que se ha abierto
              </li>
              <li>
                Cualquier artículo que no esté en su estado original, está
                dañado o le faltan piezas por razones que no se deben a nuestro
                error
              </li>
              <li>
                Cualquier artículo devuelto más de 30 días después entrega
              </li>
            </ul>
            <h4>Reembolsos (si corresponde)</h4>
            <p>
              Una vez que se reciba e inspeccione su devolución, le enviaremos
              un correo electrónico para notificarle que hemos recibido su
              artículo devuelto. También le notificaremos la aprobación o
              rechazo de su reembolso. Si es aprobado, su reembolso será
              procesado y se aplicará automáticamente un crédito a su tarjeta de
              crédito o método de pago original, dentro de un cierto cantidad de
              días.
            </p>
            <h4>Reembolsos tardíos o faltantes (si corresponde)</h4>
            <p>
              Si aún no ha recibido un reembolso, primero verifique su cuenta
              bancaria nuevamente.
            </p>
            <p>
              Luego comuníquese con la compañía de su tarjeta de crédito, puede
              pasar algún tiempo antes de que su reembolso se publique
              oficialmente.
            </p>
            <p>
              Luego contacte a su banco. A menudo hay algún tiempo de
              procesamiento antes de que se publique un reembolso.
            </p>
            <p>
              Si ha hecho todo esto y aún no ha recibido su reembolso,
              contáctenos en gerencia@gamerecar.com.
            </p>
            <h4>Artículos en oferta (si corresponde)</h4>
            <p>
              Solo se pueden reembolsar los artículos a precio regular,
              desafortunadamente los artículos en oferta no se pueden
              reembolsar.
            </p>
            <h4>Intercambios (si corresponde)</h4>
            <p>
              Solo reemplazamos los artículos si están defectuosos o dañados. Si
              necesita cambiarlo por el mismo artículo, envíenos un correo
              electrónico a gerencia@gamerecar.com y envíe su artículo a:
              PICHINCHA / QUITO / CENTRO HISTORICO /LOS RIOS Y JOSE ANTEPARA Quito, Quito, Ecuador.
            </p>
            <h4>Regalos</h4>
            <p>
              Si el artículo se marcó como regalo cuando se compró y se le envió
              directamente a usted, recibirá un crédito de regalo por el valor
              de su devolución. Una vez que se reciba el artículo devuelto, se
              le enviará un certificado de regalo.
            </p>
            <p>
              Si el artículo no se marcó como regalo cuando lo compró, o si el
              donante recibió el pedido para que se lo envíen más tarde, le
              enviaremos un reembolso al donante y él encontrará fuera de su
              regreso.
            </p>
            <h4>Envío</h4>
            <p>
              Para devolver su producto, debe enviarlo por correo a: Amazonas y
              Gazpar de Villaroel NA, 170507 Quito, Quito, Ecuador.
            </p>
            <p>
              Usted será responsable de pagar sus propios costos de envío para
              devolver su artículo. Los gastos de envío no son reembolsables. Si
              recibe un reembolso, el costo del envío de devolución se deducirá
              de su reembolso.
            </p>
            <p>
              Dependiendo de dónde viva, el tiempo que le tome a su producto
              intercambiado llegar a usted puede variar.
            </p>
            <p>
              Si está enviando un artículo de más de $ 75, debería considerar
              usar un servicio de envío rastreable o comprar un seguro de envío.
              No garantizamos que recibiremos su artículo devuelto.
            </p>
            <p>
              Cualquier forma de clave de CD, tarjeta de regalo, tarjeta de
              tiempo, expansión, puntos de juego, tarjeta prepaga, código de
              prueba / beta / artículo, clave de juego comprada en su cuenta no
              es reembolsable ni intercambiable.
            </p>
            <h4>Descargo de responsabilidad:</h4>
            <h4>Software, pines</h4>
            <ul>
              <li>
                No incluye manuales, CD de instalación, caja, tarjeta física u
                otros elementos físicos.
              </li>
              <li>
                Podrá ver su (s) código (s) en su página de historial de pedidos
                después de completar su pedido.
              </li>
              <li>
                El reembolso del artículo de pedido anticipado está disponible 2
                semanas antes del lanzamiento del producto. Esto no se aplica al
                pedido por adelantado de CD Game Key.
              </li>
              <li>
                Una vez que haya completado la compra de cualquier pedido
                anticipado, clave de CD, tarjeta de tiempo, expansión de juego,
                punto de juego, tarjeta prepagada, código de prueba / beta /
                artículo, clave de juego de Gamerecar, esto
                significaría que acepta que toda la información introducida es
                precisa y correcta.
              </li>
              <li>
                Cualquier compra de pedido anticipado, clave de CD, tarjeta de
                tiempo, expansión, punto de juego, tarjeta prepagada, código de
                prueba / beta / artículo, clave de juego de Gamerecar
                no es reembolsable, no es transferible y no se puede cambiar una
                vez vendida.
              </li>
              <li>
              Gamerecar se reserva el derecho de modificar
                cualquiera de los términos y condiciones anteriores sin previo
                aviso.
              </li>
            </ul>
            <h4>Recarga directa</h4>
            <ul>
              <li>
                Lea detenidamente la descripción del producto y confirme que el
                nombre del juego, el servidor, la identificación del usuario del
                juego, el nombre del personaje, el nombre de la cuenta y la
                cantidad comprada, es decir, la información, sean correctos.
              </li>
              <li>
              Gamerecar no asume ninguna responsabilidad por compras
                incorrectas realizadas por el cliente debido a negligencia y / o
                información falsa / incorrecta proporcionada, que puede resultar
                en daños / pérdidas / recarga incorrecta. Al comprar cualquier
                producto de Gamerecar, el cliente comprende, reconoce
                y acepta esta exención de responsabilidad.
              </li>
              <li>
                Si tiene alguna dificultad, no dude en ponerse en contacto con
                nuestro Soporte en vivo 24 horas al día, 7 días a la semana para
                obtener más ayuda.
              </li>
            </ul>
            <h4 className="text-center">
              Política y exención de responsabilidad de reembolsos y cambios
            </h4>
            <p>
            Gamerecar no se hace responsable de la pérdida o daño de
              la Tarjeta de regalo y de cualquiera de las pérdidas o daños
              sufridos por el titular de la Tarjeta de regalo que surjan de o en
              relación con la pérdida o daño de la Tarjeta de regalo.
            </p>
            <p>
              Cualquier forma de clave de CD, tarjeta de regalo, tarjeta de
              tiempo, expansión, puntos de juego, tarjeta prepaga, código de
              prueba / beta / artículo, clave de juego comprada en la cuenta del
              cliente no es reembolsable ni intercambiable.
            </p>
            <p>
              Sin embargo, se considerará la solicitud de reembolso o cambio en
              casos excepcionales si los productos comprados en cualquier forma
              de clave de CD, tarjeta de regalo, tarjeta de tiempo, expansión,
              puntos de juego, tarjeta prepaga, código de prueba / beta /
              artículo, clave de juego defectuoso o dañado.
            </p>
            <p>
              El cliente debe enviar una solicitud a Gamerecar sobre
              cualquier producto defectuoso o dañado. De no hacerlo,
              automáticamente perderá el derecho del cliente a realizar
              cualquier reembolso o cambio.
            </p>
            <p>
            Gamerecar se reserva el derecho de modificar cualquiera
              de los términos y condiciones anteriores sin previo aviso.
            </p>
          </div>
        </div>
      </div>
      <FooterLogout navigation={navigation} />
    </div>
  );
};
