import React from 'react';
import { FooterLogout } from '../components/footer-logout';
import { Link } from 'react-router-dom';
import logo from '../images/logo2.svg';
import { PointsDesign } from '../components/points-design';

const navigation = [{ name: 'Inicio', href: '/' }];

export const ShippingPolicy = () => {
  return (
    <div>
      <div className="px-4 pt-6 mx-auto max-w-7xl sm:px-6">
        <Link to="/">
          <span className="sr-only">Gamerecar</span>
          <img className="w-auto h-24" src={logo} alt="Gamerecar" />
        </Link>
      </div>
      <div className="relative py-8 overflow-hidden bg-white">
        <PointsDesign />
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="mx-auto text-lg max-w-prose">
            <h1>
              <span className="block text-xl font-semibold tracking-wide text-center text-indigo-600 uppercase">
                Política de envío
              </span>
            </h1>
          </div>
          <div className="mx-auto mt-6 text-gray-500 prose prose-indigo prose-lg">
            <h4>¿Cuándo será procesado mi pedido?</h4>
            <p>
              Todos los pedidos se manejan y se envían desde nuestro almacén en
              China.
            </p>
            <p>
              Por favor, espere un tiempo adicional para que su pedido sea
              procesado durante las vacaciones y las temporadas de rebajas.
              Procesamos pedidos de lunes a viernes. Los pedidos se procesarán
              dentro de 3-5 días hábiles a partir de la fecha del pedido y se
              enviarán al día siguiente después del día de procesamiento. Tenga
              en cuenta que no enviamos los fines de semana.
            </p>
            <h4>¿Cuánto tiempo llevará recibir mi pedido?</h4>
            <p>
              Una vez que realice su pedido, espere de 3 a 5 días hábiles para
              procesar sus pedidos. Después de eso, tomará entre 12 y 20 días
              hábiles para la entrega en los Estados Unidos, y entre 15 y 30
              días hábiles para los pedidos internacionales (según la
              ubicación).
            </p>
            <h4>¿Me cobrarán impuestos y aduanas?</h4>
            <p>
              Los precios que se muestran en nuestro sitio están libres de
              impuestos en dólares estadounidenses, lo que significa que es
              posible que deba pagar los aranceles e impuestos una vez que
              reciba su pedido. Los impuestos de importación, aranceles y
              aranceles aduaneros relacionados se pueden cobrar una vez que su
              pedido llega a su destino final, y lo determina su oficina de
              aduanas local. El pago de estos cargos e impuestos es su
              responsabilidad y no será cubierto por nosotros. No somos
              responsables de los retrasos causados por el departamento de
              aduanas de su país. Para obtener más detalles sobre los cargos,
              comuníquese con su oficina de aduanas local.
            </p>
          </div>
        </div>
      </div>
      <FooterLogout navigation={navigation} />
    </div>
  );
};
