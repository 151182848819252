/**
 * Obtiene la fecha y hora actual en formato Unix (segundos).
 *
 * @returns {number} - La fecha y hora actual en formato Unix (segundos).
 */
export const getCurrentUnixTimestamp = () => {
    // Obtén la fecha y hora actual
    const now = new Date();

    // Convierte la fecha y hora a formato Unix (segundos)
    return Math.floor(now.getTime() / 1000);
}

// Ejemplo de uso
const unixTimestamp = getCurrentUnixTimestamp();
console.log(unixTimestamp);
