import React, { useState, useRef, useEffect } from 'react';

interface DraggableZoomableImageProps {
    src: string; // URL de la imagen
    containerWidth?: string; // Ancho del contenedor (opcional)
    containerHeight?: string; // Alto del contenedor (opcional)
    minZoom?: number; // Zoom mínimo
    maxZoom?: number; // Zoom máximo
    enableTouch: boolean; // Valor predeterminado para enableTouch
}

const DraggableZoomableImage: React.FC<DraggableZoomableImageProps> = ({
    src,
    containerWidth = '120px', // Valor predeterminado
    containerHeight = '500px', // Valor predeterminado
    minZoom = -1, // Zoom mínimo predeterminado
    maxZoom = 3, // Zoom máximo predeterminado
    enableTouch = false, // Valor predeterminado para enableTouch
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const [zoom, setZoom] = useState(1);
    const lastMousePosition = useRef({ x: 0, y: 0 });

    // Manejar el inicio del arrastre
    const handleMouseDown = (e: React.MouseEvent) => {
        setIsDragging(true);
        lastMousePosition.current = { x: e.clientX, y: e.clientY };
    };


    // Función para manejar el arrastre con el toque
    const handleTouchStart = (e: React.TouchEvent) => {
        setIsDragging(true);
        const touch = e.touches[0];
        lastMousePosition.current = { x: touch.clientX, y: touch.clientY };
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        if (!isDragging) return;
        const touch = e.touches[0];
        const deltaX = touch.clientX - lastMousePosition.current.x;
        const deltaY = touch.clientY - lastMousePosition.current.y;
        setPosition((prev) => ({ x: prev.x + deltaX, y: prev.y + deltaY }));
        lastMousePosition.current = { x: touch.clientX, y: touch.clientY };
    };

    // Manejar el final del arrastre
    const handleMouseUp = () => { setIsDragging(false); };
    const handleTouchEnd = () => setIsDragging(false);

    // Manejar el movimiento de la imagen
    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isDragging) return;

        const deltaX = e.clientX - lastMousePosition.current.x;
        const deltaY = e.clientY - lastMousePosition.current.y;

        setPosition((prev) => ({
            x: prev.x + deltaX,
            y: prev.y + deltaY,
        }));

        lastMousePosition.current = { x: e.clientX, y: e.clientY };
    };

    // Manejar el zoom (scroll)
    const handleWheel = (e: WheelEvent) => {
        e.preventDefault(); // Evitar que la página se desplace

        const zoomFactor = 0.1;
        setZoom((prevZoom) => {
            const newZoom =
                e.deltaY < 0
                    ? Math.min(prevZoom + zoomFactor, maxZoom) // Aumentar zoom
                    : Math.max(prevZoom - zoomFactor, minZoom); // Reducir zoom
            return newZoom;
        });
    };

    // Función para restablecer la imagen
    const resetImage = () => {
        setPosition({ x: 0, y: 0 });
        setZoom(1);
    };

    // Efecto para agregar el evento de rueda
    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('wheel', handleWheel);
        }
        return () => {
            if (container) {
                container.removeEventListener('wheel', handleWheel);
            }
        };
    }, [containerRef]); // Ejecutar solo una vez

    return (
        <div className="relative border-2 border-solid border-gray-700 bg-gray-300"
            style={{
                width: containerWidth,
                height: containerHeight,
            }}>
            <div
                ref={containerRef}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onTouchStart={enableTouch ? handleTouchStart : undefined}
                onTouchMove={enableTouch ? handleTouchMove : undefined}
                onTouchEnd={enableTouch ? handleTouchEnd : undefined}
                style={{
                    cursor: isDragging ? 'grabbing' : 'grab', padding: 0, margin: 0,
                }}
                tabIndex={0} // Permitir que el contenedor reciba el foco
                className='w-full h-full border-2 border-solid border-gray-700 overflow-hidden relative'
            >
                <img
                    className=''
                    src={src}
                    alt="Zoomable and Draggable"
                    style={{
                        transform: `translate(${position.x}px, ${position.y}px) scale(${zoom})`,
                        cursor: 'move',
                        userSelect: 'none',
                        willChange: 'transform', // Mejora del rendimiento para animaciones
                    }}
                    draggable="false" // Desactivar el arrastre nativo del navegador
                />
            </div>
            {/* Botón para reiniciar la imagen */}
            <button
                onClick={resetImage}
                className="absolute top-2 left-2 bg-blue-600 text-white px-2 py-1 rounded"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    width="24"
                    height="24"
                >
                    <path d="M21 12a9 9 0 1 1-4.5-7.8" />
                    <polyline points="20 4 20 10 14 10" />
                </svg>

            </button>
        </div>
    );
};

export default DraggableZoomableImage;
