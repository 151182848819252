import React, { Dispatch, SetStateAction, useState } from 'react';
import { FormErrorMessages } from '../../enums/form-error-messages.enum';
import { FormError } from '../../components/form-error';
import { useForm } from 'react-hook-form';
import { Labels } from '../../enums/labels.enum';
import { Button } from '../../components/button';
import { gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client';
import {
  createPurchaseOrderMutation,
  createPurchaseOrderMutationVariables,
} from '../../__api__/createPurchaseOrderMutation';
import { useMe } from '../../hooks/use-me';
import { Exceptions } from '../../enums/exceptions.enum';
import { checkLength, removeSpecialCharacters } from '../../utils/validate-utils';
import { productsByCategoryId_productsByCategoryId } from '../../__api__/productsByCategoryId';
import { FacType } from '../../__api__/globalTypes';
import LoaderButton from '../../components/loaders/LoaderButton';

interface IBuyProductProps {
  onOk: () => void;
  details: any | null;
  product: productsByCategoryId_productsByCategoryId | null;
  setLoadingPurcharse?: Dispatch<SetStateAction<boolean>>;
}

interface IPagarForm {
  lastName: string;
  name: string;
  identificationNumber: string;
  direccion: string;
  tipocliente: number;
}

const CREATE_PURCHASE_ORDER = gql`
  mutation createPurchaseOrderMutation($input: CreatePurchaseOrderInput!) {
    createPurchaseOrder(input: $input)
  }
`;


export const BuyProduct2: React.FC<IBuyProductProps> = ({ onOk, details, product, setLoadingPurcharse }) => {
  const { data, refetch } = useMe();
  let primalLoading = false;

  const [errorCON, seterrorCON] = useState<string | null>(null);
  const [showConsumidor, setshowConsumidor] = useState<boolean>(false);

  const {
    register,
    getValues,
    handleSubmit,
    formState: { },
  } = useForm<IPagarForm>({
    mode: 'onChange',
  });

  const consumidorChange = e => {
    if (!e) {
      return;
    }
    const val = Number(getValues().tipocliente);
    if (details == null) {
      setshowConsumidor(false);

    }
    else {
      setshowConsumidor(val != 1);
    }
  }

  const onCompleted = async (data: createPurchaseOrderMutation) => {
    const { createPurchaseOrder: purchaseOrderId } = data;
    if (purchaseOrderId) {
      primalLoading = true;
      if (setLoadingPurcharse) {
        console.log("cambia", true);
        setLoadingPurcharse(true);
      }
      await refetch();
      onOk();
    }
  };
  const [createPurchaseOrderMutation, { loading, error }] = useMutation<
    createPurchaseOrderMutation,
    createPurchaseOrderMutationVariables
  >(CREATE_PURCHASE_ORDER, {
    onCompleted,
  });

  const onSubmit = async () => {
    if (primalLoading) {
      return;
    }
    primalLoading = true;
    if (setLoadingPurcharse) {
      console.log("cambia", true);
      setLoadingPurcharse(true);
    }
    let facIdType: FacType = FacType.CONSUMIDORFINAL;
    switch (Number(getValues().tipocliente)) {
      case 1:
        facIdType = FacType.CONSUMIDORFINAL;
        break;
      case 2:
        facIdType = FacType.CEDULA;
        break;
      case 3:
        facIdType = FacType.RUC;
        break;
      case 4:
        facIdType = FacType.PASAPORTE;
        break;
      default:
        alert(Number(getValues().tipocliente));
        seterrorCON("Tipo de Consumidor no reconocido");
        return;
    }
    if (details == null || details == undefined) {
      seterrorCON("Error de Consulta");
      return;
    }
    if (product == null || product == undefined) {
      seterrorCON("Error de Producto");
      return;
    }
    if (!loading) {
      seterrorCON(null);
      try {
        const { ...input } = getValues();
        if (facIdType != FacType.CONSUMIDORFINAL) {
          if (input.name === "" || input.name == undefined) {
            seterrorCON(Labels.NAME_FAC_SERVICIO + ": " + FormErrorMessages.REQUIRED_FIELD);
            return;
          }
          if (input.name.length > 30) {
            seterrorCON(Labels.NAME_FAC_SERVICIO + ": " + FormErrorMessages.LENGTH);
            return;
          }
          if (!/^[áéíóúÁÉÍÓÚñÑa-zA-Z ]*$/.test(input.name)) {
            seterrorCON(Labels.NAME_FAC_SERVICIO + ": " + FormErrorMessages.CHARACTERS);
            return;

          }
          if (input.lastName === "" || input.lastName == undefined) {
            seterrorCON(Labels.APELLIDO_FAC_SERVICIO + ": " + FormErrorMessages.REQUIRED_FIELD);
            return;
          }
          if (input.lastName.length > 30) {
            seterrorCON(Labels.APELLIDO_FAC_SERVICIO + ": " + FormErrorMessages.LENGTH);
            return;
          }
          if (!/^[áéíóúÁÉÍÓÚñÑa-zA-Z ]*$/.test(input.lastName)) {
            seterrorCON(Labels.APELLIDO_FAC_SERVICIO + ": " + FormErrorMessages.CHARACTERS);
            return;

          }
          if (input.direccion === "" || input.direccion == undefined) {
            seterrorCON(Labels.DIR_FAC_SERVICIO + ": " + FormErrorMessages.REQUIRED_FIELD);
            return;
          }
          if (input.direccion.length > 150) {
            seterrorCON(Labels.DIR_FAC_SERVICIO + ": " + FormErrorMessages.LENGTH);
            return;
          }
          if (!/^[áéíóúÁÉÍÓÚñÑa-zA-Z ]*$/.test(input.direccion)) {
            seterrorCON(Labels.DIR_FAC_SERVICIO + ": " + FormErrorMessages.CHARACTERS);
            return;

          }
          if (input.identificationNumber === "" || input.identificationNumber == undefined) {
            seterrorCON(Labels.CI_FAC_SERVICIO + ": " + FormErrorMessages.REQUIRED_FIELD);
            return;
          }
          if (input.identificationNumber.length < 10 || input.identificationNumber.length > 30) {
            seterrorCON(Labels.CI_FAC_SERVICIO + ": " + FormErrorMessages.ID_LENGTH_SERVICE);
            return;
          }
          if (!/^[0-9]*$/.test(input.identificationNumber)) {
            seterrorCON(Labels.CI_FAC_SERVICIO + ": " + FormErrorMessages.CHARACTERS);
            return;
          }
        }
        await createPurchaseOrderMutation({
          variables: {
            input: {
              userId: data?.me.id || '',
              productId: product?.id || '',
              subid: product?.subid,
              total: product?.priceWithDiscount || 0,
              orderDetail: details,
              facData: {
                factNames: facIdType == FacType.CONSUMIDORFINAL ? "" : input.name,
                facLastnames: facIdType == FacType.CONSUMIDORFINAL ? "" : input.lastName,
                facAddress: facIdType == FacType.CONSUMIDORFINAL ? "" : input.direccion,
                facID: facIdType == FacType.CONSUMIDORFINAL ? "" : input.identificationNumber,
                facIDType: facIdType
              }
            },
          },
        });
      } catch (error: any) {
        seterrorCON(error.toString());
      }
      finally {
        primalLoading = false;
        if (setLoadingPurcharse) {
          setLoadingPurcharse(false);
        }
      }
    }
  };

  return (
    <div className="flex flex-col justify-center px-2 py-8 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="flex-1 min-w-0">
          <div className="flex items-center mb-4 space-x-3">
            <h2 className="text-lg font-bold text-gray-900 leading-7">
              {product?.name}
            </h2>
          </div>
          <h3 className="mb-6 text-base font-semibold text-gray-900 leading-7">
            {product?.description}
          </h3>
          <h4 className="mb-2 text-sm font-normal text-gray-900 leading-5">
            Requerimos de la siguiente información para la compra de tu producto asdasd
          </h4>
        </div>
        {(details == null || details == undefined) ? (<FormError errorMessage="No existe un detalle del producto" />) : (
          <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              <div>
                <label htmlFor="tipocliente" className="label">
                  {Labels.CLI_SELECT_SERVICIO}
                </label>
                <div className="mt-1">
                  <select
                    name="tipocliente"
                    ref={register({ required: true })}
                    className="input"
                    onChange={consumidorChange}
                    defaultValue={1}
                  >
                    <option value="1">Consumidor Final</option>
                    <option value="2">Cedula</option>
                    <option value="3">RUC</option>
                    <option value="4">Pasaporte</option>
                  </select>
                </div>
              </div>
              {showConsumidor && (
                <div>
                  <label htmlFor="name" className="label">
                    {Labels.NAME_FAC_SERVICIO}
                  </label>
                  <div className="mt-1">
                    <input
                      ref={register({ maxLength: 100 })}
                      name="name"
                      maxLength={100}
                      type="text"
                      className="input"
                    />
                  </div>
                </div>)}
              {showConsumidor && (
                <div>
                  <label htmlFor="lastName" className="label">
                    {Labels.APELLIDO_FAC_SERVICIO}
                  </label>
                  <div className="mt-1">
                    <input
                      ref={register({ maxLength: 100 })}
                      name="lastName"
                      maxLength={100}
                      type="text"
                      className="input"
                    />
                  </div>
                </div>)}
              {showConsumidor && (
                <div>
                  <label htmlFor="direccion" className="label">
                    {Labels.DIR_FAC_SERVICIO}
                  </label>
                  <div className="mt-1">
                    <input
                      ref={register({ maxLength: 150 })}
                      name="direccion"
                      maxLength={150}
                      type="text"
                      className="input"
                    />
                  </div>
                </div>)}
              {showConsumidor && (
                <div>
                  <label htmlFor="identificationNumber" className="label">
                    {Labels.CI_FAC_SERVICIO}
                  </label>
                  <div className="mt-1">
                    <input
                      ref={register({ maxLength: 20, minLength: 10, pattern: /^[0-9]*$/ })}
                      name="identificationNumber"
                      type="tel"
                      pattern="[0-9]*"
                      minLength={10}
                      maxLength={20}
                      className="input"
                      onKeyDown={(e) => checkLength(e)}
                      onKeyUp={removeSpecialCharacters}
                    />
                  </div>
                </div>)}
              {(!loading && !primalLoading) &&
                (<Button
                  canClick={true}
                  loading={loading || primalLoading}
                  actionText={Labels.BUY}
                />)
              }
              {(loading || primalLoading) && (<LoaderButton />)}
              {error && (
                <FormError errorMessage={Exceptions[error.message as any]} />
              )}
            </form>
            {errorCON && (
              <FormError errorMessage={errorCON} />
            )}
          </div>
        )}

      </div>
    </div>
  );
};
