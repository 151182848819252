import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ApolloError, gql } from '@apollo/client/core';
import { useMutation } from '@apollo/client';
import { useMe } from '../../hooks/use-me';
import { Labels } from '../../enums/labels.enum';
import { FormErrorMessages } from '../../enums/form-error-messages.enum';
import { FormError } from '../../components/form-error';
import { Exceptions } from '../../enums/exceptions.enum';
import { Button } from '../../components/button';
import 'moment/locale/es';
import { useCategories } from '../../hooks/use-categories';
import {
  createProductMutation,
  createProductMutationVariables,
} from '../../__api__/createProductMutation';
import { v4 as uuid } from 'uuid';
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/outline';
import { DataTypeEnum } from '../../enums/spanish.enum';
import { MTTRType } from '../../__api__/globalTypes';

export enum DataType {
  EMAIL = 'email',
  PASSWORD = 'password',
  NUMBER = 'number',
  TEXT = 'text',
  TEXT_NUMBER = 'textNumber',
}

interface Field {
  label: string;
  name: string;
  dataType: string;
}

const CREATE_PRODUCT_MUTATION = gql`
  mutation createProductMutation($input: CreateProductInput!) {
    createProduct(input: $input)
  }
`;

interface ICreateProductProps {
  categoryId: string | null;
  onOk: () => void;
}

export const CreateProduct: React.FC<ICreateProductProps> = ({ categoryId, onOk }) => {
  const { data } = useMe();
  const { data: categories } = useCategories();
  const [testMTTRID, setTestMTTRID] = useState('');
  const [testMTTRType, setTestMTTRType] = useState(MTTRType.DEFAULT);
  const [formMTTRElements, setformMTTRElements] = useState<any[]>([]);

  const [formElements, setFormElements] = useState<any[]>([]);
  const [errorForm, setErrorForm] = useState<string | null>(null);

  interface RequiredInfoFieldProps {
    keyProp: string;
    tag: string;
  }

  const RequiredInfoField: React.FC<RequiredInfoFieldProps> = ({ keyProp, tag }) => {
    const labelId = `${tag}label-${uuid()}`;
    const nameId = `${tag}name-${uuid()}`;
    const dataTypeId = `${tag}dataType-${uuid()}`;
    return (
      <div className="px-3 py-3 border-2 border-r-2 rounded-2xl" key={keyProp}>
        <div
          onClick={() => {
            setFormElements((oldElements) =>
              oldElements.filter(
                (element) => element.props.keyProp !== keyProp,
              ),
            );
          }}
          className="flex justify-end"
        >
          <TrashIcon
            className="w-6 h-6 mr-3 cursor-pointer hover:text-blue-700"
            aria-hidden="true"
          />
        </div>
        <div className="mb-4" key={labelId}>
          <label htmlFor={labelId} className="label">
            Etiqueta
          </label>
          <div className="mt-1">
            <input
              ref={register({
                required: FormErrorMessages.REQUIRED_FIELD,
              })}
              name={labelId}
              type="text"
              className="input"
            />
            {errors[labelId]?.message && (
              <FormError errorMessage={errors[labelId]?.message} />
            )}
          </div>
        </div>
        <div className="mb-4" key={nameId}>
          <label htmlFor={nameId} className="label">
            ID del formulario
          </label>
          <div className="mt-1">
            <input
              ref={register({
                required: FormErrorMessages.REQUIRED_FIELD,
              })}
              name={nameId}
              type="text"
              className="input"
            />
            {errors[nameId]?.message && (
              <FormError errorMessage={errors[nameId]?.message} />
            )}
          </div>
        </div>
        <div className="mb-2" key={dataTypeId}>
          <label htmlFor={dataTypeId} className="label">
            Tipo de dato
          </label>
          <div className="mt-1">
            <select
              name={dataTypeId}
              ref={register({ required: true })}
              className="select"
            >
              <option value="">{Labels.SELECTED_ONE}</option>
              {Object.keys(DataType).map((dataType) => (
                <option key={dataType} value={dataType}>
                  {DataTypeEnum[dataType as any]}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  };

  const {
    register,
    getValues,
    errors,
    handleSubmit,
    formState: { isValid },
    setValue
  } = useForm({
    mode: 'onChange',
  });

  const onCompleted = (data: createProductMutation) => {
    const { createProduct: productId } = data;
    if (productId) {
      onOk();
    }
  };

  const onError = (error: ApolloError) => {
    if (error) {
      setErrorForm(Exceptions[error.message as any]);
    }
  };

  const [createProductMutation, { loading }] = useMutation<
    createProductMutation,
    createProductMutationVariables
  >(CREATE_PRODUCT_MUTATION, {
    onCompleted,
    onError,
  });

  useEffect(() => {
    setValue("categoryId", categoryId);
  }, [])
  const onSubmit = async () => {
    if (!loading) {
      try {
        const { ...input } = getValues();
        const fields: Field[] = [];
        let label: string | null, name: string | null, dataType: string | null;
        Object.keys(input).forEach((key: string) => {
          if (key.startsWith('defaultlabel')) {
            label = input[key];
          }
          if (key.startsWith('defaultname')) {
            name = input[key].toLowerCase();
          }
          if (key.startsWith('defaultdataType')) {
            dataType = input[key];
          }
          if (label && name && dataType) {
            fields.push({ label, name, dataType: dataType.toLowerCase() });
            name = null;
            label = null;
            dataType = null;
          }
        });
        const requiredInfo = { fields };
        let mttrReqInfo: any | null = null;
        if (input.mttrID != "") {
          const mttrFields: Field[] = [];
          Object.keys(input).forEach((key: string) => {
            if (key.startsWith('mttrlabel')) {
              label = input[key];
            }
            if (key.startsWith('mttrname')) {
              name = input[key].toLowerCase();
            }
            if (key.startsWith('mttrdataType')) {
              dataType = input[key];
            }
            if (label && name && dataType) {
              mttrFields.push({ label, name, dataType: dataType.toLowerCase() });
              name = null;
              label = null;
              dataType = null;
            }
          });
          mttrReqInfo = { fields: mttrFields };
        }
        await createProductMutation({
          variables: {
            input: {
              categoryId: input.categoryId,
              name: input.name,
              description: input.description,
              productionCost: Number(input.productionCost),
              saleCost: Number(input.saleCost),
              apiCost: Number(input.saleCostAPI),
              pvp: Number(input.pvp),
              isAcceptDiscounts: input.isAcceptDiscounts || false,
              isImmediateResponse: input.isImmediateResponse || false,
              apiEnabled: input.isAPIEnabled || false,
              requiredInfo,
              suggested: input.IsSuggested || false,
              userId: data?.me.id || '',
              mttrID: input.mttrID == "" ? undefined : input.mttrID,
              mttrReqInfo: input.mttrID == "" ? undefined : mttrReqInfo,
              mttrType: input.mttrID == "" ? undefined : testMTTRType,
            },
          },
        });
      } catch (error) { }
    }
  };

  const categoryOptions = useMemo(() => categories?.categories || [], [
    categories,
  ]);

  const key: string = uuid();

  return (
    <div className="flex flex-col justify-center px-2 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label htmlFor="categoryId" className="label">
                Categoría
              </label>
              <div className="mt-1">
                <select
                  name="categoryId"
                  ref={register({ required: true })}
                  className="select"
                >
                  <option value="">{Labels.SELECTED_ONE}</option>
                  {categoryOptions.map((category: any) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="name" className="label">
                Nombre
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_CATEGORY_NAME,
                    minLength: 3,
                    maxLength: 100,
                    validate: {
                      isValidCharacters: (name) =>
                        /^[áéíóúÁÉÍÓÚñÑa+-zA-Z0-9 ]*$/.test(name) ||
                        FormErrorMessages.CHARACTERS,
                    },
                  })}
                  name="name"
                  minLength={3}
                  maxLength={100}
                  type="text"
                  className="input"
                />
                {errors.name?.message && (
                  <FormError errorMessage={errors.name?.message} />
                )}
                {(errors.name?.type === 'minLength' ||
                  errors.name?.type === 'maxLength') && (
                    <FormError errorMessage={FormErrorMessages.LENGTH} />
                  )}
              </div>
            </div>
            <div>
              <label htmlFor="description" className="label">
                Descripción
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_DESCRIPTION,
                    minLength: 3,
                    maxLength: 500,
                    validate: {
                      isValidCharacters: (description) =>
                        /^[áéíóúÁÉÍÓÚñÑa+-zA-Z0-9 ]*$/.test(description) ||
                        FormErrorMessages.CHARACTERS,
                    },
                  })}
                  name="description"
                  minLength={3}
                  maxLength={500}
                  type="text"
                  className="input"
                />
                {errors.description?.message && (
                  <FormError errorMessage={errors.description?.message} />
                )}
                {(errors.description?.type === 'minLength' ||
                  errors.description?.type === 'maxLength') && (
                    <FormError errorMessage={FormErrorMessages.LENGTH} />
                  )}
              </div>
            </div>
            <div>
              <label htmlFor="productionCost" className="label">
                Costo de producción
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    min: 0,
                  })}
                  name="productionCost"
                  min={0}
                  type="number"
                  step=".01"
                  className="input"
                />
                {errors.productionCost?.message && (
                  <FormError errorMessage={errors.productionCost?.message} />
                )}
                {errors.productionCost?.type === 'min' && (
                  <FormError errorMessage={FormErrorMessages.MIN_VALUE} />
                )}
              </div>
            </div>
            <div>
              <label htmlFor="pvp" className="label">
                Precio de venta al público
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    min: 0,
                  })}
                  name="pvp"
                  min={0}
                  type="number"
                  step=".01"
                  className="input"
                />
                {errors.pvp?.message && (
                  <FormError errorMessage={errors.pvp?.message} />
                )}
                {errors.pvp?.type === 'min' && (
                  <FormError errorMessage={FormErrorMessages.MIN_VALUE} />
                )}
              </div>
            </div>
            <div>
              <label htmlFor="saleCost" className="label">
                Costo de venta vendedor
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    min: 0,
                  })}
                  name="saleCost"
                  min={0}
                  type="number"
                  step=".01"
                  className="input"
                />
                {errors.saleCost?.message && (
                  <FormError errorMessage={errors.saleCost?.message} />
                )}
                {errors.saleCost?.type === 'min' && (
                  <FormError errorMessage={FormErrorMessages.MIN_VALUE} />
                )}
              </div>
            </div>
            <div>
              <label htmlFor="saleCostAPI" className="label">
                Costo de venta para API
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    min: 0,
                  })}
                  name="saleCostAPI"
                  min={0}
                  type="number"
                  step=".01"
                  className="input"
                />
                {errors.saleCostAPI?.message && (
                  <FormError errorMessage={errors.saleCostAPI?.message} />
                )}
                {errors.saleCostAPI?.type === 'min' && (
                  <FormError errorMessage={FormErrorMessages.MIN_VALUE} />
                )}
              </div>
            </div>
            <div className="flex -align-center">
              <label htmlFor="isAPIEnabled" className="mt-1 label">
                Se encuentra habilitado en el API?
              </label>
              <div className="mt-1">
                <input
                  ref={register()}
                  name="isAPIEnabled"
                  type="checkbox"
                  className="w-4 h-4 ml-2 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                />
              </div>
            </div>
            <div className="flex -align-center">
              <label htmlFor="isAcceptDiscounts" className="mt-1 label">
                Acepta descuentos?
              </label>
              <div className="mt-1">
                <input
                  ref={register()}
                  name="isAcceptDiscounts"
                  type="checkbox"
                  className="w-4 h-4 ml-2 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                />
              </div>
            </div>
            <div className="flex -align-center">
              <label htmlFor="isImmediateResponse" className="mt-1 label">
                Tiene respuesta inmediata?
              </label>
              <div className="mt-1">
                <input
                  ref={register()}
                  name="isImmediateResponse"
                  type="checkbox"
                  className="w-4 h-4 ml-2 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                />
              </div>
            </div>
            <div className="flex -align-center">
              <label htmlFor="IsSuggested" className="mt-1 label">
                Es Sugerido a los Clientes?
              </label>
              <div className="mt-1">
                <input
                  defaultChecked={false}
                  ref={register()}
                  name="IsSuggested"
                  type="checkbox"
                  className="w-4 h-4 ml-2 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                />
              </div>
            </div>

            <div>
              <label htmlFor="mttrID" className="label">
                Codigo MTTR
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    minLength: 0,
                    maxLength: 100,
                    validate: {
                      isValidCharacters: (name) =>
                        (/^[áéíóúÁÉÍÓÚñÑa+-zA-Z0-9 ]*$/.test(name) || name == "") ||
                        FormErrorMessages.CHARACTERS,
                    },
                  })}
                  name="mttrID"
                  minLength={0}
                  maxLength={100}
                  type="text"
                  className="input"
                  onChange={e => setTestMTTRID(e.target.value)}
                />
                {errors.mttrID?.message && (
                  <FormError errorMessage={errors.mttrID?.message} />
                )}
                {(errors.mttrID?.type === 'minLength' ||
                  errors.mttrID?.type === 'maxLength') && (
                    <FormError errorMessage={FormErrorMessages.LENGTH} />
                  )}
              </div>
            </div>
            {testMTTRID != "" && (
              <div>
                <label htmlFor="mttrType" className="label">
                  Tipo de MTTR
                </label>
                <div className="mt-1">
                  <select
                    name="mttrType"
                    ref={register({ required: true })}
                    className="input"
                    onChange={e => setTestMTTRType(e.target.value as MTTRType)}
                    defaultValue={MTTRType.DEFAULT}
                  >
                    <option value="">{Labels.SELECTED_ONE}</option>
                    {Object.keys(MTTRType).map((key) => (
                      <option value={MTTRType[key]}>{key}</option>
                    ))}

                  </select>
                </div>
              </div>
            )}
            {(testMTTRID == "" || (testMTTRID != "" && testMTTRType == MTTRType.DEFAULT)) && (
              <div>
                <h3 className="mb-1 font-semibold">
                  Campos dinámicos del producto
                </h3>
                <div
                  onClick={() =>
                    setFormElements((oldElements) => [
                      ...oldElements,
                      <RequiredInfoField keyProp={key} tag="default" />,
                    ])
                  }
                  className="flex text-gray-700 cursor-pointer text-l group"
                >
                  <span className="mr-2 text-gray-700 group-hover:text-blue-700">
                    Agregar campo dinámico
                  </span>
                  <PlusCircleIcon
                    className="w-6 h-6 mr-3 group-hover:text-blue-700"
                    aria-hidden="true"
                  />
                </div>
                {formElements &&
                  formElements.map((element, index) => (
                    <div key={index}>{element}</div>
                  ))
                }
              </div>

            )}
            {(testMTTRID != "" && testMTTRType == MTTRType.DEFAULT) && (
              <div>
                <h3 className="mb-1 font-semibold">
                  Campos dinámicos para topup de MTTR (Repetir la llave de la respuesta de mttr para que se use en el topup)
                </h3>
                <div
                  onClick={() =>
                    setformMTTRElements((oldElements) => [
                      ...oldElements,
                      <RequiredInfoField keyProp={key} tag='mttr' />,
                    ])
                  }
                  className="flex text-gray-700 cursor-pointer text-l group"
                >
                  <span className="mr-2 text-gray-700 group-hover:text-blue-700">
                    Agregar campo dinámico
                  </span>
                  <PlusCircleIcon
                    className="w-6 h-6 mr-3 group-hover:text-blue-700"
                    aria-hidden="true"
                  />
                </div>
                {formMTTRElements &&
                  formMTTRElements.map((element, index) => (
                    <div key={index}>{element}</div>
                  ))}
              </div>)}
            <Button
              canClick={isValid}
              loading={loading}
              actionText={Labels.CREATE}
            />
            <div className="mb-2" />
            {errorForm && <FormError errorMessage={errorForm} />}
          </form>
        </div>
      </div>
    </div>
  );
};
