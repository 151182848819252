import React from 'react';
import { useLocation,useHistory  } from "react-router-dom"
import { Titles } from '../../enums/titles.enum';
import { Helmet } from 'react-helmet-async';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { notaVenta } from '../../documents/notaventa';
import { notaVentaPagatodito } from '../../documents/notaventapagatodito';

import {DocumentDownloadIcon} from '@heroicons/react/outline';
import { Labels } from '../../enums/labels.enum';
import { BrowserView } from 'react-device-detect';
import { purchases_orders_nodes_PurchaseOrder } from '../../__api__/purchases';


export const DownloadNotaVenta = () => {
  const location = useLocation<any>();
  const item : purchases_orders_nodes_PurchaseOrder = location.state.purchaseNV;
  const item2 = location.state.purchasePagatodito;
  const history = useHistory();
  let mydoc : any = null;
  if((item == null || item == undefined) && (item2 == null || item2 == undefined))
  {
    return (
      <div>
        <Helmet>
          <title>{Titles.DOWNLOAD}</title>
        </Helmet>
        <div>
          <div className="px-4 py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div>
              <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-3">
              Nada que mostrar
              </dl>
            </div>
          </div>
        </div>
      </div>
    );
  }
  else if(item2 == null || item2 == undefined)
  {
    mydoc = notaVenta(item.id,item.createdAt,item.product.categoryName,(item.alternateName?item.alternateName: item.product.name),(item.alternateDesc?item.alternateDesc:item.product.description),item.status,item.precioVenta,item.detail,item.result,(item.customer.name+" "+item.customer.lastName),item.factNames,item.facLastnames,item.facAddress,item.facIDType,item.facID);
  }
  else
  {
    mydoc = notaVentaPagatodito(item2);
    
  }
  return (
    <div>
      <Helmet>
        <title>{Titles.DOWNLOAD}</title>
      </Helmet>
      <div className="py-6">
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-900 leading-6">
              {Labels.DOWNLOAD_NOTAVENTA}
            </h3>
            <div className="flex items-center justify-between mb-4">
              <PDFDownloadLink document={mydoc} fileName={"factura.pdf"}>
                {({loading }) =>
                  loading ? 'Cargando...' : <DocumentDownloadIcon
                    className="w-6 h-6 mr-3 text-gray-400 cursor-pointer hover:text-blue-400"
                    aria-hidden="true"
                  />
                }
              </PDFDownloadLink>
              <button
                type="button"
                onClick={() => {
                  history.goBack();
                }}
                className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent shadow-sm leading-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <div className="flex items-center justify-between">
                  <span className="pr-2">Regresar</span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <BrowserView>
        <div>
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <PDFViewer children={mydoc} style={{height: "50vh"}} className="w-full"/>
          </div>
        </div>
      </BrowserView>
    </div>
  );
};
