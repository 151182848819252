import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  makeVar,
  split,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { LOCALSTORAGE_USER_ID } from './constants';
import { getMainDefinition } from '@apollo/client/utilities';

const URL = process.env.REACT_APP_URL_MAIN == undefined ? "" : process.env.REACT_APP_URL_MAIN;
const URL_WS = process.env.REACT_APP_URL_WS == undefined ? "" : process.env.REACT_APP_URL_WS;

const params = {
  uri: URL == undefined ? "" : URL,
  credentials: 'include',
};
const wsLink = new WebSocketLink({
  uri: URL_WS == undefined ? "" : URL_WS,
  options: {
    connectionParams: {
      credentials: 'include',
    },
    lazy: true,
    reconnect: true,
  },
});
const uploadLink = createUploadLink(params);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  uploadLink,
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      );
      if (message === 'Unauthorized') {
        localStorage.setItem(LOCALSTORAGE_USER_ID, '');
        authUserId(null);
        isLoggedInVar(false);
        window.location.reload();
      }
    });
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError.message}`);
  }
});

const storageUserId = localStorage.getItem(LOCALSTORAGE_USER_ID);
export const isLoggedInVar = makeVar(Boolean(storageUserId));
export const authUserId = makeVar(storageUserId);

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, splitLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          isLoggedIn: {
            read() {
              return isLoggedInVar();
            },
          },
          userId: {
            read() {
              return authUserId();
            },
          },
        },
      },
    },
  }),
});
