import { gql } from '@apollo/client/core';
import { useQuery, useReactiveVar } from '@apollo/client';
import { authUserId } from '../apollo';
import { meQuery, meQueryVariables } from '../__api__/meQuery';

const ME_QUERY = gql`
  query meQuery($input: UserIdInput!) {
    me(input: $input) {
      id
      email
      role
      customer {
        id
        name
        lastName
        cellPhone
        identificationType
        identificationNumber
        balance
        totalPurchases
        totalIncome
        monthlyIncome
        provinceId
        cityId
      }
    }
  }
`;

export const useMe = () => {
  const userId = useReactiveVar(authUserId) || '';
  return useQuery<meQuery, meQueryVariables>(ME_QUERY, {
    variables: {
      input: {
        userId,
      },
    },
  });
};
