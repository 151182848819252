import {
  EyeIcon,
  EyeOffIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import React, { useRef, useState, useMemo, useEffect } from 'react';
import { Labels } from '../../enums/labels.enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Modal as AModal, Table, Tag, Tooltip } from 'antd';
import { ApolloError, gql } from '@apollo/client/core';
import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import {
  createCategoryMutation,
  createCategoryMutationVariables,
} from '../../__api__/createCategoryMutation';
import { useMe } from '../../hooks/use-me';
import { FormErrorMessages } from '../../enums/form-error-messages.enum';
import { FormError } from '../../components/form-error';
import { Button } from '../../components/button';
import { Exceptions } from '../../enums/exceptions.enum';
import { Modal } from '../../components/modal';
import {
  deleteCategoryMutation,
  deleteCategoryMutationVariables,
} from '../../__api__/deleteCategoryMutation';
import {
  updateCategoryMutation,
  updateCategoryMutationVariables,
} from '../../__api__/updateCategoryMutation';
import {
  fetchCategories,
  fetchCategories_categories,
} from '../../__api__/fetchCategories';
import {
  SkeletonTable,
  SkeletonTableColumnsType,
} from '../../components/skeleton-table';
import { CategoryStatus } from '../../__api__/globalTypes';
import { CategoryStatusSpanish, APIStatusSpanish } from '../../enums/spanish.enum';
import { useFamilies } from '../../hooks/use-families';
import { normalizeText } from '../../utils/normalize-text-utils';


const CATEGORIES = gql`
  query fetchCategories {
    categories {
      id
      imageURL
      name
      status
      apiEnabled
      family{
        id
      }
      index
    }
  }
`;

const CREATE_CATEGORY_MUTATION = gql`
  mutation createCategoryMutation($input: CreateCategoryInput!) {
    createCategory(input: $input)
  }
`;

const UPDATE_CATEGORY_MUTATION = gql`
  mutation updateCategoryMutation($input: UpdateCategoryInput!) {
    updateCategory(input: $input)
  }
`;

const DELETE_CATEGORY_MUTATION = gql`
  mutation deleteCategoryMutation($input: DeleteCategoryInput!) {
    deleteCategory(input: $input)
  }
`;

const success = (content: string) => {
  AModal.success({
    content,
  });
};

interface CreateCategoryForm {
  name: string;
  file: any;
  apiEnabled: boolean;
  selectFamily: number;
  index: number;
}

interface CreateCategoryProps {
  onOk: () => void;
}

const CreateCategory: React.FC<CreateCategoryProps> = ({ onOk }) => {
  const { data: user } = useMe();
  const { data: families } = useFamilies();

  const {
    register,
    getValues,
    errors,
    handleSubmit,
    formState: { isValid },
  } = useForm<CreateCategoryForm>({
    mode: 'onChange',
  });

  const onCompleted = (data: createCategoryMutation) => {
    const { createCategory: categoryId } = data;
    if (categoryId) {
      onOk();
    }
  };
  const [createCategoryMutation, { loading, error }] = useMutation<
    createCategoryMutation,
    createCategoryMutationVariables
  >(CREATE_CATEGORY_MUTATION, {
    onCompleted,
  });
  const onSubmit = async () => {
    if (!loading) {
      try {
        const { file, index, name, apiEnabled, selectFamily } = getValues();
        const upload = file[0];
        await createCategoryMutation({
          variables: {
            input: {
              name,
              userId: user?.me.id || '',
              upload,
              apiEnabled,
              familyId: Number(selectFamily),
              index: Number(index)
            },
          },
        });
      } catch (error) { }
    }
  };
  const fileReference = useRef<HTMLInputElement | null>(null);
  const spanReference = useRef<HTMLSpanElement>(null);
  const familyOptions = useMemo(() => families?.families || [], [
    families,
  ]);


  return (
    <div className="flex flex-col justify-center px-2 py-8 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">

            <div>
              <label htmlFor="index" className="label">
                {Labels.CATEGORY_INDEX}
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUEIRED_INDEX,
                  })}
                  name="index"
                  type="number"
                  className="input"
                />
                {errors.index?.message && (
                  <FormError errorMessage={errors.index?.message} />
                )}
              </div>
            </div>

            <div>
              <label htmlFor="name" className="label">
                {Labels.CATEGORY_NAME}
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_CATEGORY_NAME,
                    minLength: 3,
                    maxLength: 100,
                    validate: {
                      isValidCharacters: (name) =>
                        /^[áéíóúÁÉÍÓÚñÑa-zA-Z ]*$/.test(name) ||
                        FormErrorMessages.CHARACTERS,
                    },
                  })}
                  name="name"
                  minLength={3}
                  maxLength={100}
                  type="text"
                  className="input"
                />
                {errors.name?.message && (
                  <FormError errorMessage={errors.name?.message} />
                )}
                {(errors.name?.type === 'minLength' ||
                  errors.name?.type === 'maxLength') && (
                    <FormError errorMessage={FormErrorMessages.CATEGORY_LENGTH} />
                  )}
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {Labels.IMAGE}
              </label>
              <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-gray-300 border-dashed rounded-md">
                <div className="text-center space-y-1">
                  <svg
                    className="w-12 h-12 mx-auto text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="flex text-sm text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative font-medium text-indigo-600 bg-white cursor-pointer rounded-md hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                    >
                      <span
                        ref={spanReference}
                        onClick={() => fileReference?.current?.click()}
                        className="w-full py-1 font-medium text-center text-indigo-500 cursor-pointer hover:text-indigo-400 hover:underline"
                      >
                        {Labels.SELECT_IMAGE}
                      </span>
                    </label>
                  </div>
                  <p className="text-xs text-gray-500">PNG, JPG, GIF o PDF</p>
                </div>
              </div>
            </div>
            <div className="flex -align-center">
              <label htmlFor="apiEnabled" className="mt-1 label">
                Se encuentra habilitado en el API?
              </label>
              <div className="mt-1">
                <input
                  ref={register()}
                  name="apiEnabled"
                  type="checkbox"
                  className="w-4 h-4 ml-2 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                />
              </div>
            </div>
            <div>
              <label htmlFor="selectFamily" className="label">
                Familia
              </label>
              <div className="mt-1">
                <select
                  name="selectFamily"
                  ref={register({ required: true })}
                  className="input"
                >
                  <option value="">{Labels.SELECTED_ONE}</option>
                  {familyOptions.map((family: any) => (
                    <option key={family.id} value={family.id}>
                      {family.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <input
              type="file"
              name="file"
              accept="application/pdf, image/*"
              ref={(e) => {
                register(e, { required: true });
                fileReference.current = e;
              }}
              onChange={(e) =>
                spanReference && spanReference.current
                  ? (spanReference.current.innerText =
                    e?.target?.files?.[0].name || '')
                  : ''
              }
              className="hidden"
            />
            <Button
              canClick={isValid}
              loading={loading}
              actionText={Labels.CREATE}
            />
            {error && (
              <FormError errorMessage={Exceptions[error.message as any]} />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

interface UpdateCategoryForm {
  name?: string;
  apiEnabled?: boolean;
  file?: any;
  selectFamily: number;
  index: number;
}

interface UpdateCategoryProps {
  category: fetchCategories_categories | null;
  onOk: () => void;
}

const UpdateCategory: React.FC<UpdateCategoryProps> = ({ category, onOk }) => {
  const { data: user } = useMe();
  const { data: families } = useFamilies();

  const {
    register,
    getValues,
    errors,
    handleSubmit,
    formState: { isValid },
  } = useForm<UpdateCategoryForm>({
    mode: 'onChange',
    defaultValues: {
      name: category?.name,
      apiEnabled: category?.apiEnabled,
      selectFamily: category?.family.id,
      index: category?.index ?? 1
    },
  });

  const onCompleted = (data: updateCategoryMutation) => {
    const { updateCategory: categoryId } = data;
    if (categoryId) {
      onOk();
    }
  };
  const [updateCategoryMutation, { loading, error }] = useMutation<
    updateCategoryMutation,
    updateCategoryMutationVariables
  >(UPDATE_CATEGORY_MUTATION, {
    onCompleted,
  });
  const onSubmit = async () => {
    if (!loading) {
      try {
        const { file, index, name, apiEnabled, selectFamily } = getValues();
        const upload = file ? file[0] : null;
        await updateCategoryMutation({
          variables: {
            input: {
              id: category?.id || '',
              userId: user?.me.id || '',
              name,
              upload,
              apiEnabled,
              familyId: Number(selectFamily),
              index: Number(index),
            },
          },
        });
      } catch (error) { }
    }
  };
  const spanReference = useRef<HTMLSpanElement>(null);
  const fileReference = useRef<HTMLInputElement | null>(null);
  const familyOptions = useMemo(() => families?.families || [], [
    families,
  ]);

  return (
    <div className="flex flex-col justify-center px-2 py-8 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
          <div className="flex items-center justify-center block w-full h-full mb-8 rounded-lg">
            <img
              src={category?.imageURL}
              alt={category?.id}
              className="object-cover"
            />
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">

            <div>
              <label htmlFor="index" className="label">
                {Labels.CATEGORY_INDEX}
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUEIRED_INDEX,
                  })}
                  name="index"
                  type="number"
                  className="input"
                />
                {errors.index?.message && (
                  <FormError errorMessage={errors.index?.message} />
                )}
              </div>
            </div>

            <div>
              <label htmlFor="name" className="label">
                {Labels.CATEGORY_NAME}
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_CATEGORY_NAME,
                    minLength: 3,
                    maxLength: 100,
                    validate: {
                      isValidCharacters: (name) =>
                        /^[áéíóúÁÉÍÓÚñÑa+-zA-Z ]*$/.test(name) ||
                        FormErrorMessages.CHARACTERS,
                    },
                  })}
                  name="name"
                  minLength={3}
                  maxLength={100}
                  type="text"
                  className="input"
                />
                {errors.name?.message && (
                  <FormError errorMessage={errors.name?.message} />
                )}
                {(errors.name?.type === 'minLength' ||
                  errors.name?.type === 'maxLength') && (
                    <FormError errorMessage={FormErrorMessages.CATEGORY_LENGTH} />
                  )}
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Actualizar imagen
              </label>
              <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-gray-300 border-dashed rounded-md">
                <div className="text-center space-y-1">
                  <svg
                    className="w-12 h-12 mx-auto text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="flex text-sm text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative font-medium text-indigo-600 bg-white cursor-pointer rounded-md hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                    >
                      <span
                        ref={spanReference}
                        onClick={() => fileReference?.current?.click()}
                        className="w-full py-1 font-medium text-center text-indigo-500 cursor-pointer hover:text-indigo-400 hover:underline"
                      >
                        {Labels.SELECT_IMAGE}
                      </span>
                    </label>
                  </div>
                  <p className="text-xs text-gray-500">PNG, JPG, GIF o PDF</p>
                </div>
              </div>
            </div>
            <div className="flex -align-center">
              <label htmlFor="apiEnabled" className="mt-1 label">
                Se encuentra habilitado en el API?
              </label>
              <div className="mt-1">
                <input
                  ref={register()}
                  name="apiEnabled"
                  type="checkbox"
                  className="w-4 h-4 ml-2 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                />
              </div>
            </div>
            <div>
              <label htmlFor="selectFamily" className="label">
                Familia
              </label>
              <div className="mt-1">
                <select
                  name="selectFamily"
                  ref={register({ required: true })}
                  className="input"
                >
                  <option value="">{Labels.SELECTED_ONE}</option>
                  {familyOptions.map((family: any) => (
                    <option key={family.id} value={family.id}>
                      {family.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <input
              type="file"
              name="file"
              accept="application/pdf, image/*"
              ref={(e) => {
                register(e);
                fileReference.current = e;
              }}
              onChange={(e) =>
                spanReference && spanReference.current
                  ? (spanReference.current.innerText =
                    e?.target?.files?.[0].name || '')
                  : ''
              }
              className="hidden"
            />
            <Button
              canClick={isValid}
              loading={loading}
              actionText={Labels.UPDATE}
            />
            {error && (
              <FormError errorMessage={Exceptions[error.message as any]} />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export const ProductCategory = () => {
  const { data: user } = useMe();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [category, setCategory] = useState<fetchCategories_categories | null>(
    null,
  );
  const [filterCategory, setFilterCategory] = useState<string>();
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [isModalUpdateVisible, setIsModalUpdateVisible] = useState(false);
  const [isModalEnableVisible, setIsModalEnableVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onCompleted = (data: deleteCategoryMutation) => {
    const { deleteCategory: categoryId } = data;
    if (categoryId) {
      setIsModalDeleteVisible(false);
      success('Categoría eliminada satisfactoriamente');
    }
    setErrorMessage(null);
  };

  const onError = (error: ApolloError) => {
    if (error) {
      setErrorMessage(error.message);
    }
  };

  const [deleteCategoryMutation, { loading: loadingDelete }] = useMutation<
    deleteCategoryMutation,
    deleteCategoryMutationVariables
  >(DELETE_CATEGORY_MUTATION, {
    onCompleted,
    onError,
  });

  const deleteCategory = async (categoryId?: string) => {
    if (!loadingDelete) {
      try {
        await deleteCategoryMutation({
          variables: {
            input: {
              id: categoryId || '',
              userId: user?.me.id || '',
            },
          },
        });
      } catch (error) { }
    }
  };

  const onCompletedEnable = async (data: updateCategoryMutation) => {
    const { updateCategory: categoryId } = data;
    if (categoryId) {
      setErrorMessage(null);
      setIsModalEnableVisible(false);
      success('Se actualizó la categoría satisfactoriamente');
      await refetch();
    }
  };
  const [updateCategoryMutation, { loading: loadingEnable }] = useMutation<
    updateCategoryMutation,
    updateCategoryMutationVariables
  >(UPDATE_CATEGORY_MUTATION, {
    onCompleted: onCompletedEnable,
  });

  const enableOrDisableCategory = async (
    categoryId: string | undefined,
    isEnabled: boolean,
  ) => {
    if (!loadingEnable) {
      try {
        await updateCategoryMutation({
          variables: {
            input: {
              id: category?.id || '',
              userId: user?.me.id || '',
              status: isEnabled ? CategoryStatus.ACTIVE : CategoryStatus.INACTIVE,
              familyId: category?.family.id || 1,
              index: category?.index || 1
            },
          },
        });
      } catch (error) { }
    }
  };

  const { data, loading, refetch } = useQuery<fetchCategories>(CATEGORIES, {
    fetchPolicy: 'network-only',
  });

  const handleChangeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterCategory(e.target.value);
  }

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Imagen',
      dataIndex: 'image',
      key: 'image',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'API Habilitado',
      dataIndex: 'enabledAPI',
      key: 'enabledAPI',
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const filterCategoriesByName = (categories: any[], searchTerm: string) => {
    const normalizedSearchTerm = normalizeText(searchTerm);

    return categories.filter(category =>
      normalizeText(category.name).includes(normalizedSearchTerm)
    );
  };
  const getDataSource = () => {
    if (filterCategory) {
      if (data) {
        const filteredCategories = filterCategoriesByName(data?.categories, filterCategory);
        return filteredCategories.map((item) => ({
          key: item.id,
          enabledAPI: (
            <Tag color={item.apiEnabled ? 'geekblue' : 'error'}>
              {item.apiEnabled ? APIStatusSpanish.ON : APIStatusSpanish.OFF}
            </Tag>
          ),
          image: (
            <img
              className="inline-block w-20 h-20 px-2 py-2 border-2 border-indigo-900"
              src={item.imageURL}
              alt={item.id}
            />
          ),
          name: item.name,
          status: (
            <Tag color={item.status === 'ACTIVE' ? 'geekblue' : 'error'}>
              {
                CategoryStatusSpanish[
                item.status ? item.status : CategoryStatus.ACTIVE
                ]
              }
            </Tag>
          ),
          action: (
            <div className="flex items-center justify-start">
              <div
                onClick={() => {
                  setCategory(item);
                  setIsModalEnableVisible(true);
                }}
                className="flex-shrink-0 mr-3"
              >
                <Tooltip title={item.status === 'ACTIVE' ? 'desactivar' : 'activar'}>
                  {item.status === 'ACTIVE' && (
                    <EyeOffIcon
                      className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                      aria-hidden="true"
                    />
                  )}
                  {item.status === 'INACTIVE' && (
                    <EyeIcon
                      className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                      aria-hidden="true"
                    />
                  )}
                </Tooltip>
              </div>
              <div
                onClick={() => {
                  setCategory(item);
                  setIsModalUpdateVisible(true);
                }}
                className="flex-shrink-0"
              >
                <Tooltip title="editar">
                  <PencilIcon
                    className="w-6 h-6 mr-3 text-gray-400 cursor-pointer hover:text-blue-400"
                    aria-hidden="true"
                  />
                </Tooltip>
              </div>
              <div
                onClick={() => {
                  setCategory(item);
                  setIsModalDeleteVisible(true);
                }}
                className="flex-shrink-0"
              >
                <Tooltip title="eliminar">
                  <TrashIcon
                    className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                    aria-hidden="true"
                  />
                </Tooltip>
              </div>
            </div>
          ),
        }))
      }

    } else {
      return data?.categories?.map((item) => ({
        key: item.id,
        enabledAPI: (
          <Tag color={item.apiEnabled ? 'geekblue' : 'error'}>
            {item.apiEnabled ? APIStatusSpanish.ON : APIStatusSpanish.OFF}
          </Tag>
        ),
        image: (
          <img
            className="inline-block w-20 h-20 px-2 py-2 border-2 border-indigo-900"
            src={item.imageURL}
            alt={item.id}
          />
        ),
        name: item.name,
        status: (
          <Tag color={item.status === 'ACTIVE' ? 'geekblue' : 'error'}>
            {
              CategoryStatusSpanish[
              item.status ? item.status : CategoryStatus.ACTIVE
              ]
            }
          </Tag>
        ),
        action: (
          <div className="flex items-center justify-start">
            <div
              onClick={() => {
                setCategory(item);
                setIsModalEnableVisible(true);
              }}
              className="flex-shrink-0 mr-3"
            >
              <Tooltip title={item.status === 'ACTIVE' ? 'desactivar' : 'activar'}>
                {item.status === 'ACTIVE' && (
                  <EyeOffIcon
                    className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                    aria-hidden="true"
                  />
                )}
                {item.status === 'INACTIVE' && (
                  <EyeIcon
                    className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                    aria-hidden="true"
                  />
                )}
              </Tooltip>
            </div>
            <div
              onClick={() => {
                setCategory(item);
                setIsModalUpdateVisible(true);
              }}
              className="flex-shrink-0"
            >
              <Tooltip title="editar">
                <PencilIcon
                  className="w-6 h-6 mr-3 text-gray-400 cursor-pointer hover:text-blue-400"
                  aria-hidden="true"
                />
              </Tooltip>
            </div>
            <div
              onClick={() => {
                setCategory(item);
                setIsModalDeleteVisible(true);
              }}
              className="flex-shrink-0"
            >
              <Tooltip title="eliminar">
                <TrashIcon
                  className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                  aria-hidden="true"
                />
              </Tooltip>
            </div>
          </div>
        ),
      }));
    }
  }
  const datasource = getDataSource();



  useEffect(() => {
    console.log("categorías", data?.categories)
  }, []);

  return (
    <div>
      <Modal
        title={`${category?.status === CategoryStatus.ACTIVE ? 'Desactivar' : 'Activar'
          } categoría ${category?.name}`}
        visible={isModalEnableVisible}
        onOk={async () => {
          await enableOrDisableCategory(
            category?.id,
            category?.status === CategoryStatus.INACTIVE,
          );
        }}
        onCancel={() => {
          setIsModalEnableVisible(false);
          setErrorMessage(null);
        }}
        footer={true}
        afterClose={async () => {
          setErrorMessage(null);
          await refetch();
        }}
        child={
          <div className="flex flex-col">
            <span className="mb-4">
              {`Estás seguro de ${category?.status === CategoryStatus.ACTIVE
                ? 'desactivar'
                : 'activar'
                } la categoría ${category?.name}?`}
            </span>
            {errorMessage && (
              <FormError errorMessage={Exceptions[errorMessage as any]} />
            )}
          </div>
        }
      />
      <Modal
        title={'Eliminar categoría'}
        visible={isModalDeleteVisible}
        onOk={async () => {
          await deleteCategory(category?.id);
        }}
        onCancel={() => {
          setIsModalDeleteVisible(false);
          setErrorMessage(null);
        }}
        footer={true}
        afterClose={async () => {
          setErrorMessage(null);
          await refetch();
        }}
        child={
          <div className="flex flex-col">
            <span className="mb-4">
              Estás seguro de eliminar la categoría{' '}
              <span className="font-semibold text-blue-400">
                {category?.name}
              </span>
              ?
            </span>
            {errorMessage && (
              <FormError errorMessage={Exceptions[errorMessage as any]} />
            )}
          </div>
        }
      />
      <Modal
        title={Labels.CREATE_CATEGORY}
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        afterClose={async () => {
          await refetch();
        }}
        child={
          <CreateCategory
            onOk={() => {
              setIsModalVisible(false);
              success('Categoría creada satisfactoriamente');
            }}
          />
        }
      />
      <Modal
        title={Labels.UPDATE_CATEGORY}
        visible={isModalUpdateVisible}
        onOk={() => setIsModalUpdateVisible(false)}
        onCancel={() => setIsModalUpdateVisible(false)}
        afterClose={async () => {
          await refetch();
        }}
        child={
          <UpdateCategory
            category={category}
            onOk={() => {
              setIsModalUpdateVisible(false);
              success('Categoría actualizada satisfactoriamente');
            }}
          />
        }
      />
      <div className="py-6">
        <div className="py-2 mx-auto px-1.5 max-w-7xl">
          <div className="flex items-center justify-between mb-4">
            <div className='flex items-center'>
              <h3 className="text-lg font-medium text-gray-900 leading-6 mr-1">
                {Labels.MENU_PRODUCT_CATEGORY}
              </h3>
              <div className="relative">
                <svg className="absolute left-2 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <circle cx="10" cy="10" r="7" stroke="currentColor" strokeWidth="2" />
                  <line x1="15" y1="15" x2="21" y2="21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                </svg>
                <input
                  className="w-40 h-8 pl-8 ml-2 text-gray-700 border-gray-300 rounded focus:ring-indigo-500"
                  type="text"
                  name="filter-category"
                  value={filterCategory}
                  onChange={handleChangeFilter}
                  placeholder={Labels.SEARCH}
                />
              </div>
            </div>
            <button
              type="button"
              onClick={() => {
                setIsModalVisible(true);
              }}
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent shadow-sm leading-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <div className="flex items-center justify-between">
                <span className="pr-2">{Labels.CREATE}</span>
                <FontAwesomeIcon icon={faPlus} />
              </div>
            </button>
          </div>
          <SkeletonTable
            active={true}
            loading={loading}
            columns={columns as SkeletonTableColumnsType[]}
          >
            <Table
              dataSource={datasource}
              columns={columns}
              pagination={false}
            />
          </SkeletonTable>
        </div>
      </div>
    </div>
  );
};
