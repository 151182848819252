import React, { useMemo, useState } from 'react';
import { ApolloError, gql } from '@apollo/client/core';
import { useCategories } from '../../hooks/use-categories';
import { useMe } from '../../hooks/use-me';
import { CommissionStatus } from '../../__api__/globalTypes';
import { useMutation, useQuery } from '@apollo/client';
import {
  distributorCommissionsQuery,
  distributorCommissionsQuery_distributorCommissions_nodes_Commission,
  distributorCommissionsQueryVariables,
} from '../../__api__/distributorCommissionsQuery';
import { CommissionStatusSpanish } from '../../enums/spanish.enum';
import { DatePicker, Modal as AModal, Table, Tag, Tooltip } from 'antd';
import {
  EyeIcon,
  EyeOffIcon,
  PencilIcon,
  TrashIcon,
} from '@heroicons/react/outline';
import { Labels } from '../../enums/labels.enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faPlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import {
  SkeletonTable,
  SkeletonTableColumnsType,
} from '../../components/skeleton-table';
import { distributorsQuery } from '../../__api__/distributorsQuery';
import { useForm } from 'react-hook-form';
import { FormErrorMessages } from '../../enums/form-error-messages.enum';
import { FormError } from '../../components/form-error';
import { Button } from '../../components/button';
import { Exceptions } from '../../enums/exceptions.enum';
import {
  createDistributorCommissionMutation,
  createDistributorCommissionMutationVariables,
} from '../../__api__/createDistributorCommissionMutation';
import { Modal } from '../../components/modal';
import {
  updateDistributorCommissionMutation,
  updateDistributorCommissionMutationVariables,
} from '../../__api__/updateDistributorCommissionMutation';
import {
  deleteDistributorCommissionMutation,
  deleteDistributorCommissionMutationVariables,
} from '../../__api__/deleteDistributorCommissionMutation';

const { RangePicker } = DatePicker;

const DISTRIBUTORS = gql`
  query distributorsQuery {
    distributors {
      id
      email
    }
  }
`;

const DISTRIBUTOR_COMMISSIONS = gql`
  query distributorCommissionsQuery($input: CommissionSearcherInput!) {
    distributorCommissions(input: $input) {
      meta {
        nodeCount
        pageCount
        pageCurrent
        nodesPerPage
      }
      nodes {
        __typename
        ... on Commission {
          id
          commissionTime
          commissionPercentage
          createdAt
          status
          category {
            id
            name
          }
          distributor {
            id
            email
          }
        }
      }
    }
  }
`;

const CREATE_DISTRIBUTOR_COMMISSION_MUTATION = gql`
  mutation createDistributorCommissionMutation(
    $input: CreateDistributorCommissionInput!
  ) {
    createDistributorCommission(input: $input)
  }
`;

const UPDATE_DISTRIBUTOR_COMMISSION_MUTATION = gql`
  mutation updateDistributorCommissionMutation(
    $input: UpdateDistributorCommissionInput!
  ) {
    updateDistributorCommission(input: $input)
  }
`;

const DELETE_DISTRIBUTOR_COMMISSION_MUTATION = gql`
  mutation deleteDistributorCommissionMutation(
    $input: DeleteDistributorCommissionInput!
  ) {
    deleteDistributorCommission(input: $input)
  }
`;

const success = (content: string) => {
  AModal.success({
    content,
  });
};

interface CreateDistributorCommissionForm {
  categoryId: string;
  distributorId: string;
  commissionTime: number;
  commissionPercentage: number;
}

interface CreateDistributorCommissionProps {
  onOk: () => void;
}

const CreateDistributorCommission: React.FC<CreateDistributorCommissionProps> = ({
  onOk,
}) => {
  const { data: user } = useMe();
  const { data: categories } = useCategories();
  const { data: distributors } = useQuery<distributorsQuery>(DISTRIBUTORS);
  const categoryOptions = useMemo(() => categories?.categories || [], [
    categories,
  ]);

  const {
    register,
    getValues,
    errors,
    handleSubmit,
    formState: { isValid },
  } = useForm<CreateDistributorCommissionForm>({
    mode: 'onChange',
  });

  const onCompleted = (data: createDistributorCommissionMutation) => {
    const { createDistributorCommission: distributorCommissionId } = data;
    if (distributorCommissionId) {
      onOk();
    }
  };
  const [createDistributorCommissionMutation, { loading, error }] = useMutation<
    createDistributorCommissionMutation,
    createDistributorCommissionMutationVariables
  >(CREATE_DISTRIBUTOR_COMMISSION_MUTATION, {
    onCompleted,
  });
  const onSubmit = async () => {
    if (!loading) {
      try {
        const { ...input } = getValues();
        await createDistributorCommissionMutation({
          variables: {
            input: {
              categoryId: input.categoryId,
              distributorId: input.distributorId,
              commissionPercentage: Number(input.commissionPercentage),
              commissionTime: Number(input.commissionTime),
              userId: user?.me.id || '',
            },
          },
        });
      } catch (error) { }
    }
  };

  return (
    <div className="flex flex-col justify-center px-2 py-8 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label htmlFor="categoryId" className="label">
                Categoría
              </label>
              <div className="mt-1">
                <select
                  name="categoryId"
                  ref={register({ required: true })}
                  className="select"
                >
                  <option value="">{Labels.SELECTED_ONE}</option>
                  {categoryOptions.map((category: any) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="distributorId" className="label">
                Distribuidor
              </label>
              <div className="mt-1">
                <select
                  name="distributorId"
                  ref={register({ required: true })}
                  className="select"
                >
                  <option value="">{Labels.SELECTED_ONE}</option>
                  {distributors?.distributors.map((distributor: any) => (
                    <option key={distributor.id} value={distributor.id}>
                      {distributor.email}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="commissionPercentage" className="label">
                Porcentaje de comisión
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    min: 0.1,
                  })}
                  name="commissionPercentage"
                  min={0.1}
                  type="number"
                  step=".01"
                  className="input"
                />
                {errors.commissionPercentage?.message && (
                  <FormError
                    errorMessage={errors.commissionPercentage?.message}
                  />
                )}
                {errors.commissionPercentage?.type === 'min' && (
                  <FormError
                    errorMessage={FormErrorMessages.DISCOUNT_RATE_MIN_VALUE}
                  />
                )}
              </div>
            </div>
            <div>
              <label htmlFor="commissionTime" className="label">
                Tiempo de comisión (meses)
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    min: 1,
                    pattern: {
                      value: /^[-+]?\d+$/,
                      message: 'Debe ser un número entero',
                    },
                  })}
                  name="commissionTime"
                  min={1}
                  step="1"
                  pattern="^[-/d]/d*$"
                  type="number"
                  className="input"
                />
                {errors.commissionTime?.message && (
                  <FormError errorMessage={errors.commissionTime?.message} />
                )}
                {errors.commissionTime?.type === 'min' && (
                  <FormError
                    errorMessage={FormErrorMessages.COMMISSION_TIME_MIN_VALUE}
                  />
                )}
              </div>
            </div>
            <Button
              canClick={isValid}
              loading={loading}
              actionText={Labels.CREATE}
            />
            {error && (
              <FormError errorMessage={Exceptions[error.message as any]} />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

interface UpdateDistributorCommissionForm {
  categoryId?: string;
  distributorId?: string;
  commissionTime?: number;
  commissionPercentage?: number;
}

interface UpdateDistributorCommissionProps {
  distributorCommission: distributorCommissionsQuery_distributorCommissions_nodes_Commission | null;
  onOk: () => void;
}

const UpdateDistributorCommission: React.FC<UpdateDistributorCommissionProps> = ({
  distributorCommission,
  onOk,
}) => {
  const { data: user } = useMe();
  const { data: categories } = useCategories();
  const { data: distributors } = useQuery<distributorsQuery>(DISTRIBUTORS);

  const categoryOptions = useMemo(() => categories?.categories || [], [
    categories,
  ]);

  const {
    register,
    getValues,
    errors,
    handleSubmit,
  } = useForm<UpdateDistributorCommissionForm>({
    mode: 'onChange',
    defaultValues: {
      categoryId: distributorCommission?.category.id,
      distributorId: distributorCommission?.distributor.id,
      commissionTime: distributorCommission?.commissionTime,
      commissionPercentage: distributorCommission?.commissionPercentage,
    },
  });

  const onCompleted = (data: updateDistributorCommissionMutation) => {
    const { updateDistributorCommission: distributorCommissionId } = data;
    if (distributorCommissionId) {
      onOk();
    }
  };
  const [updateDistributorCommissionMutation, { loading, error }] = useMutation<
    updateDistributorCommissionMutation,
    updateDistributorCommissionMutationVariables
  >(UPDATE_DISTRIBUTOR_COMMISSION_MUTATION, {
    onCompleted,
  });
  const onSubmit = async () => {
    if (!loading) {
      try {
        const { ...input } = getValues();
        await updateDistributorCommissionMutation({
          variables: {
            input: {
              categoryId: input.categoryId,
              distributorId: input.distributorId,
              commissionTime: Number(input.commissionTime),
              commissionPercentage: Number(input.commissionPercentage),
              id: distributorCommission?.id || '',
              userId: user?.me.id || '',
            },
          },
        });
      } catch (error) { }
    }
  };

  return (
    <div className="flex flex-col justify-center px-2 py-8 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label htmlFor="categoryId" className="label">
                Categoría
              </label>
              <div className="mt-1">
                <select
                  name="categoryId"
                  ref={register({ required: true })}
                  className="select"
                >
                  <option value="">{Labels.SELECTED_ONE}</option>
                  {categoryOptions.map((category: any) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="distributorId" className="label">
                Distribuidor
              </label>
              <div className="mt-1">
                <select
                  name="distributorId"
                  ref={register({ required: true })}
                  className="select"
                >
                  <option value="">{Labels.SELECTED_ONE}</option>
                  {distributors?.distributors.map((distributor: any) => (
                    <option key={distributor.id} value={distributor.id}>
                      {distributor.email}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="commissionPercentage" className="label">
                Porcentaje de comisión
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    min: 0.1,
                  })}
                  name="commissionPercentage"
                  min={0.1}
                  type="number"
                  step=".01"
                  className="input"
                />
                {errors.commissionPercentage?.message && (
                  <FormError
                    errorMessage={errors.commissionPercentage?.message}
                  />
                )}
                {errors.commissionPercentage?.type === 'min' && (
                  <FormError
                    errorMessage={FormErrorMessages.DISCOUNT_RATE_MIN_VALUE}
                  />
                )}
              </div>
            </div>
            <div>
              <label htmlFor="commissionTime" className="label">
                Tiempo de comisión (meses)
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_FIELD,
                    min: 1,
                    pattern: {
                      value: /^[-+]?\d+$/,
                      message: 'Debe ser un número entero',
                    },
                  })}
                  name="commissionTime"
                  min={1}
                  step="1"
                  pattern="^[-/d]/d*$"
                  type="number"
                  className="input"
                />
                {errors.commissionTime?.message && (
                  <FormError errorMessage={errors.commissionTime?.message} />
                )}
                {errors.commissionTime?.type === 'min' && (
                  <FormError
                    errorMessage={FormErrorMessages.COMMISSION_TIME_MIN_VALUE}
                  />
                )}
              </div>
            </div>
            <Button
              canClick={true}
              loading={loading}
              actionText={Labels.UPDATE}
            />
            {error && (
              <FormError errorMessage={Exceptions[error.message as any]} />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

const DEFAULT_PAGE_SIZE = 10;

export const Commissions = () => {
  const { data: categories } = useCategories();
  const { data: user } = useMe();
  const { data: distributors } = useQuery<distributorsQuery>(DISTRIBUTORS);

  const [pageCurrent, setPageCurrent] = useState(1);
  const [nodesPerPage, setNodesPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const [distributorId, setDistributorId] = useState<string | null>(null);
  const [dateTo, setDateTo] = useState<string | null>(null);
  const [status, setStatus] = useState<CommissionStatus | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalEnableVisible, setIsModalEnableVisible] = useState(false);
  const [isModalUpdateVisible, setIsModalUpdateVisible] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);

  const [
    distributorCommission,
    setDistributorCommission,
  ] = useState<distributorCommissionsQuery_distributorCommissions_nodes_Commission | null>(
    null,
  );

  const { data, loading, refetch } = useQuery<
    distributorCommissionsQuery,
    distributorCommissionsQueryVariables
  >(DISTRIBUTOR_COMMISSIONS, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        pageCurrent,
        nodesPerPage,
        where: {
          categoryId,
          distributorId,
          dateFrom,
          dateTo,
          status,
        },
      },
    },
  });

  const onChange = (values: any) => {
    if (!values) {
      setDateFrom(null);
      setDateTo(null);
      return;
    }

    // Establecer la fecha desde con hora a 00:00
    const dateFrom = values[0].startOf('day').format(); // 00:00

    // Establecer la fecha hasta con hora a 23:59
    const dateTo = values[1].endOf('day').format(); // 23:59

    setDateFrom(dateFrom);
    setDateTo(dateTo);
  };

  const onCompletedEnable = async (
    data: updateDistributorCommissionMutation,
  ) => {
    const { updateDistributorCommission: distributorCommissionId } = data;
    if (distributorCommissionId) {
      setErrorMessage(null);
      setIsModalEnableVisible(false);
      success('Se actualizó la comisión satisfactoriamente');
      await refetch();
    }
  };
  const [
    updateDistributorCommissionMutation,
    { loading: loadingEnable },
  ] = useMutation<
    updateDistributorCommissionMutation,
    updateDistributorCommissionMutationVariables
  >(UPDATE_DISTRIBUTOR_COMMISSION_MUTATION, {
    onCompleted: onCompletedEnable,
  });

  const enableOrDisableDistributorCommission = async (
    distributorCommissionId: string | undefined,
    isEnabled: boolean,
  ) => {
    if (!loadingEnable) {
      try {
        await updateDistributorCommissionMutation({
          variables: {
            input: {
              id: distributorCommissionId || '',
              userId: user?.me.id || '',
              status: isEnabled
                ? CommissionStatus.ACTIVE
                : CommissionStatus.INACTIVE,
            },
          },
        });
      } catch (error) { }
    }
  };

  const onCompleted = (data: deleteDistributorCommissionMutation) => {
    const { deleteDistributorCommission: distributorCommissionId } = data;
    if (distributorCommissionId) {
      setIsModalDeleteVisible(false);
      success('Comisión eliminada satisfactoriamente');
    }
    setErrorMessage(null);
  };

  const onError = (error: ApolloError) => {
    if (error) {
      setErrorMessage(error.message);
    }
  };

  const [
    deleteDistributorCommissionMutation,
    { loading: loadingDelete },
  ] = useMutation<
    deleteDistributorCommissionMutation,
    deleteDistributorCommissionMutationVariables
  >(DELETE_DISTRIBUTOR_COMMISSION_MUTATION, {
    onCompleted,
    onError,
  });

  const deleteDistributorCommission = async (
    distributorCommissionId?: string,
  ) => {
    if (!loadingDelete) {
      try {
        await deleteDistributorCommissionMutation({
          variables: {
            input: {
              id: distributorCommissionId || '',
              userId: user?.me.id || '',
            },
          },
        });
      } catch (error) { }
    }
  };

  const columns = [
    {
      title: 'Categoría',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Distribuidor',
      dataIndex: 'distributorEmail',
      key: 'distributorEmail',
    },
    {
      title: 'Porcentaje de comisión',
      dataIndex: 'commissionPercentage',
      key: 'commissionPercentage',
    },
    {
      title: 'Tiempo de comisión (meses)',
      dataIndex: 'commissionTime',
      key: 'commissionTime',
    },
    {
      title: 'Fecha de creación',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Acciones',
      dataIndex: 'action',
      key: 'action',
    },
  ];

  const distributorCommissions = data?.distributorCommissions.nodes as
    | distributorCommissionsQuery_distributorCommissions_nodes_Commission[]
    | null;

  const datasource = distributorCommissions?.map(
    (
      item: distributorCommissionsQuery_distributorCommissions_nodes_Commission,
    ) => ({
      key: item.id,
      category: item.category.name,
      distributorEmail: item.distributor.email,
      commissionPercentage: `${item.commissionPercentage}%`,
      commissionTime: item.commissionTime,
      createdAt: item.createdAt,
      status: (
        <Tag color={item.status === 'ACTIVE' ? 'geekblue' : 'error'}>
          {CommissionStatusSpanish[item.status]}
        </Tag>
      ),
      action: (
        <div className="flex items-center justify-start">
          <div
            onClick={() => {
              setDistributorCommission(item);
              setIsModalEnableVisible(true);
            }}
            className="flex-shrink-0 mr-3"
          >
            <Tooltip
              title={item.status === 'ACTIVE' ? 'desactivar' : 'activar'}
            >
              {item.status === 'ACTIVE' && (
                <EyeOffIcon
                  className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                  aria-hidden="true"
                />
              )}
              {item.status === 'INACTIVE' && (
                <EyeIcon
                  className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                  aria-hidden="true"
                />
              )}
            </Tooltip>
          </div>
          <div
            onClick={() => {
              setDistributorCommission(item);
              setIsModalUpdateVisible(true);
            }}
            className="flex-shrink-0"
          >
            <Tooltip title="editar">
              <PencilIcon
                className="w-6 h-6 mr-3 text-gray-400 cursor-pointer hover:text-blue-400"
                aria-hidden="true"
              />
            </Tooltip>
          </div>
          <div
            onClick={() => {
              setDistributorCommission(item);
              setIsModalDeleteVisible(true);
            }}
            className="flex-shrink-0"
          >
            <Tooltip title="eliminar">
              <TrashIcon
                className="w-6 h-6 text-gray-400 cursor-pointer hover:text-blue-400"
                aria-hidden="true"
              />
            </Tooltip>
          </div>
        </div>
      ),
    }),
  );

  return (
    <div>
      <Modal
        title={'Crear comisión'}
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        afterClose={async () => {
          await refetch();
        }}
        child={
          <CreateDistributorCommission
            onOk={() => {
              setIsModalVisible(false);
              success('Comisión creada satisfactoriamente');
            }}
          />
        }
      />
      <Modal
        title={'Actualizar comisión por distribuidor'}
        visible={isModalUpdateVisible}
        onOk={() => setIsModalUpdateVisible(false)}
        onCancel={() => setIsModalUpdateVisible(false)}
        afterClose={async () => {
          await refetch();
        }}
        child={
          <UpdateDistributorCommission
            distributorCommission={distributorCommission}
            onOk={() => {
              setIsModalUpdateVisible(false);
              success('Comisión actualizada satisfactoriamente');
            }}
          />
        }
      />
      <Modal
        title={`${distributorCommission?.status === CommissionStatus.ACTIVE
          ? 'Desactivar'
          : 'Activar'
          } comisión por distribuidor`}
        visible={isModalEnableVisible}
        onOk={async () => {
          await enableOrDisableDistributorCommission(
            distributorCommission?.id,
            distributorCommission?.status === CommissionStatus.INACTIVE,
          );
        }}
        onCancel={() => {
          setIsModalEnableVisible(false);
          setErrorMessage(null);
        }}
        footer={true}
        afterClose={async () => {
          setErrorMessage(null);
          await refetch();
        }}
        child={
          <div className="flex flex-col">
            <span className="mb-4">
              {`Estás seguro de ${distributorCommission?.status === CommissionStatus.ACTIVE
                ? 'desactivar'
                : 'activar'
                } la comisión por distribuidor?`}
            </span>
            {errorMessage && (
              <FormError errorMessage={Exceptions[errorMessage as any]} />
            )}
          </div>
        }
      />
      <Modal
        title={'Eliminar comisión por distribuidor'}
        visible={isModalDeleteVisible}
        onOk={async () => {
          await deleteDistributorCommission(distributorCommission?.id);
        }}
        onCancel={() => {
          setIsModalDeleteVisible(false);
          setErrorMessage(null);
        }}
        footer={true}
        afterClose={async () => {
          setErrorMessage(null);
          await refetch();
        }}
        child={
          <div className="flex flex-col">
            <span className="mb-4">Estás seguro de eliminar la comisión?</span>
            {errorMessage && (
              <FormError errorMessage={Exceptions[errorMessage as any]} />
            )}
          </div>
        }
      />
      <div className="py-2">
        <div className="py-2 mx-auto max-w-7xl">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-medium text-gray-900 leading-6">
              Comisiones
            </h3>
            <button
              type="button"
              onClick={() => {
                setIsModalVisible(true);
              }}
              className="inline-flex items-center px-3 py-2 mr-3 text-sm font-medium text-white bg-indigo-600 border border-transparent shadow-sm leading-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <div className="flex items-center justify-between">
                <span className="pr-2">{Labels.CREATE}</span>
                <FontAwesomeIcon icon={faPlus} />
              </div>
            </button>
          </div>
          <div className="mt-5 mb-5 md:mt-0 md:col-span-2">
            <form className="flex flex-col items-center w-full py-8 bg-white shadow sm:rounded-3xl sm:px-4">
              <div className="mb-4 mr-auto">
                {Labels.FILTERS}
                <FontAwesomeIcon icon={faFilter} className="ml-3 text-xl" />
              </div>
              <div className="max-w-xl grid grid-cols-1 gap-5">
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="first_name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">{Labels.FILTER_DATES}</span>
                  </label>
                  <RangePicker
                    placeholder={['Fecha desde', 'Fecha hasta']}
                    className="w-full rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={onChange}
                    disabledDate={(current) => moment().isBefore(current)}
                  />
                </div>
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="status"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">{Labels.FILTER_STATUS}</span>
                  </label>
                  <select
                    name="status"
                    onChange={(event) => {
                      const value = event.target.value;
                      setStatus(value ? (value as CommissionStatus) : null);
                    }}
                    className="w-full select"
                  >
                    <option value="">Todos</option>
                    {Object.keys(CommissionStatus).map((state) => (
                      <option key={state} value={state}>
                        {CommissionStatusSpanish[state as any]}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="categoryId"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">Categorías:</span>
                  </label>
                  <select
                    name="categoryId"
                    onChange={(event) => {
                      const value = event.target.value;
                      setCategoryId(value === 'Todas' ? null : value);
                    }}
                    className="w-full select"
                  >
                    <option value="">Todas</option>
                    {categories?.categories.map((category: any) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex items-center justify-center">
                  <label
                    htmlFor="categoryId"
                    className="block text-sm font-medium text-gray-700"
                  >
                    <span className="mr-3">Distribuidores:</span>
                  </label>
                  <select
                    name="categoryId"
                    onChange={(event) => {
                      const value = event.target.value;
                      setDistributorId(value === 'Todos' ? null : value);
                    }}
                    className="w-full select"
                  >
                    <option value="">Todos</option>
                    {distributors?.distributors.map((distributor: any) => (
                      <option key={distributor.id} value={distributor.id}>
                        {distributor.email}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </form>
          </div>
          <SkeletonTable
            active={true}
            loading={loading}
            columns={columns as SkeletonTableColumnsType[]}
          >
            <Table
              dataSource={datasource}
              columns={columns}
              pagination={{
                defaultPageSize: DEFAULT_PAGE_SIZE,
                pageSize: nodesPerPage,
                current: pageCurrent,
                total: data?.distributorCommissions.meta.nodeCount,
                responsive: true,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30', '100'],
                onShowSizeChange: (current, size) => setNodesPerPage(size),
                onChange: (page) => setPageCurrent(page),
              }}
            />
          </SkeletonTable>
        </div>
      </div>
    </div>
  );
};
