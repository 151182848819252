import React from 'react';
import { Titles } from '../../enums/titles.enum';
import { Helmet } from 'react-helmet-async';
import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
import { content } from '../../__api__/content';
import { productsByFavorite, productsByFavoriteVariables } from '../../__api__/productsByFavorite';
import { useMe } from '../../hooks/use-me';
import { productsBySuggested } from '../../__api__/productsBySuggested';
import { ProductShowroom, ProducttoShow } from '../../components/product-showroom';
import { useHistory } from 'react-router-dom';

const CONTENT_QUERY = gql`
  query content {
    content {
      id
      title
      description
      image
    }
  }
`;

const FAV_PRODUCTS = gql`
  query productsByFavorite($input: ProductByFavoriteInput!) {
    productsByFavorite(input: $input) {
      id
      searchQuery
      name
      description
      foto
    }
  }
`;

const SUGGESTED_PRODUCTS = gql`
  query productsBySuggested {
    productsBySuggested {
      id
      searchQuery
      name
      description
      foto
    }
  }
`;

function parseToProducttoShow(input: any|undefined, handleCardClick : any): ProducttoShow[] {
  if(input == null) return [];
  if(!input) return [];
  try {
    const product: ProducttoShow[] = [];
    for (const i of input) {
      product.push({
        id: i.id,
        searchQuery: i.searchQuery,
        name: i.name,
        description: i.description,
        foto: i.foto,
        handleCardClick: handleCardClick
      });
    }
    return product;
  } catch (error) {
    console.error('Failed to parse input to ProducttoShow', error);
    return [];
  }
}

export const Landing = () => {
  const { data, loading } = useQuery<content>(CONTENT_QUERY, {
    fetchPolicy: 'network-only',
  });
  const history = useHistory();
  const { data: user } = useMe();
  const { data: productsFavData,loading: loadingfav } = useQuery<
    productsByFavorite,
    productsByFavoriteVariables
  >(FAV_PRODUCTS, {
    fetchPolicy: 'network-only',
    variables: {
      input: {
        customerId: user?.me.customer?.id || '',
        limit: 10,
      },
    },
  });
  const { data: productsSuggestedData,loading: loadingsug } = useQuery<productsBySuggested>(SUGGESTED_PRODUCTS, {
    fetchPolicy: 'network-only',
  });
  return (
    <div>
      <Helmet>
        <title>{Titles.MAIN}</title>
      </Helmet>
      {!loadingfav && (
        <div>
          <div className="mt-4 text-center text-2xl font-bold">{"Tus Favoritos"}</div>
          <div className="px-4 py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <ProductShowroom products={parseToProducttoShow(productsFavData?.productsByFavorite,(product: ProducttoShow) => {
          history.push('/products/'+product.searchQuery);
        })}></ProductShowroom>
          </div>
        </div>
      )}
      {!loadingsug && (
        <div>
          
          <div className="mt-4 text-center text-2xl font-bold">{"Recomendados por Nosotros"}</div>
          <div className="px-4 py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <ProductShowroom products={parseToProducttoShow(productsSuggestedData?.productsBySuggested,(product: ProducttoShow) => {
          history.push('/products/'+product.searchQuery);
        })}></ProductShowroom>
          </div>
        </div>
      )}
      {!loading && (
        <div>
          
          <div className="mt-4 text-center text-2xl font-bold">{"Publicidad para clientes"}</div>
          <div className="px-4 py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div>
              <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 xl:grid-cols-3">
                {data?.content?.map((content) => (
                  <div
                    key={content.id}
                    className="px-4 py-5 overflow-hidden bg-white border-2 rounded-lg shadow sm:p-6"
                  >
                    <dt className="text-sm font-medium text-gray-500 truncate">
                      <img
                        className="w-auto mb-4"
                        src={content.image}
                        alt={content.id}
                      />
                    </dt>
                    <dd className="mt-1 text-xl font-semibold text-gray-700">
                      {content.title}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
