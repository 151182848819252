import React, { useState } from 'react';
import { FormErrorMessages } from '../../../../enums/form-error-messages.enum';
import { FormError } from '../../../../components/form-error';
import { useForm } from 'react-hook-form';
import { Labels } from '../../../../enums/labels.enum';
import { Button } from '../../../../components/button';
import { useMutation } from '@apollo/client';
import { useMe } from '../../../../hooks/use-me';
import { Exceptions } from '../../../../enums/exceptions.enum';
import { checkLength, removeSpecialCharacters } from '../../../../utils/validate-utils';
import { FacType } from '../../../../__api__/globalTypes';
import { MRExceptions } from '../../../../enums/mr-exceptions.enum';
import { IBuyProductMTTRProps } from '../buy-product-mttr-props.interface';
import { CREATE_MTTR_PURCHASE_GIFTCARD } from '../../../../common-mutations/common-mutations';
import { comprarVoucherMTTRMutation, comprarVoucherMTTRMutationVariables } from '../../../../__api__/comprarVoucherMTTRMutation';
import { generateRandomString } from '../../../../utils/string-utils';
import { EMAIL_REGEX } from '../../../../constants';
import LoaderButton from '../../../../components/loaders/LoaderButton';

export const BuyProductMTTRESET: React.FC<IBuyProductMTTRProps> = ({ onOk, product, setLoadingPurcharse }) => {
  const { data, refetch } = useMe();
  let primalLoading = false;
  const [order_id] = useState(generateRandomString(24));


  const [errorCON, seterrorCON] = useState<string | null>(null);
  const [showConsumidor, setshowConsumidor] = useState<boolean>(false);

  const {
    register,
    getValues,
    errors,
    handleSubmit,
    formState: { },
  } = useForm({
    mode: 'onChange',
  });

  const consumidorChange = e => {
    if (!e) {
      return;
    }
    const val = Number(getValues().tipocliente);
    setshowConsumidor(val != 1);
  }

  const onCompleted = async (data: comprarVoucherMTTRMutation) => {
    const { comprarVoucherMTTR: purchaseOrderId } = data;
    if (purchaseOrderId) {
      primalLoading = true;
      if (setLoadingPurcharse) {
        setLoadingPurcharse(true);
      }
      await refetch();
      onOk(undefined);
    }
  };
  const [comprarVoucherMTTRMutation, { loading, error }] = useMutation<
    comprarVoucherMTTRMutation,
    comprarVoucherMTTRMutationVariables
  >(CREATE_MTTR_PURCHASE_GIFTCARD, {
    onCompleted,
  });

  const onSubmit = async () => {
    if (primalLoading) {
      return;
    }
    primalLoading = true;
    if (setLoadingPurcharse) {
      setLoadingPurcharse(true);
    }
    let facIdType: FacType = FacType.CONSUMIDORFINAL;
    switch (Number(getValues().tipocliente)) {
      case 1:
        facIdType = FacType.CONSUMIDORFINAL;
        break;
      case 2:
        facIdType = FacType.CEDULA;
        break;
      case 3:
        facIdType = FacType.RUC;
        break;
      case 4:
        facIdType = FacType.PASAPORTE;
        break;
      default:
        alert(Number(getValues().tipocliente));
        seterrorCON("Tipo de Consumidor no reconocido");
        return;
    }
    if (product == null || product == undefined) {
      seterrorCON("Error de Producto");
      return;
    }
    if (!loading) {
      seterrorCON(null);
      try {
        const { name, lastName, direccion, identificationNumber, nameEset, emailEset } = getValues();
        if (nameEset === "" || nameEset == undefined) {
          seterrorCON(Labels.ESET_NAME + ": " + FormErrorMessages.REQUIRED_ESET_NAME);
          return;
        }
        if (nameEset.length > 20) {
          seterrorCON(Labels.ESET_NAME + ": " + FormErrorMessages.LENGTH);
          return;
        }
        if (emailEset === "" || emailEset == undefined) {
          seterrorCON(Labels.ESET_EMAIL + ": " + FormErrorMessages.REQUIRED_ESET_EMAIL);
          return;
        }
        if (emailEset.length > 50) {
          seterrorCON(Labels.ESET_EMAIL + ": " + FormErrorMessages.LENGTH);
          return;
        }
        if (facIdType != FacType.CONSUMIDORFINAL) {
          if (name === "" || name == undefined) {
            seterrorCON(Labels.NAME_FAC_SERVICIO + ": " + FormErrorMessages.REQUIRED_FIELD);
            return;
          }
          if (name.length > 30) {
            seterrorCON(Labels.NAME_FAC_SERVICIO + ": " + FormErrorMessages.LENGTH);
            return;
          }
          if (!/^[áéíóúÁÉÍÓÚñÑa-zA-Z ]*$/.test(name)) {
            seterrorCON(Labels.NAME_FAC_SERVICIO + ": " + FormErrorMessages.CHARACTERS);
            return;

          }
          if (lastName === "" || lastName == undefined) {
            seterrorCON(Labels.APELLIDO_FAC_SERVICIO + ": " + FormErrorMessages.REQUIRED_FIELD);
            return;
          }
          if (lastName.length > 30) {
            seterrorCON(Labels.APELLIDO_FAC_SERVICIO + ": " + FormErrorMessages.LENGTH);
            return;
          }
          if (!/^[áéíóúÁÉÍÓÚñÑa-zA-Z ]*$/.test(lastName)) {
            seterrorCON(Labels.APELLIDO_FAC_SERVICIO + ": " + FormErrorMessages.CHARACTERS);
            return;

          }
          if (direccion === "" || direccion == undefined) {
            seterrorCON(Labels.DIR_FAC_SERVICIO + ": " + FormErrorMessages.REQUIRED_FIELD);
            return;
          }
          if (direccion.length > 150) {
            seterrorCON(Labels.DIR_FAC_SERVICIO + ": " + FormErrorMessages.LENGTH);
            return;
          }
          if (!/^[áéíóúÁÉÍÓÚñÑa-zA-Z ]*$/.test(direccion)) {
            seterrorCON(Labels.DIR_FAC_SERVICIO + ": " + FormErrorMessages.CHARACTERS);
            return;

          }
          if (identificationNumber === "" || identificationNumber == undefined) {
            seterrorCON(Labels.CI_FAC_SERVICIO + ": " + FormErrorMessages.REQUIRED_FIELD);
            return;
          }
          if (identificationNumber.length < 10 || identificationNumber.length > 30) {
            seterrorCON(Labels.CI_FAC_SERVICIO + ": " + FormErrorMessages.ID_LENGTH_SERVICE);
            return;
          }
          if (!/^[0-9]*$/.test(identificationNumber)) {
            seterrorCON(Labels.CI_FAC_SERVICIO + ": " + FormErrorMessages.CHARACTERS);
            return;
          }
        }
        await comprarVoucherMTTRMutation({
          variables: {
            input: {
              values: {
                name: nameEset,
                email: emailEset,
              },
              userId: data?.me.id || '',
              productId: product?.id || '',
              amount: product?.priceWithDiscount || 0,
              mttr_id: product?.mttrID ? product?.mttrID : "",
              order_id: order_id,
              facData: {
                factNames: facIdType == FacType.CONSUMIDORFINAL ? "" : name,
                facLastnames: facIdType == FacType.CONSUMIDORFINAL ? "" : lastName,
                facAddress: facIdType == FacType.CONSUMIDORFINAL ? "" : direccion,
                facID: facIdType == FacType.CONSUMIDORFINAL ? "" : identificationNumber,
                facIDType: facIdType
              }
            },
          },
        });
      } catch (error: any) {
        const errorMR = "MR" + (error.toString()?.split(":"))[2];
        seterrorCON(MRExceptions[errorMR] ? MRExceptions[errorMR] : MRExceptions.Default);
      }
      finally {
        primalLoading = false;
        if (setLoadingPurcharse) {
          setLoadingPurcharse(false);
        }
      }
    }
  };

  return (
    <div className="flex flex-col justify-center px-2 py-8 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="flex-1 min-w-0">
          <div className="flex items-center mb-4 space-x-3">
            <h2 className="text-lg font-bold text-gray-900 leading-7">
              {product?.name}
            </h2>
          </div>
          <h3 className="mb-6 text-base font-semibold text-gray-900 leading-7">
            {product?.description}
          </h3>
          <h4 className="mb-2 text-sm font-normal text-gray-900 leading-5">
            Requerimos de la siguiente información para la compra de tu producto
          </h4>
        </div>
        <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label htmlFor="nameEset" className="label">
                {Labels.ESET_NAME}
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_ESET_NAME,
                    minLength: 1,
                    maxLength: 20
                  })}
                  name="nameEset"
                  minLength={1}
                  maxLength={20}
                  type="text"
                  className="input"
                />
                {errors.nameEset?.message && (
                  <FormError errorMessage={errors.nameEset?.message} />
                )}
                {(errors.nameEset?.type === 'minLength' ||
                  errors.nameEset?.type === 'maxLength') && (
                    <FormError errorMessage={FormErrorMessages.LENGTH} />
                  )}
              </div>
            </div>
            <div>
              <label htmlFor="emailEset" className="label">
                {Labels.ESET_EMAIL}
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_ESET_EMAIL,
                    minLength: 1,
                    maxLength: 50,
                    pattern: EMAIL_REGEX
                  })}
                  name="emailEset"
                  minLength={1}
                  maxLength={50}
                  type="text"
                  className="input"
                />
                {errors.emailEset?.message && (
                  <FormError errorMessage={errors.emailEset?.message} />
                )}
                {(errors.emailEset?.type === 'minLength' ||
                  errors.emailEset?.type === 'maxLength') && (
                    <FormError errorMessage={FormErrorMessages.LENGTH} />
                  )}
              </div>
            </div>
            <div>
              <label htmlFor="tipocliente" className="label">
                {Labels.CLI_SELECT_SERVICIO}
              </label>
              <div className="mt-1">
                <select
                  name="tipocliente"
                  ref={register({ required: true })}
                  className="input"
                  onChange={consumidorChange}
                  defaultValue={1}
                >
                  <option value="1">Consumidor Final</option>
                  <option value="2">Cedula</option>
                  <option value="3">RUC</option>
                  <option value="4">Pasaporte</option>
                </select>
              </div>
            </div>
            {showConsumidor && (
              <div>
                <label htmlFor="name" className="label">
                  {Labels.NAME_FAC_SERVICIO}
                </label>
                <div className="mt-1">
                  <input
                    ref={register({ maxLength: 100 })}
                    name="name"
                    maxLength={100}
                    type="text"
                    className="input"
                  />
                </div>
              </div>)}
            {showConsumidor && (
              <div>
                <label htmlFor="lastName" className="label">
                  {Labels.APELLIDO_FAC_SERVICIO}
                </label>
                <div className="mt-1">
                  <input
                    ref={register({ maxLength: 100 })}
                    name="lastName"
                    maxLength={100}
                    type="text"
                    className="input"
                  />
                </div>
              </div>)}
            {showConsumidor && (
              <div>
                <label htmlFor="direccion" className="label">
                  {Labels.DIR_FAC_SERVICIO}
                </label>
                <div className="mt-1">
                  <input
                    ref={register({ maxLength: 150 })}
                    name="direccion"
                    maxLength={150}
                    type="text"
                    className="input"
                  />
                </div>
              </div>)}
            {showConsumidor && (
              <div>
                <label htmlFor="identificationNumber" className="label">
                  {Labels.CI_FAC_SERVICIO}
                </label>
                <div className="mt-1">
                  <input
                    ref={register({ maxLength: 20, minLength: 10, pattern: /^[0-9]*$/ })}
                    name="identificationNumber"
                    type="tel"
                    pattern="[0-9]*"
                    minLength={10}
                    maxLength={20}
                    className="input"
                    onKeyDown={(e) => checkLength(e)}
                    onKeyUp={removeSpecialCharacters}
                  />
                </div>
              </div>)}
            {(!loading && !primalLoading) &&
              (<Button
                canClick={true}
                loading={loading || primalLoading}
                actionText={Labels.BUY}
              />)
            }

            {(loading || primalLoading) && <LoaderButton />}
            {errors && (
              <FormError errorMessage={errors.message} />
            )}
            {error && (
              <FormError errorMessage={Exceptions[error.message as any]} />
            )}
          </form>
          {errorCON && (
            <FormError errorMessage={errorCON} />
          )}
        </div>

      </div>
    </div>
  );
};
