import React, { useMemo, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ApolloError, gql } from '@apollo/client/core';
import { useMutation, useQuery } from '@apollo/client';
import { Modal as AModal } from 'antd';
import {
  bankAccounts,
  bankAccountsVariables,
} from '../../__api__/bankAccounts';
import {
  createConsignmentMutation,
  createConsignmentMutationVariables,
} from '../../__api__/createConsignmentMutation';
import { useMe } from '../../hooks/use-me';
import { Labels } from '../../enums/labels.enum';
import { FormErrorMessages } from '../../enums/form-error-messages.enum';
import {
  checkLength,
  removeSpecialCharacters,
} from '../../utils/validate-utils';
import { FormError } from '../../components/form-error';
import { Exceptions } from '../../enums/exceptions.enum';
import { Button } from '../../components/button';
import { DatePicker } from 'antd';
import 'moment/locale/es';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';
import { BankAccountStatus } from '../../__api__/globalTypes';
import { INPUT_TEXT_REGEX } from '../../constants';

const BANK_ACCOUNTS_QUERY = gql`
  query bankAccounts($input: BankAccountStatusInput) {
    bankAccounts(input: $input) {
      id
      accountHolder
      accountNumber
      accountType
      bankCode
      bankName
    }
  }
`;

const CREATE_CONSIGNMENT_MUTATION = gql`
  mutation createConsignmentMutation($input: CreateConsignmentInput!) {
    createConsignment(input: $input)
  }
`;

interface ICreateConsignmentForm {
  amount: number;
  bankAccountId: string;
  voucherNumber: string;
  transactionDate: string;
  file: any;
  comment?: string;
}

interface ICreateConsignmentProps {
  onOk: () => void;
}

export const CreateConsignment: React.FC<ICreateConsignmentProps> = ({
  onOk,
}) => {
  const { data } = useMe();
  const { data: bankAccounts } = useQuery<bankAccounts, bankAccountsVariables>(
    BANK_ACCOUNTS_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        input: {
          status: BankAccountStatus.ACTIVE,
        },
      },
    },
  );
  const {
    register,
    getValues,
    errors,
    handleSubmit,
    formState: { isValid },
    control,
  } = useForm<ICreateConsignmentForm>({
    mode: 'onChange',
  });

  const onCompleted = (data: createConsignmentMutation) => {
    const { createConsignment: consignmentId } = data;
    if (consignmentId) {
      onOk();
    }
  };
  const [createConsignmentMutation, { loading, error }] = useMutation<
    createConsignmentMutation,
    createConsignmentMutationVariables
  >(CREATE_CONSIGNMENT_MUTATION, {
    onCompleted,
  });

  const errorAlert = (content: string) => {
    AModal.error({
      content
    });
  };


  const onSubmit = async () => {
    if (!loading) {
      try {
        const { file, amount, ...input } = getValues();
        const upload = file[0];
        await createConsignmentMutation({
          variables: {
            input: {
              ...input,
              amount: Number(amount),
              userId: data?.me.id || '',
              upload,
            },
          },
        });
      } catch (error) {
        const apolloError = error as ApolloError;
        const graphQLErrors = apolloError.graphQLErrors;

        if (graphQLErrors.length > 0) {
          const errorMessage = graphQLErrors[0]?.message;

          if (errorMessage === 'MAXAMOUNTNOTALLOWED') {
            errorAlert('El monto excede el máximo permitido,contactate con un administrador o con soporte');
          } else if (errorMessage === 'MINAMOUNTNOTALLOWED') {
            errorAlert('El monto es menor al mínimo permitido,contactate con un administrador o con soporte');
          }
        }
      }
    }
  };
  const fileReference = useRef<HTMLInputElement | null>(null);
  const spanReference = useRef<HTMLSpanElement>(null);

  const bankAccountOptions = useMemo(() => bankAccounts?.bankAccounts || [], [
    bankAccounts,
  ]);

  return (
    <div className="flex flex-col justify-center px-2 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-xl">
        <div className="px-4 py-8 bg-white shadow sm:rounded-3xl sm:px-10">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label htmlFor="voucherNumber" className="label">
                {Labels.VOUCHER_NUMBER}
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_VOUCHER_NUMBER,
                    minLength: 1,
                    maxLength: 150,
                    validate: {
                      isValidCharacters: (identificationNumber) =>
                        /^[0-9]*$/.test(identificationNumber) ||
                        FormErrorMessages.CHARACTERS,
                    },
                  })}
                  name="voucherNumber"
                  minLength={1}
                  maxLength={150}
                  onKeyDown={(e) => checkLength(e)}
                  onKeyUp={removeSpecialCharacters}
                  className="input"
                />
                {errors.voucherNumber?.message && (
                  <FormError errorMessage={errors.voucherNumber?.message} />
                )}
                {(errors.voucherNumber?.type === 'minLength' ||
                  errors.voucherNumber?.type === 'maxLength') && (
                    <FormError errorMessage={FormErrorMessages.LENGTH} />
                  )}
              </div>
            </div>
            <div>
              <label htmlFor="bankAccountId" className="label">
                {Labels.BANK_ACCOUNT}
              </label>
              <div className="mt-1">
                <select
                  name="bankAccountId"
                  ref={register({ required: true })}
                  className="select"
                >
                  <option value="">{Labels.SELECTED_ONE}</option>
                  {bankAccountOptions.map((bankAccount: any) => (
                    <option key={bankAccount.id} value={bankAccount.id}>
                      {`${bankAccount.bankName} - ${bankAccount.accountNumber} - ${bankAccount.accountHolder}`}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label htmlFor="amount" className="label">
                {Labels.AMOUNT}
              </label>
              <div className="mt-1">
                <input
                  ref={register({
                    required: FormErrorMessages.REQUIRED_AMOUNT,
                    min: 0.01,
                  })}
                  name="amount"
                  min={0.01}
                  type="number"
                  step=".01"
                  className="input"
                  onWheel={(e) => (e.target as HTMLInputElement).blur()} // Evita el cambio con la rueda del mouse
                  onKeyDown={(e) => {                 // Evita el cambio con las teclas de desplazamiento
                    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                      e.preventDefault();
                    }
                  }}
                />
                {errors.amount?.message && (
                  <FormError errorMessage={errors.amount?.message} />
                )}
                {errors.amount?.type === 'min' && (
                  <FormError errorMessage={'Debe ser mayor que 0.01'} />
                )}
              </div>
            </div>
            <div>
              <label htmlFor="transactionDate" className="label">
                {Labels.CONSIGNMENT_DATE}
              </label>
              <div className="mt-1">
                <Controller
                  control={control}
                  defaultValue={moment()}
                  name="transactionDate"
                  rules={{ required: true }}
                  render={({ onChange }) => (
                    <DatePicker
                      locale={locale}
                      defaultValue={moment()}
                      className="w-full input"
                      onChange={(date) => onChange(date)}
                      disabledDate={(current) => moment().isBefore(current)}
                    />
                  )}
                />
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {Labels.VOUCHER}
              </label>
              <div className="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-gray-300 border-dashed rounded-md">
                <div className="text-center space-y-1">
                  <svg
                    className="w-12 h-12 mx-auto text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="flex text-sm text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative font-medium text-indigo-600 bg-white cursor-pointer rounded-md hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                    >
                      <span
                        ref={spanReference}
                        onClick={() => fileReference?.current?.click()}
                        className="w-full py-1 font-medium text-center text-indigo-500 cursor-pointer hover:text-indigo-400 hover:underline"
                      >
                        {Labels.SELECT_VOUCHER}
                      </span>
                    </label>
                  </div>
                  <p className="text-xs text-gray-500">PNG, JPG, GIF</p>
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="comment" className="label">
                {Labels.COMMENT}
              </label>
              <div className="mt-1">
                <textarea
                  ref={register({
                    validate: {
                      isValidCharacters: (comment) =>
                        INPUT_TEXT_REGEX.test(comment) ||
                        FormErrorMessages.CHARACTERS,
                    },
                  })}
                  name="comment"
                  className="input"
                />
                {errors.comment?.message && (
                  <FormError errorMessage={errors.comment?.message} />
                )}
              </div>
            </div>
            <input
              type="file"
              name="file"
              accept="image/*"
              ref={(e) => {
                register(e, { required: true });
                fileReference.current = e;
              }}
              onChange={(e) =>
                spanReference && spanReference.current
                  ? (spanReference.current.innerText =
                    e?.target?.files?.[0].name || '')
                  : ''
              }
              className="hidden"
            />
            <Button
              canClick={isValid}
              loading={loading}
              actionText={Labels.CREATE}
            />
            {error && (
              <FormError errorMessage={Exceptions[error.message as any]} />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};
